/* BackdropLoader: A component which renders a loading
 * spinner with a backdrop, which is toggleable. It
 * is drawn entirely over the parent component. */

import React from "react";
import PropTypes from "prop-types";
import "../../styles/shared/backdrop-loader.scss";

const BackdropLoader = ({ disableBackdrop }) => (
  <div
    className="backdrop-loader align-absolute-center"
    style={{ backdropFilter: disableBackdrop ? "none" : "blur(10px)" }}
  >
    <div className="spinner-border text-dark" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>
);

BackdropLoader.propTypes = {
  disableBackdrop: PropTypes.bool
};

BackdropLoader.defaultProps = {
  disableBackdrop: false
};

export default BackdropLoader;
