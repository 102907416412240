import PropTypes from "prop-types";
import React from "react";
import Modal from "react-modal";
import { format } from "date-fns";

import Spinner from "./shared/Spinner";
import RapConstants from "../common/RapConstants";

class ConfirmUpdateModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false
    };
  }

  componentDidMount() {
    Modal.setAppElement("body");
  }

  getUpdateSummaryTable = () => {
    const { initialFieldValues, updatedFieldValues } = this.props;
    const finalRows = [];
    for (let key in updatedFieldValues) {
      // eslint-disable-next-line no-prototype-builtins
      if (updatedFieldValues.hasOwnProperty(key)) {
        const finalKey = `confirm-${key}`;
        let oldValue = initialFieldValues[key];
        // If its a List field, the old field value contains the value in {label: '', value:''} format
        if (RapConstants.LIST_FIELDS.includes(key)) {
          oldValue = oldValue && oldValue.value;
        } else if (key === "startDate" || key === "endDate") {
          oldValue =
            oldValue && oldValue !== "" && format(oldValue, "MM/dd/yyyy");
        }
        // Filter any empty values
        const checkEmptyValues = updatedFieldValues[key] === "" && !oldValue;
        // Skip showing allocations, notes and changeReason here
        if (
          key !== "allocations" &&
          key !== "notes" &&
          key !== "changeReason" &&
          updatedFieldValues[key] !== oldValue &&
          !checkEmptyValues
        ) {
          finalRows.push(
            <tr key={finalKey}>
              <td>{RapConstants.FORM_FIELDS[key]}</td>
              <td className="td-confirm-modal">
                <b>{updatedFieldValues[key]}</b>
              </td>
              <td className="td-confirm-modal grayed-text">{oldValue}</td>
            </tr>
          );
        }
      }
    }
    if (finalRows.length === 0) {
      finalRows.push(
        <tr key="field-0">
          <td>No Fields Updated</td>
        </tr>
      );
    }
    return finalRows;
  };

  getAllocations = () => {
    const { initialFieldValues, updatedFieldValues } = this.props;
    const key = "allocations";
    const finalRows = [];
    const oldAllocations = initialFieldValues[key];
    const newAllocations = updatedFieldValues[key];
    const newAllocationClass = "td-confirm-modal new-allocation";
    const updatedAllocationClass = "td-confirm-modal updated-allocation";
    const deletedAllocationClass = "td-confirm-modal deleted-allocation";

    // Iterate through new allocation rows
    if (newAllocations) {
      newAllocations.forEach((newRow, index) => {
        const finalKey = `alloc-${index}`;
        /// find the row to compare in old allocations
        const oldRow = oldAllocations.find((o) => o.id === newRow.id);
        let isAllocationUpdated = false;
        let isClientLobUpdated = false;
        let isClientUpdated = false;
        let isRateCardUpdated = false;
        let isPinStatusUpdated = false;
        let isStartDateUpdated = false;
        let isEndDateUpdated = false;
        let isReconcilableUpdated = false;

        let activeClass = updatedAllocationClass;

        // If status is 'Eliminated' that means the allocation is deleted
        const isEliminated = newRow.status === "Eliminated";
        if (isEliminated) {
          activeClass = deletedAllocationClass;
        }

        if (!oldRow) {
          // The old allocation was not found for this New ID,
          // means a new allocation record was newly added
          finalRows.push(
            this.getAllocationRow(newAllocationClass, newRow, finalKey)
          );
        } else {
          if (newRow.allocation !== oldRow.allocation) {
            isAllocationUpdated = true;
          }
          if (
            (!oldRow.client && newRow.client !== "") ||
            (oldRow.client && newRow.client !== oldRow.client.value)
          ) {
            isClientUpdated = true;
          }
          if (
            (!oldRow.clientLob && newRow.clientLob !== "") ||
            (oldRow.clientLob && newRow.clientLob !== oldRow.clientLob.value)
          ) {
            isClientLobUpdated = true;
          }
          if (
            (!oldRow.status && newRow.status !== "") ||
            (oldRow.status && newRow.status !== oldRow.status.value)
          ) {
            isPinStatusUpdated = true;
          }
          if (newRow.rateCard !== oldRow.rateCard) {
            isRateCardUpdated = true;
          }
          if (
            newRow.startDate !==
            (oldRow.startDate ? format(oldRow.startDate, "MM/dd/yyyy") : "")
          ) {
            isStartDateUpdated = true;
          }
          if (
            newRow.endDate !==
            (oldRow.endDate ? format(oldRow.endDate, "MM/dd/yyyy") : "")
          ) {
            isEndDateUpdated = true;
          }
          if (oldRow.reconcilable !== newRow.reconcilable) {
            isReconcilableUpdated = true;
          }

          if (
            isAllocationUpdated ||
            isClientUpdated ||
            isClientLobUpdated ||
            isRateCardUpdated ||
            isPinStatusUpdated ||
            isStartDateUpdated ||
            isEndDateUpdated ||
            isReconcilableUpdated
          ) {
            finalRows.push(
              <tr key={finalKey}>
                {this.getFormattedAllocationCol(
                  isEliminated || isAllocationUpdated
                    ? `td-allocation ${activeClass}`
                    : "",
                  oldRow.allocation,
                  newRow.allocation,
                  isAllocationUpdated
                )}
                {this.getFormattedAllocationCol(
                  isEliminated || isClientUpdated ? activeClass : "",
                  oldRow.client && oldRow.client.value,
                  newRow.client,
                  isClientUpdated
                )}
                {this.getFormattedAllocationCol(
                  isEliminated || isClientLobUpdated ? activeClass : "",
                  oldRow.clientLob && oldRow.clientLob.value,
                  newRow.clientLob,
                  isClientLobUpdated
                )}
                {this.getFormattedAllocationCol(
                  isEliminated || isPinStatusUpdated ? activeClass : "",
                  oldRow.status && oldRow.status.value,
                  newRow.status,
                  isPinStatusUpdated
                )}
                {this.getFormattedAllocationCol(
                  isEliminated || isRateCardUpdated
                    ? `td-rateCard ${activeClass}`
                    : "",
                  oldRow.rateCard,
                  newRow.rateCard,
                  isRateCardUpdated
                )}
                {this.getFormattedAllocationCol(
                  isEliminated || isStartDateUpdated
                    ? `td-startDate ${activeClass}`
                    : "",
                  oldRow.startDate,
                  newRow.startDate,
                  isStartDateUpdated,
                  true
                )}
                {this.getFormattedAllocationCol(
                  isEliminated || isEndDateUpdated
                    ? `td-endDate ${activeClass}`
                    : "",
                  oldRow.endDate,
                  newRow.endDate,
                  isEndDateUpdated,
                  true
                )}
                {this.getFormattedAllocationCol(
                  isEliminated || isReconcilableUpdated ? activeClass : "",
                  oldRow.reconcilable,
                  newRow.reconcilable,
                  isReconcilableUpdated
                )}
              </tr>
            );
          }
        }
      });
    }

    if (finalRows.length === 0) {
      finalRows.push(
        <tr key="alloc-0">
          <td>No Allocations Updated</td>
        </tr>
      );
    }
    return finalRows;
  };

  getAllocationRow = (className, newRow, key) => (
    <tr className={className} key={key}>
      <td>
        <b>{newRow.allocation}</b>
      </td>
      <td>
        <b>{newRow.client}</b>
      </td>
      <td>
        <b>{newRow.clientLob}</b>
      </td>
      <td>
        <b>{newRow.status}</b>
      </td>
      <td>
        <b>{newRow.rateCard}</b>
      </td>
      <td>
        <b>{newRow.startDate}</b>
      </td>
      <td>
        <b>{newRow.endDate}</b>
      </td>
      <td>
        <b>{newRow.reconcilable}</b>
      </td>
    </tr>
  );

  getFormattedAllocationCol = (
    className,
    oldVal,
    newVal,
    isUpdated,
    isDate
  ) => (
    <td>
      <span
        className={className}
        style={newVal === "Eliminated" ? { color: "red" } : {}}
      >
        <b>{newVal}</b>
      </span>
      {isUpdated && (
        <span className="td-confirm-modal grayed-text">
          {` /  ${isDate && oldVal ? format(oldVal, "MM/dd/yyyy") : oldVal}`}
        </span>
      )}
    </td>
  );

  confirmUpdate = () => {
    const { onConfirmUpdateModalSubmit } = this.props;
    this.setState(() => ({ isSubmitting: true }));
    onConfirmUpdateModalSubmit();
  };

  render() {
    const {
      isModalOpen,
      updatedFieldValues,
      initialFieldValues,
      onConfirmUpdateModalClose
    } = this.props;
    const { isSubmitting } = this.state;
    if (!isModalOpen) {
      return (
        <div>
          Loading...
          <Spinner waiting />
        </div>
      );
    }
    let buttonMessage = "Confirmed, All Good";
    if (isSubmitting) {
      buttonMessage = "Saving....";
    }
    return (
      <div className="container">
        <Modal
          id="confirmUpdateModal"
          role="dialog"
          isOpen={isModalOpen}
          onRequestClose={onConfirmUpdateModalClose}
          contentLabel="Confirm Updated Values"
          closeTimeoutMS={0}
          shouldCloseOnEsc={false}
          shouldCloseOnOverlayClick={false}
          parentSelector={() => document.body}
          className="confirm-modalcontent"
          overlayClassName="modaloverlay"
          ariaHideApp={false}
        >
          <div id="confirm-modal" className="form-group mt-2">
            <div className="row">
              <div className="col-md-6">
                <h5>
                  <span className="header-confirm-modal">
                    {"Review the changes to "}
                  </span>
                  &quot;{initialFieldValues.mslPin}
                  <span className="title-delimiter">|</span>
                  {`${initialFieldValues.familyName}, ${initialFieldValues.givenName}`}
                  &quot;
                </h5>
              </div>
              <div className="col-md-6">
                <h5 className="float-left">Allocations</h5>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="confirm-table-wrap">
                  <table className="table">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Title</th>
                        <th scope="col">New Value</th>
                        <th scope="col">Old Value</th>
                      </tr>
                    </thead>
                    <tbody>{this.getUpdateSummaryTable()}</tbody>
                  </table>
                </div>
              </div>
              <div className="col-md-6 right-col-wrap">
                <div className="allocation-table-wrap">
                  <table className="table">
                    <thead className="thead-light th-allocation">
                      <tr>
                        <th scope="col">Allocation</th>
                        <th scope="col">Client</th>
                        <th scope="col">Client LOB</th>
                        <th scope="col">Pin Status</th>
                        <th scope="col" className="td-rateCard">
                          Rate Card
                        </th>
                        <th scope="col" className="td-StartDate">
                          Start Date
                        </th>
                        <th scope="col" className="td-endDate">
                          End Date
                        </th>
                        <th scope="col">Reconcilable</th>
                      </tr>
                    </thead>
                    <tbody>{this.getAllocations()}</tbody>
                  </table>
                </div>
                <br />
                <div>
                  <div>
                    <b>Reason for Change</b>
                  </div>
                  <div>{updatedFieldValues.changeReason}</div>
                  <br />
                  <div>
                    <b>Notes</b>
                  </div>
                  <div>
                    <span className="notes-field">
                      {updatedFieldValues.notes}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="form-group mt-2">
            <button
              type="button"
              className="btn btn-sm btn-success"
              onClick={this.confirmUpdate}
              disabled={isSubmitting}
            >
              {buttonMessage}
            </button>{" "}
            <button
              type="button"
              onClick={onConfirmUpdateModalClose}
              className="btn btn-sm btn-danger"
              disabled={isSubmitting}
            >
              Cancel
            </button>{" "}
            {isSubmitting && <Spinner waiting />}
          </div>
        </Modal>
      </div>
    );
  }
}

ConfirmUpdateModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onConfirmUpdateModalClose: PropTypes.func.isRequired,
  onConfirmUpdateModalSubmit: PropTypes.func.isRequired,
  initialFieldValues: PropTypes.shape({
    mslPin: PropTypes.string,
    familyName: PropTypes.string,
    givenName: PropTypes.string
  }).isRequired,
  updatedFieldValues: PropTypes.shape({
    mslPin: PropTypes.string,
    changeReason: PropTypes.string,
    notes: PropTypes.string
  }).isRequired
};

export default ConfirmUpdateModal;
