import React from "react";
import PropTypes from "prop-types";
import Constants from "../../common/Constants";
import BackdropLoader from "../shared/BackdropLoader";

const ConditionalUI = ({
  currentState,
  emptyStateMsg,
  errorStateMsg,
  children
}) => {
  switch (currentState) {
    case Constants.UI_STATE_LOADING:
      return <BackdropLoader />;

    case Constants.UI_STATE_SUCCESS:
      return children;

    case Constants.UI_STATE_ERROR:
      return <span className="conditional-ui__msg">{errorStateMsg}</span>;

    default:
      return <span className="conditional-ui__msg">{emptyStateMsg}</span>;
  }
};

ConditionalUI.propTypes = {
  currentState: PropTypes.string.isRequired,
  emptyStateMsg: PropTypes.string,
  errorStateMsg: PropTypes.string,
  children: PropTypes.element.isRequired
};

ConditionalUI.defaultProps = {
  errorStateMsg: Constants.STD_MSG_ERROR,
  emptyStateMsg: Constants.STD_MSG_EMPTY
};

export default ConditionalUI;
