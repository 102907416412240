import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import api from "../../services/UserAdminApi";
import Constants from "../../common/Constants";

const Countries = () => {
  const [countries, setCountries] = useState([]);

  const getCountries = async () => {
    try {
      const countryResult = await api.getAllCountries();
      if (countryResult) {
        const countries = countryResult.map((country) => ({
          label: country.countryname,
          value: {
            id: country.countryid.toString(),
            name: country.countryname
          }
        }));
        setCountries(countries);
      } else {
        toast.error(
          "There was an error in fetching countries",
          Constants.TOAST_OPTIONS
        );
      }
    } catch (ex) {
      toast.error(
        `There was an error in fetching countries - ${ex.message}`,
        Constants.TOAST_OPTIONS
      );
    }
  };

  useEffect(() => {
    getCountries();
  }, []);

  return countries;
};

export default Countries;
