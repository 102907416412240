import React from "react";
import "../../styles/cost_to_serve/recent-scenario-card.scss";
import PropTypes from "prop-types";
import Tooltip from "../shared/Tooltip";
import CTSConstants from "../../common/CTSConstants";
import ScenarioStatusIcon from "./ScenarioStatusIcon";
import DateTime from "../../helpers/DateTime";
import String from "../../helpers/String";

const RecentScenarioCard = ({ scenario, onCopy, onView }) => {
  const { name, created, status, processingIssues } = scenario;

  const formattedCreateDate = DateTime.getUIDisplayDate(created);

  const createdAt =
    formattedCreateDate !== CTSConstants.SCENARIO_CREATED_YESTERDAY
      ? formattedCreateDate
      : `at ${formattedCreateDate}`;

  return (
    <div className="card recent-scenario-card">
      <div className="recent-scenario-card__name">
        {name?.length ? (
          <Tooltip className="tooltip-content" text={name}>
            <span className="recent-scenario-card__name__content text-ellipsis">
              {name}
            </span>
          </Tooltip>
        ) : (
          <span className="recent-scenario-card__name__content text-ellipsis">
            NA
          </span>
        )}
      </div>

      <p className="recent-scenario-card__timestamp">Created {createdAt}</p>

      <Tooltip
        containerClassName="scenario-status-wrapper"
        className="tooltip-content"
        text={String.capitalizeFirstLetter(status)}
      >
        <ScenarioStatusIcon status={status} />
      </Tooltip>

      <div className="recent-scenario-card__footer">
        <span className="recent-scenario-card__footer__mismatches">
          {processingIssues || "0"}
        </span>

        <div className="recent-scenario-card__actions">
          {/* Only locked scenarios could be copied */}
          {scenario.status === CTSConstants.SCENARIO_STATUS_LOCKED && (
            <Tooltip
              className="tooltip-content text-center"
              text="Copy Scenario"
            >
              <i
                className="fas fa-file scenario-action copy"
                onClick={() => onCopy(scenario)}
              />
            </Tooltip>
          )}

          <Tooltip className="tooltip-content text-center" text="View Scenario">
            <i
              className="fas fa-eye scenario-action view"
              onClick={() => onView(scenario)}
            />
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

RecentScenarioCard.propTypes = {
  scenario: PropTypes.shape({
    scenarioId: PropTypes.number,
    name: PropTypes.string,
    status: PropTypes.string,
    created: PropTypes.string,
    updated: PropTypes.string,
    sources: PropTypes.arrayOf(PropTypes.string),
    processingIssues: PropTypes.number
  }).isRequired,
  onCopy: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired
};

export default RecentScenarioCard;
