/* eslint-disable no-return-assign */
import React from "react";
import PropTypes from "prop-types";
import TypeAheadBoxField from "../../shared/TypeAheadBoxField";
import TextAreaField from "../../shared/TextAreaField";
import CollapsibleCard from "../../shared/CollapsibleCard";
import UploadFile from "../../shared/UploadFile";
import Tooltip from "../../shared/Tooltip";

const PositionJustification = ({
  formikProps,
  canModifyPARRecords,
  availablePARRequests,
  showDescription,
  showFieldsReadOnly,
  isSubmitterRole
}) => (
  <CollapsibleCard title="Position Justification">
    <div className="row">
      <div className="mt-3 col-md-3">
        <div className="relatedParContainer">
          <div className="textLabel">Related PAR Ticket(s)</div>
          <div className="tooltip-cell">
            <Tooltip
              className="tooltip-content"
              text={
                "Please note and reference related PAR ticket numbers in future submissions to help streamline tracking and management." ||
                ""
              }
            >
              <i className="fas fa-info-circle fa-lg info-color" />
            </Tooltip>
          </div>
        </div>
        <TypeAheadBoxField
          formikProps={formikProps}
          suggestions={availablePARRequests.map((x) => x.mslPin || x)}
          fieldName="related_pars"
          label="Related PAR Ticket(s)"
          showDescription={showFieldsReadOnly.related_pars}
          isOptional
          isMultiSelect
          disable={!canModifyPARRecords}
          placeholder="Select Related PAR Ticket(s)"
        />
      </div>
      <div className="mt-3 col-md-3">
        <div className="ccEmailsContainer">
          <div className="textLabel">CC for PAR Notification</div>
          <div className="tooltip-cell">
            <Tooltip
              className="tooltip-content"
              text="Please verify that the email addresses entered are correct to avoid any communication issues. You can add multiple emails."
            >
              <i className="fas fa-info-circle fa-lg info-color" />
            </Tooltip>
          </div>
        </div>
        <TypeAheadBoxField
          formikProps={formikProps}
          suggestions={[]}
          fieldName="cc_emails"
          label="CC for PAR Notification"
          showCreateNewOption
          createNewOptionMsg="Add cc email -"
          hideDropdownArrow
          isOptional
          isMultiSelect
          showDescription={showDescription}
          disable={!canModifyPARRecords}
          placeholder="Please type the email address and hit enter to add"
        />
      </div>
    </div>
    <div className="row ">
      <div className="mt-3 col-md-6">
        <TextAreaField
          formikProps={formikProps}
          className="mr-0"
          label="Position Action Justification"
          fieldName="justification"
          id="justification"
          placeholder="Enter email Address"
          showDescription={showDescription}
          disabled={!canModifyPARRecords}
        />
      </div>
    </div>
    <div className="row">
      <div className="mt-3 col-md-6">
        <UploadFile
          formikProps={formikProps}
          fieldName="attachment_paths"
          label="Upload Supporting Attachment(s)"
          maxSize={25}
          fileType=".xls,.xlsx, .doc, .docx, .pdf, .jpg"
          disable={!canModifyPARRecords || showDescription}
          showDescription={showDescription}
          isOptional
          isSubmitterRole={isSubmitterRole}
        />
      </div>
    </div>
  </CollapsibleCard>
);

PositionJustification.propTypes = {
  formikProps: PropTypes.shape({
    values: PropTypes.shape({
      fill_type: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      })
    }),
    setFieldValue: PropTypes.func,
    handleBlur: PropTypes.func
  }).isRequired,
  canModifyPARRecords: PropTypes.bool,
  showDescription: PropTypes.bool,
  isSubmitterRole: PropTypes.bool,
  availablePARRequests: PropTypes.instanceOf(Array),
  showFieldsReadOnly: PropTypes.shape({
    related_pars: PropTypes.bool
  })
};

PositionJustification.defaultProps = {
  availablePARRequests: [],
  canModifyPARRecords: false,
  showDescription: false,
  showFieldsReadOnly: {},
  isSubmitterRole: false
};

export default PositionJustification;
