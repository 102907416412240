/* eslint-disable no-return-assign */
import React from "react";
import PropTypes from "prop-types";
import CollapsibleCard from "../../shared/CollapsibleCard";
import TextAreaField from "../../shared/TextAreaField";

const TicketAction = ({ formikProps, isDraft }) => (
  <CollapsibleCard title="Ticket Action">
    <div className="row">
      <div className="col-md-3 mt-3">
        <label htmlFor="submitter">Submitter</label>
        <div id="submitter">
          <strong>
            {formikProps.values.submitter ||
              (window.userSession && window.userSession.email
                ? window.userSession.name
                : "")}
          </strong>
        </div>
      </div>
      <div className="col-md-3 mt-3">
        {isDraft && <div className="rounded-box">Submission In Progress</div>}
      </div>
    </div>
    <div className="row">
      <div className="col-md-6 mt-3">
        <TextAreaField
          formikProps={formikProps}
          label="Comments"
          fieldName="comment"
          id="comment"
          isOptional
        />
      </div>
    </div>
  </CollapsibleCard>
);

TicketAction.propTypes = {
  formikProps: PropTypes.shape({
    values: PropTypes.shape({
      comment: PropTypes.string,
      submitter: PropTypes.string
    })
  }),
  isDraft: PropTypes.bool
};

TicketAction.defaultProps = {
  formikProps: {
    values: {
      comment: null,
      submitter: null
    }
  },
  isDraft: false
};

export default TicketAction;
