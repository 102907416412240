import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import debounce from "lodash.debounce";

import RecentScenarioCard from "./RecentScenarioCard";
import ScenariosTable from "./ScenariosTable";
import "../../styles/cost_to_serve/scenario-list-page.scss";
import api from "../../services/CtsApi";
import ConditionalUI from "../helpers/ConditionalUI";
import Constants from "../../common/Constants";
import CTSConstants from "../../common/CTSConstants";
import ProgressOverlay from "../shared/ProgressOverlay";
import Tooltip from "../shared/Tooltip";

import ScenarioStatusIcon from "./ScenarioStatusIcon";
import DateTime from "../../helpers/DateTime";
import String from "../../helpers/String";

/**
 * Main Page of the CTS Section, it displays all the scenarios
 * that are available
 */
const ScenarioListPage = () => {
  const [scenarioSearchPayload, setScenarioSearchPayload] = useState("");
  const [newScenarioName, setNewScenarioName] = useState("");
  const [scenariosData, setScenariosData] = useState([]);
  const [scenariosTableData, setScenariosTableData] = useState([]);
  const [isChangingScenario, setChangingScenario] = useState(false);
  const [changeMessage, setChangeMessage] = useState(<></>);
  const appRef = useRef(); // Create a ref object

  // Store the reference to the table to be used to access state from callback methods
  const scenariosTableDataStateRef = useRef();
  scenariosTableDataStateRef.current = scenariosTableData;

  const scenariosDataStateRef = useRef();
  scenariosDataStateRef.current = scenariosData;

  const [uiState, setUIState] = useState(Constants.UI_STATE_SUCCESS);
  const navigate = useNavigate();

  const handleNewScenarioNameChange = (e) => {
    setNewScenarioName(e.target.value);
  };

  // Method to view the scenario
  const viewScenario = ({ scenarioId }) => {
    // Get latest record data in the state variable
    const scenarioRecord = scenariosTableDataStateRef.current.find(
      (t) => t.scenarioId === scenarioId
    );
    const {
      processingIssues,
      sourceType,
      latestBatchId,
      name,
      status,
      created,
      updated,
      updatedBy
    } = scenarioRecord;
    navigate("/ctsupload", {
      state: {
        scenarioId,
        processingIssues,
        sourceType,
        latestBatchId,
        name,
        status,
        created,
        updated,
        updatedBy
      }
    });
  };

  const isDuplicateName = (name) =>
    scenariosTableDataStateRef.current.findIndex((s) => s.name === name) > -1;

  const getDataSourceTagsTemplate = (sources) => {
    const dataSourceTags = [];

    sources = sources.split(",");

    sources.forEach((sourceTag) => {
      dataSourceTags.push(
        <li key={sourceTag} className="source-tag">
          {sourceTag}
        </li>
      );
    });

    return <ul className="source-tags list-no-style">{dataSourceTags}</ul>;
  };

  /* Formats scenarios data from the API into data used by the table */
  const formatScenariosData = (rawData) =>
    rawData.map((scenario) => {
      const { status, updated, processingIssues, sourceType } = scenario;

      scenario.actions = (
        <>
          {scenario.status === CTSConstants.SCENARIO_STATUS_LOCKED && (
            <Tooltip
              className="tooltip-content text-center"
              text="Copy Scenario"
            >
              <i
                className="fas fa-file scenario-action copy"
                // eslint-disable-next-line no-use-before-define
                onClick={() => createScenario(true, scenario)}
              />
            </Tooltip>
          )}
          <Tooltip className="tooltip-content text-center" text="View Scenario">
            <i
              className="fas fa-eye scenario-action view"
              onClick={() => viewScenario(scenario)}
            />
          </Tooltip>
        </>
      );
      scenario[CTSConstants.SCENARIO_DATAKEY_LOADING] = false;
      scenario[CTSConstants.SCENARIO_DATAKEY_NEWROW] = false;
      scenario[CTSConstants.SCENARIO_DATAKEY_STATUSICO] = (
        <Tooltip
          containerClassName="scenario-status-wrapper"
          className="tooltip-content"
          text={String.capitalizeFirstLetter(status)}
        >
          <ScenarioStatusIcon status={status} />
        </Tooltip>
      );

      scenario[CTSConstants.SCENARIO_DATAKEY_LAST_EDITED] =
        DateTime.getUIDisplayDate(updated);
      scenario[CTSConstants.SCENARIO_DATAKEY_MISMATCHES] =
        processingIssues || 0;
      scenario[CTSConstants.SCENARIO_DATAKEY_SOURCES_TEMPLATE] =
        sourceType?.length ? getDataSourceTagsTemplate(sourceType) : "NA";

      return scenario;
    });

  // Method to create the scenario
  const createScenario = async (createCopy = false, scenario = null) => {
    try {
      let name =
        createCopy && scenario
          ? `Copy of ${scenario.name}`
          : newScenarioName.trim();

      if (!name?.length) {
        toast.error(
          "Scenario name could not be empty",
          Constants.TOAST_OPTIONS
        );
        return;
      }

      // Check if the scenario name already exists in the list
      if (isDuplicateName(name)) {
        if (!createCopy) {
          toast.error(
            "Scenario name already exists. Please change the name.",
            Constants.TOAST_OPTIONS
          );
          return;
        }
        // Keep updating the name to copy of till it gets the right name
        do {
          // FIXME: May be my lack of understanding, but what's going on here? it _looks_ like a loop which
          //        is looking for state change without the change happening on its own loop ~ Arron
          name = `Copy of ${name}`;
        } while (isDuplicateName(name));
      }

      const payload = {
        scenario: {
          createCopy,
          name,
          status: "Active"
        }
      };

      // Scenario object is only set for copyScenario condition
      if (scenario) {
        payload.scenario.scenarioId = scenario.scenarioId;
      }

      let message = (
        <>
          The scenario <b>{name}</b> is being created, Please wait.
        </>
      );
      if (createCopy) {
        message = (
          <>
            The scenario <b>{scenario.name}</b> is being copied to <b>{name}</b>
            , Please wait.
          </>
        );
      }
      // Set the state var as true so that overlay is displayed
      setChangingScenario(true);
      setChangeMessage(message);

      // Call API method to create a scenario
      const res = await api.createScenario(payload);
      if (res.status && res.scenario) {
        if (!createCopy) {
          toast.success(
            `Scenario - "${name}" created successfully`,
            Constants.TOAST_OPTIONS
          );
          navigate("/ctsupload", { state: res.scenario });
        } else {
          toast.success(
            `Scenario copied to "${name}" successfully.`,
            Constants.TOAST_OPTIONS
          );
          // Set the loading flag to false on the specific row that is being renamed

          const formattedData = formatScenariosData([res.scenario]);
          formattedData[0].newRow = true;
          const tableDataTemp =
            scenariosTableDataStateRef.current.concat(formattedData);
          const dataTemp = scenariosDataStateRef.current.concat(formattedData);

          // Flip the newRow column for all rows except the current one
          const tableData = tableDataTemp.map((x) =>
            x.scenarioId !== formattedData[0].scenarioId
              ? { ...x, newRow: false }
              : x
          );
          const data = dataTemp.map((x) =>
            x.scenarioId !== formattedData[0].scenarioId
              ? { ...x, newRow: false }
              : x
          );

          setScenariosData(data);
          setScenariosTableData(tableData);
          // Scroll to top of the file
          appRef.current.scrollTo(0, 0);
        }
      } else {
        toast.error(
          "There was an error in creating scenario",
          Constants.TOAST_OPTIONS
        );
        setUIState(Constants.UI_STATE_SUCCESS);
      }
    } catch (ex) {
      console.log(ex);
      toast.error(
        `There was an error in creating scenario - ${ex.message}`,
        Constants.TOAST_OPTIONS
      );
      setUIState(Constants.UI_STATE_SUCCESS);
    } finally {
      setUIState(Constants.UI_STATE_SUCCESS);
      setChangingScenario(false);
      setChangeMessage(<></>);
    }
  };

  const getScenarios = async (search = "") => {
    try {
      setUIState(Constants.UI_STATE_LOADING);

      const res = search.length
        ? await api.getScenarios({ textSearch: search })
        : await api.getScenarios();

      if (res.scenarios) {
        if (res.scenarios.length) {
          setUIState(Constants.UI_STATE_SUCCESS);

          const formattedData = formatScenariosData(res.scenarios);

          setScenariosData(formattedData);
          setScenariosTableData(formattedData);
        } else {
          setUIState(Constants.UI_STATE_EMPTY);
        }
      } else {
        setUIState(Constants.UI_STATE_ERROR);
        toast.error(
          "There was an error in fetching scenarios",
          Constants.TOAST_OPTIONS
        );
      }
    } catch (ex) {
      setUIState(Constants.UI_STATE_ERROR);

      // Ignore the exception, this means we did not get the data
      console.error(ex);
      toast.error(
        `There was an error in fetching scenarios - ${ex.message}`,
        Constants.TOAST_OPTIONS
      );
    }
  };

  const getRecentScenariosListTemplate = () => {
    const scenariosTemplate = [];

    const recentScenarios = scenariosData.sort(
      (a, b) => new Date(b.created).valueOf() - new Date(a.created).valueOf()
    );

    for (let i = 0; i < recentScenarios.length; i++) {
      scenariosTemplate.push(
        <span
          className="scenario-list-page__recent__list__item"
          key={recentScenarios[i].scenarioId}
        >
          <RecentScenarioCard
            scenario={recentScenarios[i]}
            onView={viewScenario}
            onCopy={(scenario) => createScenario(true, scenario)}
          />
        </span>
      );
    }

    return <>{scenariosTemplate}</>;
  };

  const debouncedHandleScenarioSearchTermChange = debounce(
    (search) => setScenarioSearchPayload(search),
    500
  );

  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
    const timeOut = setTimeout(() => getScenarios(), 50);
    return () => {
      if (timeOut) {
        clearTimeout(timeOut);
      }
    };
  }, []);

  /* Side effect when search term updates */
  useEffect(() => {
    setScenariosTableData(
      scenariosData.filter(({ name }) =>
        name.toLowerCase().includes(scenarioSearchPayload.toLowerCase())
      )
    );
    return () => {
      debouncedHandleScenarioSearchTermChange.cancel();
    };
  }, [scenarioSearchPayload]);

  // Method to rename the scenario and render table
  const handleScenarioRename = async (scenarioId, name) => {
    try {
      const message = (
        <>
          Scenario is being renamed to <b>{name}</b>. Please wait
        </>
      );
      setChangingScenario(true);
      setChangeMessage(message);

      const res = await api.renameScenario({ scenario: { scenarioId, name } });

      if (res.status) {
        toast.success("Scenario renamed successfully", Constants.TOAST_OPTIONS);
        // Set the loading flag to false on the specific row that is being renamed
        const tableData = scenariosTableData.map((x) =>
          x.scenarioId === scenarioId ? { ...x, name } : x
        );
        const data = scenariosData.map((x) =>
          x.scenarioId === scenarioId ? { ...x, name } : x
        );
        setScenariosData(data);
        setScenariosTableData(tableData);
        setChangingScenario(false);
        setChangeMessage("");
      }
    } catch (ex) {
      console.error(ex);
      toast.error(
        `There was an error in renaming scenario - ${ex.message}`,
        Constants.TOAST_OPTIONS
      );
    }
  };

  const clearSearchInput = () => {
    setScenarioSearchPayload("");
    document.getElementById("searchScenario").value = "";
  };

  const onCreateScenarioKeyDown = (event) => {
    if (event.key === Constants.KEY_ENTER) createScenario();
  };

  return (
    <div ref={appRef} className="scenario-list-page ctsClass full-height">
      <h6 className="scenario-list-page__title">Recently Updated</h6>

      {/* Recent Scenarios List */}
      <div className="scenario-list-page__recent disable-scrollbars">
        <ConditionalUI
          currentState={uiState}
          emptyStateMsg="No scenarios available"
          errorStateMsg="Error fetching scenarios"
        >
          {getRecentScenariosListTemplate()}
        </ConditionalUI>
      </div>

      {/* Action Bar */}
      <div className="scenario-list-page__action-bar">
        {/* Title and Search */}
        <span className="display-flex scenario-list-page__action-bar__left">
          <h6 className="scenario-list-page__action-bar__title margin-btm-0">
            All Scenarios
          </h6>
          <div className="scenario-list-page__action-bar__search-wrapper">
            <input
              type="text"
              placeholder="Search Scenario"
              id="searchScenario"
              className="form-control scenario-list-page__action-bar__input"
              onChange={(e) =>
                debouncedHandleScenarioSearchTermChange(e.target.value)
              }
            />
            {!scenarioSearchPayload.length ? (
              <i className="fas fa-search align-absolute-vertical-center" />
            ) : (
              <i
                className="far fa-times-circle align-absolute-vertical-center clear-input-btn"
                onClick={() => clearSearchInput()}
              />
            )}
          </div>
        </span>

        {/* Create Scenario */}
        <span className="display-flex scenario-list-page__action-bar__create">
          <input
            type="text"
            placeholder="Scenario Name"
            className="form-control scenario-list-page__action-bar__input"
            onChange={handleNewScenarioNameChange}
            value={newScenarioName}
            onKeyDown={onCreateScenarioKeyDown}
          />
          <button
            type="button"
            disabled={!newScenarioName.length}
            className="btn btn-primary scenario-list-page__create"
            onClick={() => createScenario()}
          >
            Create
          </button>
        </span>
      </div>

      {/* Scenarios Table */}
      <div className="card scenario-list-page__table-wrapper">
        <ScenariosTable
          uiState={uiState}
          originalData={scenariosTableData}
          updateMyData={handleScenarioRename}
        />
      </div>
      {isChangingScenario && <ProgressOverlay message={changeMessage} />}
    </div>
  );
};

export default ScenarioListPage;
