import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";

import TypeAheadBoxField from "./TypeAheadBoxField";

const Pagination = ({
  currentPage,
  totalPages,
  onPageChange,
  pageSize,
  onPageSizeChange,
  totalRows,
  showPerPage,
  localStorageName
}) => {
  const [pageValue, setpageValue] = useState(0);

  useEffect(() => {
    if (pageValue > 0) {
      onPageSizeChange(pageValue);
      localStorage.setItem(localStorageName, pageValue);
    }
  }, [pageValue]);

  const handlePageChange = (formikProps, pageNumber) => {
    if (pageNumber > 0) {
      formikProps.values.pageSelection = {
        value: pageNumber + 1,
        label: pageNumber + 1
      };
    } else {
      formikProps.values.pageSelection = {
        value: 1,
        label: 1
      };
    }
  };

  const options = [];
  for (let i = 1; i <= totalPages; i++) {
    options.push(i);
  }

  return (
    <Formik
      initialValues={{
        pageSize:
          pageSize !== totalRows
            ? {
                value: pageSize,
                label: pageSize
              }
            : {
                value: "Show All",
                label: "Show All"
              },
        pageSelection: {
          value: currentPage + 1,
          label: currentPage + 1
        }
      }}
    >
      {(formikProps) => (
        <Form>
          <div className="paginationContainer">
            <div className="pageSizeContainer">
              <span>Show per page:&nbsp;&nbsp;</span>
              <TypeAheadBoxField
                formikProps={formikProps}
                suggestions={showPerPage}
                fieldName="pageSize"
                label="pageSize"
                onValueChange={(value) => {
                  if (value !== "Show All") {
                    setpageValue(value);
                  } else {
                    setpageValue(totalRows);
                    formikProps.values.pageSelection.value = 1;
                    formikProps.values.pageSelection.label = 1;
                  }
                }}
                isOptional
              />
            </div>
            <div className="pageSelectionContainer">
              <span>Page&nbsp;&nbsp;</span>
              <TypeAheadBoxField
                formikProps={formikProps}
                suggestions={options}
                fieldName="pageSelection"
                label="pageSelection"
                onValueChange={(value) => onPageChange(value - 1)}
                isOptional
                disable={formikProps.values.pageSize.value === "Show All"}
              />
              <span>&nbsp;&nbsp;of {totalPages}</span>
            </div>
            <div className="separatorContainer">
              <span className="separator" />
            </div>
            <div className="navigationContainer">
              <button
                type="button"
                onClick={() => {
                  onPageChange(currentPage - 1);
                  handlePageChange(formikProps, currentPage - 1);
                }}
                disabled={currentPage === 0}
                title="previous"
              >
                <i className="fas fa-arrow-left fa-xs" />
              </button>
              <button
                type="button"
                onClick={() => {
                  onPageChange(currentPage + 1);
                  handlePageChange(formikProps, currentPage + 1);
                }}
                disabled={currentPage >= totalPages - 1}
                title="next"
              >
                <i className="fas fa-arrow-right fa-xs" />
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  onPageChange: PropTypes.func,
  pageSize: PropTypes.number,
  onPageSizeChange: PropTypes.func,
  totalRows: PropTypes.number,
  showPerPage: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ),
  localStorageName: PropTypes.string
};

Pagination.defaultProps = {
  currentPage: 0,
  totalPages: 0,
  onPageChange: {},
  pageSize: 0,
  onPageSizeChange: {},
  totalRows: 0,
  showPerPage: [],
  localStorageName: ""
};

export default Pagination;
