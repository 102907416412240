/* eslint-disable react/prop-types */
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTable, useSortBy } from "react-table";
import "../../styles/cost_to_serve/scenarios-table.scss";
import { toast } from "react-toastify";
import Constants from "../../common/Constants";
import BackdropLoader from "../shared/BackdropLoader";
import CTSConstants from "../../common/CTSConstants";

const ScenariosTable = ({ uiState, originalData, updateMyData }) => {
  // Custom sorting handler for columns that have Date as values
  const sortDates = (rowA, rowB, id) =>
    new Date(rowA.values[id]) - new Date(rowB.values[id]);

  // Custom sorting handler for columns that have 'Sources List' as values
  const sortSources = (rowA, rowB) => {
    const rowAValue =
      rowA.values[CTSConstants.SCENARIO_DATAKEY_SOURCES]?.length || 0;
    const rowBValue =
      rowB.values[CTSConstants.SCENARIO_DATAKEY_SOURCES]?.length || 0;

    // Missing sources is equivalent to a string "NA" which will always be shorter than a string with at-least once source
    return rowAValue - rowBValue;
  };

  // Custom sorting handler for columns that have 'Status Icon' as values
  const sortStatus = (rowA, rowB) =>
    rowA.values[CTSConstants.SCENARIO_DATAKEY_STATUS]
      .toLowerCase()
      .localeCompare(
        rowB.values[CTSConstants.SCENARIO_DATAKEY_STATUS].toLowerCase()
      );

  const [activeEditableRow, setActiveEditableRow] = useState(-1); // Keeps the track of active index being edited which controls visibility of 'edit' icon

  /*
   * This is a cell renderer with editable input field, apply to specific or all columns
   * by passing a 'Cell' key either into header config or defaultColumn arg into useTable
   * params as {Cell: EditableCell}
   * */
  // eslint-disable-next-line react/no-unstable-nested-components
  const EditableCell = ({
    value: initialValue,
    row,
    data,
    // eslint-disable-next-line no-shadow
    updateMyData // This is a custom function that we supplied to our table instance
  }) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue || "");

    // eslint-disable-next-line no-shadow
    const onChange = ({ target: { value } }) => {
      setValue(value);
    };

    const onFocus = () => {
      setActiveEditableRow(row.index);
    };

    const updateValue = () => {
      setActiveEditableRow(-1);
      const originalName = row.allCells[4].value;

      // Cannot set blank scenario names
      if (!value.length) {
        setValue(originalName); // Restore original name
        toast.error(
          "Scenario name could not be empty",
          Constants.TOAST_OPTIONS
        );
      } else if (value !== originalName) {
        // Do not trigger action on same name
        if (data.findIndex((s) => s.name === value) > -1) {
          setValue(originalName); // Restore original name
          toast.error(
            "Scenario name already exists. Please change the name.",
            Constants.TOAST_OPTIONS
          );
          return;
        }
        updateMyData(row.allCells[0].value, value.trim());
      }
    };
    // We'll only update the external data when the input is blurred
    const onBlur = () => {
      updateValue();
    };

    const onKeyDown = (e) => {
      if (e.key === Constants.KEY_ENTER) {
        updateValue();
      }
    };

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    // Locked scenario won't be editable
    if (row.allCells[1].value === CTSConstants.SCENARIO_STATUS_LOCKED) {
      return value;
    }

    return (
      <input
        className="form-control"
        value={value}
        onKeyDown={onKeyDown}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    );
  };
  EditableCell.propTypes = {
    value: PropTypes.string,
    row: PropTypes.shape({
      index: PropTypes.number,
      cells: PropTypes.arrayOf(PropTypes.shape({})),
      allCells: PropTypes.arrayOf(
        PropTypes.shape({
          getRowProps: PropTypes.func
        })
      )
    }),
    data: PropTypes.arrayOf(PropTypes.shape({})),
    updateMyData: PropTypes.func
  };
  EditableCell.defaultProps = {
    value: "",
    row: {},
    data: [],
    updateMyData: () => {}
  };

  // Column Config
  const columns = React.useMemo(
    () => [
      {
        Header: "scenarioId", // Hidden column
        accessor: CTSConstants.SCENARIO_DATAKEY_ID
      },
      {
        Header: "scenarioStatus", // Hidden column
        accessor: CTSConstants.SCENARIO_DATAKEY_STATUS
      },
      {
        Header: "sourceType", // Hidden column
        accessor: CTSConstants.SCENARIO_DATAKEY_SOURCES
      },
      {
        Header: "rowNew", // Hidden column
        accessor: CTSConstants.SCENARIO_DATAKEY_NEWROW
      },
      {
        Header: CTSConstants.COLUMN_SCENARIO_NAME,
        accessor: CTSConstants.SCENARIO_DATAKEY_NAME,
        Cell: EditableCell // All cells in this column will be rendered using this renderer
      },
      {
        Header: CTSConstants.COLUMN_LAST_EDITED,
        accessor: CTSConstants.SCENARIO_DATAKEY_LAST_EDITED,
        sortType: sortDates
      },
      {
        Header: CTSConstants.COLUMN_EDITED_BY,
        accessor: CTSConstants.SCENARIO_DATAKEY_LAST_EDITED_BY
      },
      {
        Header: CTSConstants.COLUMN_DATA_SOURCES,
        accessor: CTSConstants.SCENARIO_DATAKEY_SOURCES_TEMPLATE,
        sortType: sortSources
      },
      {
        Header: CTSConstants.COLUMN_MISMATCHES,
        accessor: CTSConstants.SCENARIO_DATAKEY_MISMATCHES
      },
      {
        Header: CTSConstants.COLUMN_STATUS,
        accessor: CTSConstants.SCENARIO_DATAKEY_STATUSICO,
        sortType: sortStatus
      },
      {
        Header: CTSConstants.COLUMN_ACTIONS,
        accessor: CTSConstants.SCENARIO_DATAKEY_ACTIONS,
        disableSortBy: true
      }
    ],
    []
  );

  const centerAlignedColumns = [
    CTSConstants.COLUMN_MISMATCHES,
    CTSConstants.COLUMN_STATUS,
    CTSConstants.COLUMN_ACTIONS
  ];
  const notSortableColumns = [CTSConstants.COLUMN_ACTIONS];

  // Table Config
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: originalData,
        autoResetPage: false,
        updateMyData,
        disableSortRemove: true,
        defaultCanSort: true,
        initialState: {
          hiddenColumns: [
            CTSConstants.SCENARIO_DATAKEY_ID,
            CTSConstants.SCENARIO_DATAKEY_STATUS,
            CTSConstants.SCENARIO_DATAKEY_SOURCES,
            CTSConstants.SCENARIO_DATAKEY_NEWROW
          ],
          sortBy: [
            { id: CTSConstants.SCENARIO_DATAKEY_LAST_EDITED, desc: true }
          ]
        }
      },
      useSortBy
    );

  const getCellContents = (cell) => {
    switch (cell.column.Header) {
      case CTSConstants.COLUMN_SCENARIO_NAME:
        return (
          <span className="scenario-name__cell">
            {cell.render("Cell")}
            {/* Do not show edit icon on locked scenario */}
            {
              // If cell.row.allCells[3].value => newRow status
              // that means that this is a new row, so show a new status
              cell.row.allCells[1].value !==
                CTSConstants.SCENARIO_STATUS_LOCKED &&
                activeEditableRow !== cell.row.index && (
                  <i className="fas fa-pencil-alt scenario-name__edit align-absolute-vertical-center" />
                )
            }
            {cell.row.allCells[3].value && (
              <>
                &nbsp;&nbsp;
                <span className="new-row-tag">New</span>
              </>
            )}
          </span>
        );
      case CTSConstants.COLUMN_MISMATCHES:
        return (
          <span className="red-text text-center">{cell.render("Cell")}</span>
        );
      case CTSConstants.COLUMN_ACTIONS:
        return (
          <span className="actions-cell__content-wrapper display-flex justify-end">
            {cell.render("Cell")}
          </span>
        );
      default:
        return cell.render("Cell");
    }
  };

  function getHeaderClassName(header) {
    switch (header) {
      case CTSConstants.COLUMN_SCENARIO_NAME:
        return "col-scenario-name";
      case CTSConstants.COLUMN_LAST_EDITED:
        return "col-last-edited";
      case CTSConstants.COLUMN_EDITED_BY:
        return "col-edited-by";
      case CTSConstants.COLUMN_DATA_SOURCES:
        return "col-sources";
      case CTSConstants.COLUMN_MISMATCHES:
        return "col-mismatches";
      case CTSConstants.COLUMN_STATUS:
        return "col-status";
      case CTSConstants.COLUMN_ACTIONS:
        return "col-actions";
      default:
        return "";
    }
  }

  function getCellClassName(header) {
    switch (header) {
      case CTSConstants.COLUMN_STATUS:
        return "status-cell";
      case CTSConstants.COLUMN_ACTIONS:
        return "actions-cell";
      default:
        return "";
    }
  }

  const getTableBodyTemplate = () => {
    switch (uiState) {
      case Constants.UI_STATE_LOADING:
        return (
          <tr>
            <td colSpan={7} className="pos-relative loader-wrapper">
              <BackdropLoader />
            </td>
          </tr>
        );
      case Constants.UI_STATE_EMPTY:
        return (
          <tr>
            <td colSpan={7}>
              <h5 className="placeholder text-center">
                No scenarios available
              </h5>
            </td>
          </tr>
        );
      case Constants.UI_STATE_ERROR:
        return (
          <tr>
            <td colSpan={7}>
              <h5 className="placeholder text-center">
                Error fetching scenarios
              </h5>
            </td>
          </tr>
        );
      default:
        return rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <td
                  {...cell.getCellProps()}
                  className={`${getCellClassName(cell.column.Header)}`}
                  // className={`${cell.column.header === CTSConstants.COLUMN_STATUS ? 'status-cell' : ''}`}
                  style={{
                    textAlign: centerAlignedColumns.includes(cell.column.Header)
                      ? "center"
                      : "justify"
                  }}
                >
                  {getCellContents(cell)}
                </td>
              ))}
            </tr>
          );
        });
    }
  };

  return (
    <table className="scenarios-table" data={originalData} {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                style={{
                  textAlign: centerAlignedColumns.includes(column.Header)
                    ? "center"
                    : "justify",
                  cursor: !notSortableColumns.includes(column.Header)
                    ? "pointer"
                    : "auto"
                }}
                className={`${getHeaderClassName(column.Header)}`}
              >
                {column.render("Header")}
                {column.isSorted ? (
                  column.isSortedDesc ? (
                    <i className="fas sort-button fa-arrow-down" />
                  ) : (
                    <i className="fas sort-button fa-arrow-up" />
                  )
                ) : (
                  ""
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>{getTableBodyTemplate()}</tbody>
    </table>
  );
};

ScenariosTable.propTypes = {
  uiState: PropTypes.string.isRequired,
  originalData: PropTypes.arrayOf(PropTypes.shape).isRequired,
  updateMyData: PropTypes.func.isRequired,
  activeEditableRow: PropTypes.number,
  setActiveEditableRow: PropTypes.func
};
ScenariosTable.defaultProps = {
  activeEditableRow: null,
  setActiveEditableRow: () => {}
};
export default ScenariosTable;
