import Constants from "../common/Constants";
import baseApi from "./BaseApi";

/**
 * User Admin API wrapper.
 *
 * NOTE: central handling of the API done through this file as a central way to have tight
 * control over what the rest of the UI code has to deal with. For example, if a property
 * name in a result needs to be changed, and the server side doesn't support it yet, this
 * API file can update the results so the UI itself can move on without final support from
 * the server. This would be similar property control that the property mappings in
 * Constants would allow, but without the extra double handling at each point of usage.
 *
 * This will also be the place where we're injecting the security headers.
 */

/**
 * Abstract the core HTTP call with specific verb functions.
 */
const postRequest = (path, data) =>
  baseApi.baseRequest("POST", Constants.USER_ADMIN_ROOT + path, data);
const getRequest = (path) =>
  baseApi.baseRequest("GET", Constants.USER_ADMIN_ROOT + path);

// User Management API Methods
async function getUsers(input) {
  return await postRequest("/getUsers", input);
}

async function createUser(userData) {
  // construct the object with form values
  const requestObj = {
    user: userData
  };
  return postRequest("/createUser", requestObj);
}

async function updateUser(userData) {
  // construct the object with form values
  const requestObj = {
    user: userData
  };
  return postRequest("/updateUser", requestObj);
}

async function deleteUser(userId) {
  // construct the object with form values
  const requestObj = {
    userId: userId
  };
  return postRequest("/deleteUser", requestObj);
}

async function getGroups(permissionType) {
  const requestObj = {
    permissionType: permissionType
  };
  return await postRequest("/getGroups", requestObj);
}

async function getCountriesByUser(userId) {
  const requestObj = {
    user_id: userId
  };
  return postRequest("/getCountriesByUser", requestObj);
}

async function getAllCountries() {
  return await postRequest("/getAllCountries");
}

async function getAllPermissions() {
  return await postRequest("/getAllPermissions");
}

async function createPermission(permission) {
  const requestObj = {
    permissionName: permission
  };
  return await postRequest("/createPermission", requestObj);
}

export default {
  getUsers,
  createUser,
  updateUser,
  deleteUser,
  getGroups,
  getAllCountries,
  getCountriesByUser,
  getAllPermissions,
  createPermission
};
