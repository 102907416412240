import { Formik, Form } from "formik";
import Modal from "react-modal";
import PropTypes from "prop-types";
import React from "react";
import { toast } from "react-toastify";

import Constants from "../../common/Constants";
import CTSConstants from "../../common/CTSConstants";
import Spinner from "../shared/Spinner";
import TypeAheadBoxField from "../shared/TypeAheadBoxField";

import api from "../../services/CtsApi";

class BFSScenarioSelectModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      scenarioList: null
    };
  }

  async componentDidMount() {
    const { isModalOpen, scenarios } = this.props;

    Modal.setAppElement("body");
    if (isModalOpen) {
      this.setState(() => ({ scenarioList: scenarios }));
    }
  }

  onSaveButtonClick = async (formData) => {
    const { onSave, batchId, onClose } = this.props;
    try {
      this.setState(() => ({ isSubmitting: true }));
      const response = await api.updateBFSScenarioForBatch({
        batchId,
        bfsScenarioName: formData.selectedScenario.value
      });

      if (!response || response.status !== CTSConstants.SUCCESS) {
        toast.error(
          "There was an error in updating the Scenario Name",
          Constants.TOAST_OPTIONS
        );
        onClose();
      } else {
        onSave();
      }
    } catch (ex) {
      toast.error(
        `There was an error in updating the Scenario Name - ${ex.message}`,
        Constants.TOAST_OPTIONS
      );
      onClose();
    } finally {
      // Update State
      this.setState(() => ({ isSubmitting: false }));
    }
  };

  render() {
    const { isModalOpen, onClose } = this.props;
    const { isSubmitting, scenarioList } = this.state;
    if (!isModalOpen) {
      return (
        <div>
          Loading...
          <Spinner waiting />
        </div>
      );
    }
    return (
      <div className="container">
        <Modal
          id="bfsScenarioSelect"
          role="dialog"
          isOpen={isModalOpen}
          onRequestClose={onClose}
          contentLabel="Save BFS Scenario"
          closeTimeoutMS={0}
          shouldCloseOnEsc={false}
          shouldCloseOnOverlayClick={false}
          parentSelector={() => document.body}
          className="bfs-modalcontent"
          overlayClassName="modaloverlay"
          ariaHideApp={false}
        >
          <Formik
            enableReinitialize
            validateOnMount
            initialValues={{ selectedScenario: "" }}
            onSubmit={this.onSaveButtonClick}
          >
            {(formikProps) => (
              <Form id="bfsForm">
                <div id="bfs-select-modal" className="form-group mt-2">
                  <div className="ml-2 row">
                    <h4>Select BFS Scenario to Sync</h4>
                  </div>
                  {scenarioList && (
                    <div className="row">
                      <div className="ml-2 mt-2 mb-2 col-md-8">
                        <TypeAheadBoxField
                          formikProps={formikProps}
                          suggestions={scenarioList}
                          fieldName="selectedScenario"
                          label="BFS Scenario"
                          isOptional={false}
                        />
                      </div>
                    </div>
                  )}
                  <div className="ml-2 row">
                    <button
                      type="submit"
                      className="btn btn-sm btn-success"
                      disabled={
                        isSubmitting ||
                        !formikProps.values.selectedScenario ||
                        formikProps.values.selectedScenario === "" ||
                        !formikProps.dirty
                      }
                    >
                      {isSubmitting ? "Saving..." : "Save"}
                    </button>
                    <button
                      type="button"
                      onClick={onClose}
                      className="ml-2 mr-2 btn btn-sm btn-danger"
                      disabled={isSubmitting}
                    >
                      Cancel
                    </button>
                    {isSubmitting && <Spinner waiting />}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal>
      </div>
    );
  }
}

BFSScenarioSelectModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  batchId: PropTypes.number.isRequired,
  scenarios: PropTypes.arrayOf(PropTypes.string)
};

BFSScenarioSelectModal.defaultProps = {
  scenarios: null
};

export default BFSScenarioSelectModal;
