/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";

import Constants from "../../common/Constants";
import api from "../../services/CtsApi";
import Spinner from "../shared/Spinner";

// NOTE I have no idea why this was problematic calling the local lambda service script ~ Arron :)
function disconnectedScenariosCall() {
  window.disconnectedGetScenarios();
}

/**
 * The component displays the scenario select typeahead that is used
 * on the top of the Scenario Upload Page as well as the
 * Scenario Edit Page
 */
const ScenarioSelect = ({
  initialScenarioValue,
  navigationLink,
  changeScenario
}) => {
  const [selectedScenario, setSelectedScenario] = useState(null);
  const [scenarioList, setScenarioList] = useState(null);
  const [isDataLoading, setIsDataLoading] = useState(false);

  // To navigate to next page
  const navigate = useNavigate();

  const handleScenarioChange = (value) => {
    setSelectedScenario(value);
    changeScenario(value);
  };

  // NOTE I have no idea why this was problematic calling the local lambda service script ~ Arron :)
  window.disconnectedGetScenarios = async () => {
    try {
      setIsDataLoading(true);
      const res = await api.getScenarios();
      if (res.scenarios && res.scenarios.length) {
        let finalScenarios = res.scenarios;
        // Update the Scenarios array according to the format required by the select
        res.scenarios.forEach((scenario, index) => {
          finalScenarios[index].label = scenario.name;
          finalScenarios[index].value = scenario.name;
        });
        setScenarioList(finalScenarios);
      } else {
        toast.error(
          "There was an error in fetching scenarios",
          Constants.TOAST_OPTIONS
        );
      }
    } catch (ex) {
      // Ignore the exception, this means we did not get the data
      console.error(ex);
      toast.error(
        `There was an error in fetching scenarios - ${ex.message}`,
        Constants.TOAST_OPTIONS
      );
    } finally {
      setIsDataLoading(false);
    }
  };

  useEffect(async () => {
    // Get the scenario set from the calling page
    const scenario = initialScenarioValue;
    if (scenario) {
      scenario.label = scenario.name;
      scenario.value = scenario.name;
    }
    setSelectedScenario(scenario);

    // NOTE I have no idea why this was problematic calling the local lambda service script ~ Arron :)
    const timeOut = setTimeout(() => disconnectedScenariosCall(), 50);
    return () => {
      if (timeOut) {
        clearTimeout(timeOut);
      }
    };
  }, []);

  return (
    <>
      {isDataLoading && <Spinner className="spinner text-center" />}
      {!isDataLoading && (
        <div className="row">
          <div className="col-md-2 back-link ml-2">
            <a
              onClick={() =>
                navigate(`${navigationLink}`, { state: selectedScenario })
              }
            >
              <div className="row mt-2">
                <i className="fas fa-arrow-left fa-2x mb-2 mr-2" />
                <div className="mb-2 mt-1">Back</div>
              </div>
            </a>
          </div>
          <div className="col-md-9">
            <Select
              name="searchScenarios"
              id="searchScenarios"
              placeholder="Search scenario...."
              options={scenarioList}
              value={selectedScenario}
              onChange={(value) => handleScenarioChange(value)}
            />
          </div>
        </div>
      )}
    </>
  );
};

ScenarioSelect.propTypes = {
  initialScenarioValue: PropTypes.shape({
    scenarioId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  }),
  navigationLink: PropTypes.string.isRequired,
  changeScenario: PropTypes.func.isRequired
};

ScenarioSelect.defaultProps = {
  initialScenarioValue: null
};

export default ScenarioSelect;
