import React, { useEffect, useContext, useState } from "react";
import Modal from "react-modal";

import UserContext from "../../store/UserContext";
import UserCountries from "../hooks/UserCountries";
import Spinner from "../shared/Spinner";
import Constants from "../../common/Constants";
import "../../styles/country-selection-modal.scss";

const CountrySelectionModal = () => {
  const { defaultCountry, setDefaultCountry, isOpenModal, setIsOpenModal } =
    useContext(UserContext);
  const { countries, isLoading } = UserCountries();
  const [selectedCountry, setSelectedCountry] = useState(defaultCountry);
  const [makeDefault, setMakeDefault] = useState(true);
  const [confirmation, setConfirmation] = useState(false);

  const setDefault = () => {
    if (makeDefault) {
      localStorage.setItem(
        Constants.FINOPS_COUNTRY,
        JSON.stringify(selectedCountry)
      );
    } else if (defaultCountry?.countryid === selectedCountry?.countryid) {
      localStorage.removeItem(Constants.FINOPS_COUNTRY);
    }
    setDefaultCountry({ ...selectedCountry });
  };

  const onContinueClick = () => {
    const defaultCountryId = defaultCountry?.countryid;
    if (defaultCountryId && defaultCountryId !== selectedCountry?.countryid) {
      setConfirmation(true);
    } else {
      setDefault();
      setIsOpenModal(false);
    }
  };

  const onConfirmationClick = () => {
    setDefault();
    setIsOpenModal(false);
  };

  useEffect(() => {
    Modal.setAppElement("body");
  }, []);

  return (
    <div className="container">
      <Modal
        id="CountrySelectionModal"
        role="dialog"
        isOpen={isOpenModal}
        contentLabel="Delete User Confirmation"
        className="contry-selection-modal"
        overlayClassName="modaloverlay"
        ariaHideApp={false}
      >
        <div>
          <div className="modal-header">
            <h2>
              {!confirmation && `Select an country to start`}
              {confirmation && (
                <>
                  <i className="fas fa-exclamation-triangle custom-warning-icon" />
                  Warning
                </>
              )}
            </h2>
          </div>
          <div className="modal-body">
            {isLoading && (
              <div className="row ml-2">
                Loading Country...
                <Spinner />
              </div>
            )}
            {!isLoading && !confirmation && (
              <div>
                {countries.map((c) => (
                  <label className="custom-radio" key={c.countryid}>
                    <input
                      type="radio"
                      name="country"
                      value={c.countryid}
                      checked={c.countryid === selectedCountry?.countryid}
                      onChange={() => setSelectedCountry(c)}
                    />
                    <span>{c.countryname}</span>
                  </label>
                ))}
                {countries.length === 0 && (
                  <h3 className="error-message">
                    No country have been configured for your login. Please open
                    a ticket for access.
                  </h3>
                )}
              </div>
            )}
            {confirmation && (
              <div className="confirmation-message">
                <p>
                  Are you sure to switch from{" "}
                  {`"${defaultCountry?.countryname}"`} to{" "}
                  {`"${selectedCountry?.countryname}"`}.
                </p>
                <p>All unsaved data will be lost.</p>
              </div>
            )}
          </div>
          <div className="modal-footer">
            {!confirmation && (
              <>
                <label className="footer-label">
                  <span>
                    <input
                      type="checkbox"
                      checked={makeDefault}
                      onChange={() => setMakeDefault((prev) => !prev)}
                    />
                  </span>
                  <span>Make current selection default</span>
                </label>
                <button
                  type="button"
                  className="btn btn-sm btn-success"
                  onClick={onContinueClick}
                >
                  Continue
                </button>
              </>
            )}

            {confirmation && (
              <button
                type="button"
                className="btn btn-sm btn-success"
                onClick={onConfirmationClick}
              >
                I understand
              </button>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CountrySelectionModal;
