/* BackdropLoader: A component which renders a loading
 * spinner with a backdrop, which is toggleable. It
 * is drawn entirely over the parent component. */

import React from "react";
import PropTypes from "prop-types";
import "../../styles/shared/backdrop-loader.scss";

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="alert-modal-overlay" onClick={onClose}>
      <div className="alert-modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="alert-modal-close" onClick={onClose}>
          x
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="modal-success-icon">
              <i className="fas fa-check-circle" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 message-title">
            <strong>Thank You</strong>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 message-content">
            <strong> {children}</strong>
          </div>
        </div>
      </div>
    </div>
  );
};
Modal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node
};

Modal.defaultProps = {
  isOpen: false,
  onClose: () => {},
  children: <></>
};

export default Modal;
