const setCookie = (cname, cvalue, expiryHours, isDelete = false) => {
  let expiryTime = "";
  if (!isDelete) {
    const d = new Date();
    d.setTime(d.getTime() + expiryHours * 60 * 60 * 1000);
    expiryTime = d.toUTCString();
  } else {
    expiryTime = "Thu, 01 Jan 1970 00:00:01 GMT";
  }
  const expires = "expires=" + expiryTime;
  document.cookie =
    cname +
    "=" +
    cvalue +
    ";" +
    expires +
    ";path=/" +
    ";Secure;SameSite=Strict";
};

const getCookie = (cname) => {
  const name = cname + "";
  const decodedCookie = decodeURIComponent(document.cookie);
  const splitValues = decodedCookie.split(";");
  for (let i = 0; i < splitValues.length; i++) {
    let cookie = splitValues[i].trim();
    // split on value = key
    const keyVal = cookie.split("=");
    if (keyVal[0].indexOf(name) == 0) {
      return keyVal[1];
    }
  }
  return null;
};

export default {
  setCookie,
  getCookie
};
