/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import BFSScenarioSelectModal from "./BFSScenarioSelectModal";
import MismatchTable from "./MismatchTable";
import ScenarioSelect from "./ScenarioSelect";
import UploadFileSource from "../shared/UploadFileSource";
import ProgressOverlay from "../shared/ProgressOverlay";
import Spinner from "../shared/Spinner";

import api from "../../services/CtsApi";
import Constants from "../../common/Constants";
import CTSConstants from "../../common/CTSConstants";
import DateTime from "../../helpers/DateTime";
import SuccessSound from "../../assets/success-sound-effect.mp3";
import Tooltip from "../shared/Tooltip";

/**
 * The page that is displayed when a user clicks on a scenario
 * to view the scenario. The user works around managing a scenario
 * in this page, they can do the following -
 * 1. Upload different sources.
 * 2. Review mismatch records and correct or ignore them.
 * 3. Download the current table of processed data.
 * 4. Make Corrections and Upload the data again.
 * 5. Lock the Scenario.
 */

const ManageScenarioPage = () => {
  // Get location data that is being set by the scenario list page
  const location = useLocation();
  const [selectedScenario, setSelectedScenario] = useState(location.state);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [rapRecordData, setRapRecordData] = useState({});
  const [bfsRecordData, setBFSRecordData] = useState({});
  const [processingIssues, setProcessingIssues] = useState([]);

  const [isDataProcessing, setIsDataProcessing] = useState(false);
  const [dataProcessingMsg, setDataProcessingMsg] = useState(<></>);
  const [finalProcessingStatus, setFinalProcessingStatus] = useState(
    CTSConstants.PROCESS_INPROGRESS
  );
  const [finalProcessingError, setFinalProcessingError] = useState("");

  const [batchSources, setBatchSources] = useState([]);
  const [isBatchSourceLoading, setIsBatchSourceLoading] = useState(false);

  const [isLocking, setIsLocking] = useState(false);
  const [isScenarioLocked, setScenarioLocked] = useState(
    (location.state && location.state.status) ===
      CTSConstants.SCENARIO_STATUS_LOCKED
  );

  const [isBfsSelectionModalOpen, setBfsSelectionModalOpen] = useState(false);
  const [bfsScenarios, setBfsScenarios] = useState(null);

  // To navigate to next page
  const navigate = useNavigate();

  const refreshBatchSources = async (batchId = null) => {
    let finalBatchId = selectedScenario.latestBatchId;
    if (batchId) {
      finalBatchId = batchId;
    }
    try {
      setIsBatchSourceLoading(true);
      setBatchSources([]);
      const batchSourcesTemp = await api.getBatchSources(finalBatchId);
      if (batchSourcesTemp && batchSourcesTemp.batchSources) {
        setBatchSources(batchSourcesTemp.batchSources);
        // TODO: Figure out what needs to be done here
      } else {
        toast.error(
          "There was an error in fetching batch data",
          Constants.TOAST_OPTIONS
        );
      }
    } catch (ex) {
      // Ignore the exception, this means we did not get the data
      console.error(ex);
      toast.error(
        `There was an error in fetching batch sources - ${ex.message}`,
        Constants.TOAST_OPTIONS
      );
    } finally {
      setIsBatchSourceLoading(false);
    }
  };

  const getBatchInfo = async (batchId, refreshSources = true) => {
    try {
      setIsDataLoading(true);
      const batchDetails = await api.getBatchDetails(batchId);
      const mismatches = await api.getDataProcessingIssues(batchId);
      if (batchDetails) {
        setRapRecordData({
          rapCount: batchDetails.rapCount,
          rapLastUpdated: batchDetails.rapLastUpdated
        });
        setBFSRecordData({
          bfsCount: batchDetails.bfsCount,
          bfsLastUpdated: batchDetails.bfsLastUpdated,
          bfsScenarioName: batchDetails.bfsScenarioName
        });
        setFinalProcessingStatus(batchDetails.processStatus);
        setFinalProcessingError(batchDetails.processError);
      } else {
        toast.error(
          "There was an error in fetching batch data",
          Constants.TOAST_OPTIONS
        );
      }
      if (mismatches) {
        setProcessingIssues(mismatches.processingIssues);
      } else {
        toast.error(
          "There was an error in fetching batch data",
          Constants.TOAST_OPTIONS
        );
      }
      if (refreshSources) {
        await refreshBatchSources(batchId);
      }
    } catch (ex) {
      // Ignore the exception, this means we did not get the data
      console.error(ex);
      toast.error(
        `There was an error in fetching batch data - ${ex.message}`,
        Constants.TOAST_OPTIONS
      );
    } finally {
      setIsDataLoading(false);
    }
  };

  // Method to poll the current status of the data ReProcess kickoff
  const pollDataProcessStatus = async (batchId) => {
    let processStatus = "";
    let processError = "";
    try {
      const batchDetails = await api.getBatchDetails(batchId);
      if (batchDetails && batchDetails.processStatus) {
        processStatus = batchDetails.processStatus;
        processError = batchDetails.processError;
      }
    } catch (ex) {
      console.error(ex);
      toast.error(
        `There was an error in reprocessing data - ${ex.message}`,
        Constants.TOAST_OPTIONS
      );
    }
    return {
      processStatus,
      processError
    };
  };

  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
    // If we come to this page without selecting a scenario 1st on the list page
    if (!selectedScenario) {
      navigate("/scenarios");
    }
    const timeOut = setTimeout(() => {
      getBatchInfo(selectedScenario.latestBatchId);
    }, 100);

    return () => {
      if (timeOut) {
        clearTimeout(timeOut);
      }
    };
  }, []);

  const handleScenarioChange = async (value) => {
    setSelectedScenario(value);
    setScenarioLocked(value.status === CTSConstants.SCENARIO_STATUS_LOCKED);
    await getBatchInfo(value.latestBatchId);
  };

  const lockCurrentScenario = async () => {
    try {
      setIsLocking(true);
      const response = await api.lockScenario(
        selectedScenario.scenarioId,
        selectedScenario.latestBatchId
      );
      if (response && response.status === CTSConstants.SUCCESS) {
        setScenarioLocked(true);
      } else {
        toast.error(
          "There was an error in locking the scenario",
          Constants.TOAST_OPTIONS
        );
      }
    } catch (ex) {
      // Ignore the exception, this means some issue happened on backend
      console.error(ex);
      toast.error(
        `There was an error in locking the scenario - ${ex.message}`,
        Constants.TOAST_OPTIONS
      );
    } finally {
      setIsLocking(false);
    }
  };

  const reprocessData = async () => {
    let processStatus = "";
    let isDataProcessDone = false;
    let processInfo = {};
    setIsDataProcessing(true);
    processInfo = await pollDataProcessStatus(selectedScenario.latestBatchId);
    // If the scenario is already processing, do not allow user to proceed
    if (processInfo.processStatus === CTSConstants.PROCESS_INPROGRESS) {
      const errorMsg = `Scenario ${selectedScenario.name} is already being processed. Try later or contact Engineering support`;
      toast.error(errorMsg, Constants.TOAST_OPTIONS);
      setFinalProcessingStatus(processInfo.processStatus);
      setFinalProcessingError(errorMsg);
      setIsDataProcessing(false);
      setDataProcessingMsg(<></>);
      return;
    }
    const message = (
      <>Reprocessing Report Data. Please wait till the process completes.</>
    );
    setDataProcessingMsg(message);
    try {
      // Just kick off the data process without checking its results
      await api.runDataProcess({ batchId: selectedScenario.latestBatchId });
    } catch (ex) {
      // Ignore the error "Failed to fetch", this indicates the gateway timeout
      // that the lambda gateway might return if the process runs longer
      if (ex.message !== "Failed to fetch") {
        // Show the error if its a genuine issue
        toast.error(
          "There was an error in reprocessing the data",
          Constants.TOAST_OPTIONS
        );
        isDataProcessDone = true;
        setIsDataProcessing(false);
        setDataProcessingMsg(<></>);
        return;
      }
    }
    try {
      // Keep Polling till this process is done (i.e. processStatus column value is done)
      const statusList = [
        CTSConstants.PROCESS_DONE,
        CTSConstants.PROCESS_FINAL,
        CTSConstants.PROCESS_ERROR
      ];
      do {
        processInfo = await pollDataProcessStatus(
          selectedScenario.latestBatchId
        );
        processStatus = processInfo.processStatus;
        isDataProcessDone = statusList.includes(processStatus);
        // wait a second...
        await new Promise((resolve) => setTimeout(resolve, 1000)); // eslint-disable-line
      } while (!isDataProcessDone);

      if (isDataProcessDone && processStatus !== CTSConstants.PROCESS_ERROR) {
        // Refresh the page to get Batch Info and latest mismatches
        setFinalProcessingStatus(processStatus);
        await getBatchInfo(selectedScenario.latestBatchId, false);
        toast.success("Data reprocessed successfully", Constants.TOAST_OPTIONS);
        // Play a success sound when reprocessing is done
        const successAlert = new Audio(SuccessSound);
        successAlert.play();
      } else {
        await getBatchInfo(selectedScenario.latestBatchId, false);
        console.log(`Error in Data Processing: ${processInfo.processError}`);
        toast.error(
          `There was an error in reprocessing the scenario. \nError: ${processInfo.processError}`,
          Constants.TOAST_OPTIONS
        );
      }
    } catch (ex) {
      console.error(ex);
      toast.error(
        `There was an error in reprocessing the data - ${ex.message}`,
        Constants.TOAST_OPTIONS
      );
    } finally {
      setIsDataProcessing(false);
      setDataProcessingMsg(<></>);
    }
  };

  const renderSourceUpload = () => {
    let finalSources = [];
    CTSConstants.CTS_SOURCES.forEach((source) => {
      const sourceObj =
        batchSources && batchSources.find((b) => b.sourceType === source);
      finalSources.push(
        <UploadFileSource
          key={source}
          batchId={selectedScenario.latestBatchId}
          isBatchSourceLoading={isBatchSourceLoading}
          isScenarioLocked={isScenarioLocked || isLocking}
          source={sourceObj}
          sourceName={source}
          onSuccessfulUpload={refreshBatchSources}
        />
      );
    });
    return <>{finalSources}</>;
  };

  const initiateSnapshot = async (type) => {
    setIsDataProcessing(true);
    const message = <>{`Taking Snapshot of ${type} Data. Please wait...`}</>;
    setDataProcessingMsg(message);
    try {
      // API Call to take Snapshot
      let response;
      if (type === "RAP") {
        response = await api.takeRapSnapshot({
          batchId: selectedScenario.latestBatchId
        });
      } else {
        response = await api.takeBFSSnapshot({
          batchId: selectedScenario.latestBatchId,
          scenarioName: bfsRecordData.bfsScenarioName
        });
      }
      if (response.status === CTSConstants.SUCCESS) {
        await getBatchInfo(selectedScenario.latestBatchId);
        toast.success(
          `${type} Data Snapshot has been completed successfully`,
          Constants.TOAST_OPTIONS
        );
      } else {
        toast.error(
          `There was an error in taking ${type} Snapshot`,
          Constants.TOAST_OPTIONS
        );
      }
    } catch (ex) {
      toast.error(
        `There was an error in taking the ${type} Data Snapshot - ${ex.message}`,
        Constants.TOAST_OPTIONS
      );
    } finally {
      setIsDataProcessing(false);
      setDataProcessingMsg(<></>);
    }
  };

  const downloadFile = async (type) => {
    let filePath = "";
    try {
      const response = await api.downloadDataFile({
        batchId: selectedScenario.latestBatchId,
        fileType: type
      });
      // Get full url
      if (response && response.url) {
        filePath = response.url;
      } else {
        // Show error
        toast.error(
          "There was a problem in downloading the file.",
          Constants.TOAST_OPTIONS
        );
      }
    } catch (ex) {
      console.log(ex);
      toast.error(
        `There was an unexpected error in downloading the file - ${ex.message}`,
        Constants.TOAST_OPTIONS
      );
    }
    await window.open(`${filePath}`, "_blank");
  };

  // Method to upload the final report file
  const uploadReportFile = useCallback((file, batchId) => {
    const reader = new FileReader();
    reader.onload = async () => {
      setIsDataProcessing(true);
      const message = <>Uploading Report File. Please wait...</>;
      setDataProcessingMsg(message);
      const binaryStr = reader.result;
      // Get the Base64 String from the File reader
      const base64String = binaryStr.replace("data:", "").replace(/^.+,/, "");

      try {
        const result =
          (await api.uploadUpdatedExcel(batchId, base64String, file.name)) ||
          {};
        // If the result is success that means that file upload has been finished but
        // file ingestion might be still going on,
        // So, the CTS_DATA_SOURCE table needs to be checked for the status of the job
        // Only if the status is 'Loaded', we show the success msg
        if (result.status === CTSConstants.SUCCESS) {
          let resultResource = null;
          let isSourceLoading = true;
          do {
            const response = await api.getBatchSources(batchId);
            resultResource = ((response && response.batchSources) || []).find(
              (s) => s.sourceType === "Bulk Edit"
            );
            isSourceLoading =
              !resultResource ||
              !["loaded", "error"].includes(resultResource.status);

            // wait a second...
            await new Promise((resolve) => setTimeout(resolve, 1000)); // eslint-disable-line
          } while (isSourceLoading);

          if (resultResource.status === "loaded") {
            toast.success(
              `File - ${file.name} Uploaded Successfully`,
              Constants.TOAST_OPTIONS
            );
            // Play a success sound when source upload is done
            const successAlert = new Audio(SuccessSound);
            successAlert.play();
            // Call method on to refresh the page
            await getBatchInfo(batchId);
          } else {
            toast.error(
              "There was a problem in uploading the file. Please check file contents.",
              Constants.TOAST_OPTIONS
            );
          }
        } else {
          // Show error
          toast.error(
            "There was a problem in uploading the file. Please check file contents.",
            Constants.TOAST_OPTIONS
          );
        }
      } catch (ex) {
        console.log(ex);
        toast.error(
          `There was an unexpected error in uploading the file - ${ex.message}`,
          Constants.TOAST_OPTIONS
        );
      } finally {
        setIsDataProcessing(false);
        setDataProcessingMsg(<></>);
      }
    };
    reader.readAsDataURL(file);
  }, []);

  const dateRAPRecords =
    rapRecordData.rapLastUpdated &&
    DateTime.getUIDisplayDate(rapRecordData.rapLastUpdated);
  const dateBFSRecords =
    bfsRecordData.bfsLastUpdated &&
    DateTime.getUIDisplayDate(bfsRecordData.bfsLastUpdated);

  const closeBfsSelection = () => {
    setBfsSelectionModalOpen(false);
  };

  const saveBfsSelection = async () => {
    closeBfsSelection();
    toast.success(
      "BFS Scenario Name updated Successfully",
      Constants.TOAST_OPTIONS
    );
    await getBatchInfo(selectedScenario.latestBatchId);
  };

  const showBfsSelectionModal = async () => {
    try {
      setIsDataProcessing(true);
      const message = <>BFS Scenario not selected. Loading Scenarios..</>;
      setDataProcessingMsg(message);
      // Get the employee Detail data
      const scenarioList = await api.getBFSScenariosList({
        batchId: selectedScenario.latestBatchId
      });
      const scenarios = scenarioList.map((a) => a.scenario);
      if (scenarioList) {
        await setBfsScenarios(scenarios);
        setBfsSelectionModalOpen(true);
      } else {
        toast.error(
          "There was an error in fetching bfs scenarios list",
          Constants.TOAST_OPTIONS
        );
      }
      setIsDataProcessing(false);
      setDataProcessingMsg(<></>);
    } catch (ex) {
      toast.error(
        `There was an error in fetching bfs scenarios list - ${ex.message}`,
        Constants.TOAST_OPTIONS
      );
    }
  };

  return (
    <div className="ctsClass">
      <div className="row search-header ml-4 mr-2 mb-4">
        <div className="col-md-4">
          <ScenarioSelect
            initialScenarioValue={selectedScenario}
            navigationLink="/scenarios"
            changeScenario={handleScenarioChange}
          />
        </div>
        <div className="col-md-8">
          <div className="float-right mt-1">
            <a
              className="blue-text mt-1 mr-3"
              onClick={() => {
                navigate("/scenarios");
              }}
            >
              Cancel
            </a>
            <button
              type="button"
              className="btn btn-sm btn-secondary mr-3"
              disabled={isScenarioLocked || isLocking}
              onClick={() => {
                reprocessData();
              }}
            >
              Reprocess
            </button>
            <button
              type="button"
              className="btn btn-sm btn-success mr-3"
              disabled={isScenarioLocked || isLocking}
              onClick={() => {
                navigate("/ctsedit", { state: selectedScenario });
              }}
            >
              Edit Record
            </button>
          </div>
        </div>
      </div>
      {isDataLoading && (
        <div className="row ml-4 mr-2 mb-4">
          Loading Scenario Details.. <Spinner className="spinner text-center" />
        </div>
      )}
      {!isDataLoading && (
        <div className="row ml-3 mt-2 mb-2 mr-2">
          <div className="col-md-3">
            <div className="card">
              <div className="row ml-1 mt-2">
                <div className="col-md-7">
                  <h5>RAP Data</h5>
                  {rapRecordData.rapCount > 0 && (
                    <div className="blue-text">
                      <a onClick={() => downloadFile("rap")}>
                        <i className="fas fa-download" />
                        &nbsp; Download
                      </a>
                    </div>
                  )}
                </div>
                <div className="col-md-5 float-right">
                  <h3>{rapRecordData.rapCount.toLocaleString("en")} </h3>
                  <div className="mt-2 cy-link">records</div>
                </div>
              </div>
              <div className="row ml-1 mt-3 mb-2 gray-text">
                <div className="col-md-7">
                  <div className="mt-1">
                    Latest records as on {dateRAPRecords}
                  </div>
                </div>
                <div className="col-md-5 blue-text">
                  <button
                    type="button"
                    className="btn btn-sm btn-secondary mr-1"
                    onClick={() => {
                      initiateSnapshot("RAP");
                    }}
                    disabled={isScenarioLocked || isLocking}
                  >
                    <i className="fas fa-sync 2x" />
                  </button>
                </div>
              </div>
            </div>
            <div className="card mt-3">
              <div className="row ml-1 mt-2">
                <div className="col-md-7">
                  <h5>BFS Data</h5>
                  {bfsRecordData.bfsCount > 0 && (
                    <div className="blue-text">
                      <a onClick={() => downloadFile("bfs")}>
                        <i className="fas fa-download" />
                        &nbsp; Download
                      </a>
                    </div>
                  )}
                </div>
                <div className="col-md-5 float-right">
                  <h3>{bfsRecordData.bfsCount} </h3>
                  <div className="mt-2 cy-link">records</div>
                </div>
              </div>
              {bfsRecordData.bfsScenarioName && (
                <div className="row ml-1 mt-2 mb-2 ">
                  <div className="col-md-8">
                    <span className="bfs-name-head">BFS Scenario</span>
                    <span className="bfs-name">{` - ${bfsRecordData.bfsScenarioName}`}</span>
                  </div>
                </div>
              )}

              <div className="row ml-1 mt-3 mb-2 gray-text">
                <div className="col-md-7">
                  <div className="mt-1">
                    Latest records as on {dateBFSRecords}
                  </div>
                </div>
                <div className="col-md-5 blue-text">
                  <button
                    type="button"
                    className="btn btn-sm btn-secondary mr-1"
                    onClick={() => {
                      if (bfsRecordData.bfsScenarioName) {
                        initiateSnapshot("BFS");
                      } else {
                        showBfsSelectionModal();
                      }
                    }}
                    disabled={isScenarioLocked || isLocking}
                  >
                    <i className="fas fa-sync 2x" />
                  </button>
                </div>
              </div>
            </div>
            {renderSourceUpload()}
          </div>
          <div className="col-md-9">
            <div className="row ml-1 mt-3 mb-3">
              {selectedScenario && (
                <div className="col-md-8">
                  <div className="row col-md-8">
                    <div className="scenario-header">
                      Scenario ({selectedScenario.name})
                    </div>
                    <span className="ml-1 mt-2 red-text">
                      <i className="fa fa-lock" />
                      {
                        // Dont show Lock Scenario Option for an already locked scenario
                        !isScenarioLocked && (
                          <a onClick={() => lockCurrentScenario()}>
                            &nbsp;Lock Scenario
                          </a>
                        )
                      }
                      {isScenarioLocked && <>&nbsp;&nbsp;Locked</>}
                    </span>
                  </div>
                  <div className="row col-md-8">
                    <span className="gray-text mt-2">
                      Last Edited on{" "}
                      {DateTime.getUIDisplayDate(selectedScenario.updated)}
                    </span>
                  </div>
                </div>
              )}

              <div className="col-md-4">
                <div className="row float-right mt-2 mr-4">
                  {(finalProcessingStatus === CTSConstants.PROCESS_DONE ||
                    finalProcessingStatus === "") && (
                    <span className="cts-not-submitted">
                      <i className="fas fa-exclamation-circle" />
                      <span>&nbsp;Not Submitted</span>
                    </span>
                  )}
                  {finalProcessingStatus ===
                    CTSConstants.PROCESS_INPROGRESS && (
                    <Tooltip
                      className="tooltip-content text-center"
                      text={finalProcessingError}
                    >
                      <span className="cts-not-submitted">
                        <i className="fas fa-exclamation-circle" />
                        <span>&nbsp;Processing In Progress</span>
                      </span>
                    </Tooltip>
                  )}
                  {finalProcessingStatus === CTSConstants.PROCESS_ERROR && (
                    <Tooltip
                      className="tooltip-content text-center"
                      text={finalProcessingError}
                    >
                      <span className="red-text">
                        <i className="far fa-times-circle" />
                        <span>&nbsp;Error in Processing</span>
                      </span>
                    </Tooltip>
                  )}
                  {finalProcessingStatus === CTSConstants.PROCESS_FINAL && (
                    <span className="cts-submitted">
                      <i className="fas fa-check-circle" />
                      <span>&nbsp;Ready for Submission</span>
                    </span>
                  )}
                </div>
              </div>
            </div>
            <MismatchTable
              mismatchData={processingIssues}
              batchId={selectedScenario.latestBatchId}
              isScenarioLocked={isScenarioLocked || isLocking}
              onUploadFile={uploadReportFile}
            />
          </div>
        </div>
      )}
      {isDataProcessing && <ProgressOverlay message={dataProcessingMsg} />}
      {isBfsSelectionModalOpen && (
        <BFSScenarioSelectModal
          isModalOpen={isBfsSelectionModalOpen}
          batchId={selectedScenario.latestBatchId}
          onClose={closeBfsSelection}
          onSave={saveBfsSelection}
          scenarios={bfsScenarios}
        />
      )}
    </div>
  );
};

export default ManageScenarioPage;
