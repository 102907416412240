import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import { useField } from "formik";
import { toast } from "react-toastify";
import useFileUpload from "../hooks/useFileUpload";
import parApi from "../../services/ParApi";
import Constants from "../../common/Constants";
import spreadhseet from "../../assets/spreadhseet.png";
import Tooltip from "./Tooltip";

const errorStyle = {
  border: "3px dashed #DC2F23"
};

const UploadFile = ({
  formikProps,
  fieldName,
  label,
  hideHeader,
  isOptional,
  disable,
  maxSize,
  fileType,
  showDescription,
  isSubmitterRole
}) => {
  const { files, uploadFiles, cancelUpload, onComplete } = useFileUpload(
    formikProps.values.par_request_id
  );

  const paths = formikProps.values[fieldName];
  const [filePaths, setFilePaths] = useState(paths ? paths.split(",") : []);
  const acceptedFileTypes = [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/pdf",
    "image/jpeg"
  ];

  onComplete(() => {
    formikProps.setFieldValue(
      fieldName,
      [...filePaths, ...files.map((x) => x.fileS3Path)].join(",")
    );
  });

  const [field, meta] = useField(fieldName);

  const onDrop = (acceptedFiles) => {
    uploadFiles(acceptedFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: true,
    noClick: disable,
    accept: acceptedFileTypes
  });
  const downloadFile = async (filePath) => {
    let presignedPath = "";
    try {
      let response = await parApi.getS3PresignedURL(filePath);
      // Get full url
      if (response && response.downloadURL) {
        presignedPath = response.downloadURL;
      } else {
        // Show error
        toast.error(
          "There was a problem in downloading the file.",
          Constants.TOAST_OPTIONS
        );
      }
    } catch (ex) {
      console.log(ex);
      toast.error(
        `There was an unexpected error in downloading the file - ${ex.message}`,
        Constants.TOAST_OPTIONS
      );
    }
    await window.open(`${presignedPath}`, "_blank");
  };

  const deleteFile = async (filePath, file = null) => {
    const { par_request_id: parRequestId } = formikProps.values || {};

    try {
      await parApi.deletePARAttachment(filePath, parRequestId?.value);

      const updatedPaths = filePaths.filter((fPath) => fPath !== filePath);
      setFilePaths(updatedPaths);

      formikProps.setFieldValue(fieldName, updatedPaths.join(","));

      if (file) cancelUpload(file);
    } catch (ex) {
      console.log(ex);
      toast.error(
        `There was an unexpected error in deleting the file - ${ex.message}`,
        Constants.TOAST_OPTIONS
      );
    }
  };

  const getFileExtension = (fileName) =>
    fileName.split(".")[fileName.split(".").length - 1];

  const isInDraftState =
    !formikProps.values?.current_queue_id && !formikProps.values?.ticket_status;

  return (
    <>
      {!hideHeader && (
        <label htmlFor={fieldName}>
          {label}
          {!isOptional && <span className="red-text">&nbsp;*</span>}
        </label>
      )}
      {!showDescription && (
        <div
          {...getRootProps({ className: "dropzone upload-container" })}
          style={{ border: meta.error ? errorStyle.border : "" }}
        >
          <div className="row gray-text mt-2 display-flex align-self-center">
            <div
              className="col-md-12"
              style={{ fontSize: 66, color: "#0a6adf" }}
            >
              <i className="fa fa-upload" />
            </div>
          </div>
          <div className="row gray-text mt-2 display-flex align-self-center">
            <div className="col-md-12">Drag a File Here or</div>
          </div>
          <div className="row gray-text mt-2 display-flex align-self-center">
            <div className="col-md-12">
              <button
                type="button"
                className="ml-2 btn btn-sm btn-success"
                disabled={disable}
                onClick={onDrop}
              >
                Click to Browse & Upload
              </button>
            </div>
          </div>
          <div className="row gray-text mt-2 display-flex align-self-center">
            <div className="col-md-12">
              Maximum File Size {maxSize} MB | Supported Formats {fileType}
            </div>
          </div>
          <div {...getRootProps({ className: "row mb-3 mr-2 " })}>
            <input
              {...getInputProps()}
              name={fieldName}
              id={field.name}
              disabled={disable}
            />
          </div>
        </div>
      )}

      {meta.error && (
        <div className="mt-1 text-danger">
          {meta.error?.value ? meta.error.value : meta.error}
        </div>
      )}

      <div className="progress-container">
        {files.map((fileInfo) => (
          <div className="card mt-3" key={fileInfo.file.name}>
            <div className="file-progress">
              <div className="excelIcon">
                {["xls", "xlsx"].includes(
                  getFileExtension(fileInfo.file.name)
                ) && <img src={spreadhseet} alt="Excel icon" />}
                <div className="download-link">
                  {["doc", "docx"].includes(
                    getFileExtension(fileInfo.file.name)
                  ) && (
                    <i
                      className="fas fa-file-word 3x"
                      style={{ color: "#076cdf" }}
                      aria-hidden="true"
                    />
                  )}
                  {["pdf"].includes(getFileExtension(fileInfo.file.name)) && (
                    <i
                      className="fas fa-file-pdf 3x"
                      style={{ color: "#d10000" }}
                      aria-hidden="true"
                    />
                  )}
                  {["jpg", "jpeg"].includes(
                    getFileExtension(fileInfo.file.name)
                  ) && (
                    <i
                      className="fas fa-file-image 3x"
                      style={{ color: "#5acde9" }}
                      aria-hidden="true"
                    />
                  )}
                </div>
              </div>
              <div className="fileDetails">
                <div className="file-title">
                  <strong> {fileInfo.file.name}</strong>
                </div>
                <div className="file-description">
                  {fileInfo.file.size > 1024 * 1024
                    ? (fileInfo.file.size / (1024 * 1024)).toFixed(2) + "MB"
                    : (fileInfo.file.size / 1024).toFixed(2) + "KB"}{" "}
                  | {fileInfo.file.name.split(".")[1]} | Text
                </div>
              </div>
              <div
                className="cancel-upload"
                data-testid="cancel-upload"
                onClick={() => cancelUpload(fileInfo.file)}
              >
                <i className="fa fa-times" aria-hidden="true" />
              </div>
            </div>
            <div className="progress-row">
              <div className="blank-space">&nbsp;</div>
              <div className="progress-percent-container">
                <div className="progress-bar-container">
                  <div
                    className="progress-bar "
                    style={{
                      width: `${fileInfo.progress}%`,
                      backgroundColor: "#107C41"
                    }}
                  />
                </div>
                <div className="percentDetails">
                  <div className="d-flex ">
                    <div className="percent">{`${fileInfo.progress} `}</div>
                    <div className="percent-sign">
                      <i className="fa fa-percent fa-sm" aria-hidden="true" />
                    </div>
                  </div>
                </div>
              </div>
              {fileInfo.progress === 100 && fileInfo.downloadURL && (
                <div className="download-link">
                  <a
                    href={fileInfo.downloadURL}
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-download" aria-hidden="true" />
                  </a>
                </div>
              )}
              {isInDraftState &&
                fileInfo.progress === 100 &&
                isSubmitterRole && (
                  <div className="delete-icon">
                    <i
                      data-testid="delete-icon"
                      className="far fa-trash-alt"
                      onClick={() =>
                        deleteFile(fileInfo.fileS3Path, fileInfo.file)
                      }
                    />
                  </div>
                )}
            </div>
          </div>
        ))}
      </div>

      {filePaths && filePaths.length > 0 && (
        <div className="uploadedfiles">
          {filePaths.map((x) => (
            <div className="card mt-3" key={x}>
              <div className="file-progress">
                <div className="excelIcon">
                  {["xls", "xlsx"].includes(getFileExtension(x)) && (
                    <img src={spreadhseet} alt="Excel icon" />
                  )}
                  <div className="download-link">
                    {["doc", "docx"].includes(getFileExtension(x)) && (
                      <i
                        className="fas fa-file-word 3x"
                        style={{ color: "#076cdf" }}
                        aria-hidden="true"
                      />
                    )}
                    {["pdf"].includes(getFileExtension(x)) && (
                      <i
                        className="fas fa-file-pdf 3x"
                        style={{ color: "#d10000" }}
                        aria-hidden="true"
                      />
                    )}
                    {["jpg", "jpeg"].includes(getFileExtension(x)) && (
                      <i
                        className="fas fa-file-image 3x"
                        style={{ color: "#5acde9" }}
                        aria-hidden="true"
                      />
                    )}
                  </div>
                </div>

                <div className="fileDetails">
                  <div className="file-title">
                    <Tooltip
                      className="tooltip-content"
                      text={x.substring(x.lastIndexOf("/") + 1) || ""}
                    >
                      <span className="clientBrand__content text-ellipsis">
                        {x.substring(x.lastIndexOf("/") + 1)}
                      </span>
                    </Tooltip>
                  </div>
                </div>
                <div className="download-link">
                  <a onClick={() => downloadFile(x)}>
                    <i className="fa fa-download" aria-hidden="true" />
                  </a>
                </div>
                {isInDraftState && isSubmitterRole && (
                  <div className="delete-icon">
                    <i
                      data-testid="delete-icon"
                      className="far fa-trash-alt"
                      onClick={() => deleteFile(x)}
                    />
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

UploadFile.propTypes = {
  formikProps: PropTypes.shape({
    values: PropTypes.shape({
      par_request_id: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      }),
      current_queue_id: PropTypes.number,
      ticket_status: PropTypes.string
    }),
    setFieldValue: PropTypes.func,
    handleBlur: PropTypes.func
  }).isRequired,
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  hideHeader: PropTypes.bool,
  isOptional: PropTypes.bool,
  showDescription: PropTypes.bool,
  disable: PropTypes.bool,
  maxSize: PropTypes.number,
  fileType: PropTypes.string,
  isSubmitterRole: PropTypes.bool
};

UploadFile.defaultProps = {
  isSubmitterRole: false,
  isOptional: false,
  hideHeader: false,
  showDescription: false,
  disable: false,
  maxSize: 25 * 1024 * 1024,
  fileType: ".xls .doc"
};

export default UploadFile;
