import React, { useState } from "react";
import "../../styles/shared/collapse-panel.scss";
import PropTypes from "prop-types";

const CollapsibleCard = ({ expanded, className, children, title }) => {
  const [isExpanded, setExpanded] = useState(expanded);
  return (
    <div className={`collapsible-card ${className}`}>
      <div className="header" onClick={() => setExpanded((prev) => !prev)}>
        <div className="title">{title}</div>
        <div>
          {isExpanded ? (
            <>
              <i
                className="fa fa-solid fa-angle-down fa-lg pl-1"
                aria-hidden="true"
              />
            </>
          ) : (
            <>
              <i
                className="fa fa-solid fa-angle-right fa-lg pl-1"
                aria-hidden="true"
              />
            </>
          )}
        </div>
      </div>
      {isExpanded ? <hr /> : ""}
      <div
        className="content content-max-width"
        style={{ display: isExpanded ? "block" : "none" }}
      >
        {children}
      </div>
    </div>
  );
};

CollapsibleCard.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  expanded: PropTypes.bool
};

CollapsibleCard.defaultProps = {
  expanded: true,
  title: "",
  className: "",
  children: <></>
};

export default CollapsibleCard;
