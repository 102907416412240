import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

import PermissionsTable from "./PermissionsTable";
import "../../styles/finops-permissions.scss";
import UserAdminApi from "../../services/UserAdminApi";
import Constants from "../../common/Constants";

const FinopsPermissions = () => {
  const [allPermissions, setAllPermissions] = useState([]);
  const [permission, setPermission] = useState("");
  const [disableCreateButton, setDisableCreateButton] = useState(true);
  const permissionRef = useRef(null);

  const enableDisableCreateButton = (value) => {
    if (value.trim()) {
      setPermission(value);
      setDisableCreateButton(false);
    } else {
      setPermission("");
      setDisableCreateButton(true);
    }
  };

  const getAllPermissions = async () => {
    try {
      const response = await UserAdminApi.getAllPermissions();
      const arrPermissions = response.allPermissions;
      setAllPermissions(arrPermissions);
    } catch (error) {
      toast.error(
        "Error fetching permissions: " + error,
        Constants.TOAST_OPTIONS
      );
    }
  };

  const createNewPermission = async () => {
    try {
      const response = await UserAdminApi.createPermission(permission);

      if (response.result === "success") {
        getAllPermissions();
        toast.success(
          "Permission created sucessfully.",
          Constants.TOAST_OPTIONS
        );
      } else {
        const { message, error } = response;
        if (error) {
          toast.error(message + error, Constants.TOAST_OPTIONS);
        } else {
          toast.error(message, Constants.TOAST_OPTIONS);
        }
      }
    } catch (error) {
      toast.error(
        "Error creating permission: " + error,
        Constants.TOAST_OPTIONS
      );
    } finally {
      permissionRef.current.value = "";
    }
  };

  useEffect(() => {
    getAllPermissions();
  }, []);

  return (
    <div className="permissions-page container-fluid">
      <div className="row">
        <div className="col-sm-9">
          <h4>FinOps Permissions</h4>
        </div>
        <div className="col-sm-2">
          <input
            id="permission"
            type="text"
            className="form-control"
            placeholder="Type permission"
            ref={permissionRef}
            onChange={(e) => enableDisableCreateButton(e.target.value)}
          />
        </div>
        <div className="col-sm-1">
          <button
            className="btn btn-primary"
            type="button"
            disabled={disableCreateButton}
            onClick={createNewPermission}
          >
            Create
          </button>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-sm-6">
          <div className="card-body">
            {allPermissions.length > 0 ? (
              <PermissionsTable data={allPermissions} />
            ) : (
              <div>Loading permissions...</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinopsPermissions;
