import React, { useEffect } from "react";
import Modal from "react-modal";

const ConfirmDeleteUserModal = (props) => {
  const { user, isModalOpen, onModalClose, onModalSubmit } = props;

  useEffect(() => {
    Modal.setAppElement("body");
  }, []);

  return (
    <div className="container">
      <Modal
        id="confirmDeleteUserModal"
        role="dialog"
        isOpen={isModalOpen}
        onRequestClose={onModalClose}
        contentLabel="Delete User Confirmation"
        className="confirm-delete-modalcontent"
        overlayClassName="modaloverlay"
        ariaHideApp={false}
      >
        <h2>Confirm Deletion</h2>
        {user && (
          <p>
            Are you sure you want to delete user <b>{user.displayName}</b>?
          </p>
        )}
        <button
          className="btn btn-primary"
          onClick={() => onModalSubmit(user.userId)}
        >
          Confirm
        </button>
        <button className="btn btn-primary ml-2" onClick={onModalClose}>
          Cancel
        </button>
      </Modal>
    </div>
  );
};

export default ConfirmDeleteUserModal;
