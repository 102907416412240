import React from "react";
import PropTypes from "prop-types";

const LoadingSpinner = ({ status, textMessage }) => (
  <div key="loadingContainer" className="loadingContainer">
    <div className="par-spinner">
      <div className="row">
        <div className="col-md-12">
          {status === "NoData" && (
            <div className="par-spinner-box-warn">
              <div className="circle">
                <i className="fa fa-exclamation" />
              </div>
            </div>
          )}
          {status === "Loading" && <div className="par-spinner-box" />}
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div
            className={
              status === "NoData"
                ? "par-spinner-message-warn mt-3"
                : "par-spinner-message mt-3"
            }
          >
            {textMessage}
          </div>
        </div>
      </div>
    </div>
  </div>
);
LoadingSpinner.propTypes = {
  status: PropTypes.string.isRequired,
  textMessage: PropTypes.string.isRequired
};

LoadingSpinner.defaultProps = {};
export default LoadingSpinner;
