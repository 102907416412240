import React from "react";
import PropTypes from "prop-types";
import { Field, ErrorMessage } from "formik";

const TextBoxField = ({
  formikProps,
  fieldName,
  label,
  isOptional,
  hideHeader,
  className,
  focus,
  focusRef,
  disable,
  validateOnChange,
  showDescription,
  onChange,
  type,
  maxLength
}) => {
  const classFinal = !className ? "mt-2 mr-2 mb-2" : className;
  return (
    <div className={classFinal}>
      {!hideHeader && (
        <label htmlFor={fieldName}>
          {label}
          {!isOptional && <span className="red-text">&nbsp;*</span>}
        </label>
      )}
      {!showDescription && (
        <Field name={fieldName} validateOnChange={validateOnChange}>
          {({ field, form, meta }) => (
            <input
              {...field}
              type={type || "text"}
              name={fieldName}
              id={fieldName}
              placeholder={`Type ${label}`}
              className="form-control"
              onBlur={(e) => {
                const trimmedValue = e.target.value.trim();
                form.setFieldValue(field.name, trimmedValue);
                formikProps.handleBlur(e);
                onChange(trimmedValue);
              }}
              style={meta.error ? { border: "1px solid #DC2F23" } : {}}
              ref={focus ? focusRef : null}
              disabled={disable}
              maxLength={maxLength}
            />
          )}
        </Field>
      )}
      {showDescription && (
        <div id={`${fieldName}-description`} className="form-description">
          <strong>
            {fieldName.indexOf(".") === -1
              ? formikProps?.values[fieldName]
              : formikProps?.values[fieldName.split(".")[0]][
                  fieldName.split(".")[1]
                ][fieldName.split(".")[2]]}
          </strong>
        </div>
      )}
      <div className="mt-1 error-message">
        <ErrorMessage name={fieldName} />
      </div>
    </div>
  );
};

TextBoxField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  formikProps: PropTypes.shape({
    values: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    }),
    setFieldValue: PropTypes.func,
    handleBlur: PropTypes.func
  }).isRequired,
  isOptional: PropTypes.bool,
  hideHeader: PropTypes.bool,
  showDescription: PropTypes.bool,
  className: PropTypes.string,
  focus: PropTypes.string,
  focusRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  }),
  disable: PropTypes.bool,
  validateOnChange: PropTypes.bool,
  onChange: PropTypes.func,
  type: PropTypes.string,
  maxLength: PropTypes.number
};

TextBoxField.defaultProps = {
  isOptional: false,
  hideHeader: false,
  className: null,
  focus: null,
  focusRef: null,
  disable: false,
  showDescription: false,
  validateOnChange: false,
  onChange: () => {},
  type: "text",
  maxLength: 100
};
export default TextBoxField;
