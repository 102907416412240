import React from "react";
import PropTypes from "prop-types";
import { Field, ErrorMessage } from "formik";

const TextAreaField = ({
  formikProps,
  fieldName,
  label,
  isOptional,
  hideHeader,
  className,
  focus,
  focusRef,
  disabled,
  validateOnChange,
  showDescription,
  onChange
}) => {
  const classFinal = !className ? "mt-2 mb-2" : className;
  return (
    <div className={classFinal}>
      {!hideHeader && (
        <label htmlFor={fieldName}>
          {label}
          {!isOptional && <span className="red-text">&nbsp;*</span>}
        </label>
      )}
      {!showDescription && (
        <Field name={fieldName} validateOnChange={validateOnChange}>
          {({ field, form, meta }) => (
            <textarea
              {...field}
              type="text"
              name={fieldName}
              id={fieldName}
              className="form-control"
              maxLength="400"
              onBlur={(e) => {
                const trimmedValue = e.target.value.trim();
                form.setFieldValue(field.name, trimmedValue);
                formikProps.handleBlur(e);
                onChange(trimmedValue);
              }}
              style={meta.error ? { border: "1px solid #DC2F23" } : {}}
              ref={focus ? focusRef : null}
              disabled={disabled}
            />
          )}
        </Field>
      )}
      {showDescription && (
        <div id={`${fieldName}-description`} className="form-description">
          <strong>{formikProps?.values[fieldName]}</strong>
        </div>
      )}
      <div className="mt-1 error-message">
        <ErrorMessage name={fieldName} />
      </div>
    </div>
  );
};

TextAreaField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  formikProps: PropTypes.shape({
    values: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    }),
    setFieldValue: PropTypes.func,
    handleBlur: PropTypes.func
  }).isRequired,
  isOptional: PropTypes.bool,
  hideHeader: PropTypes.bool,
  showDescription: PropTypes.bool,
  className: PropTypes.string,
  focus: PropTypes.string,
  focusRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  }),
  disabled: PropTypes.bool,
  validateOnChange: PropTypes.bool,
  onChange: PropTypes.func
};

TextAreaField.defaultProps = {
  isOptional: false,
  hideHeader: false,
  className: null,
  focus: null,
  focusRef: null,
  disabled: false,
  showDescription: false,
  validateOnChange: false,
  onChange: () => {}
};
export default TextAreaField;
