import React from "react";
import Modal from "react-modal";
import "../../styles/shared/backdrop-loader.scss";
import "../../styles/shared/confirmation-modal.scss";
import PropTypes from "prop-types";

const ConfirmationModal = ({ isOpen, onClose, onConfirm, children }) =>
  isOpen ? (
    <div className="container">
      <Modal
        id="CountrySelectionModal"
        role="dialog"
        isOpen={isOpen}
        contentLabel="Delete User Confirmation"
        className="contry-selection-modal"
        overlayClassName="modaloverlay"
        ariaHideApp={false}
      >
        <div>
          <div className="modal-header">
            <h5>
              <i className="fas fa-exclamation-triangle custom-warning-icon" />
              Warning
            </h5>
          </div>
          <div className="modal-body">
            <div className="confirmation-message">{children}</div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              data-testid="confirmation-modal-cancel-btn"
              className="btn btn-sm btn-secondary"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              type="button"
              data-testid="confirmation-modal-confirm-btn"
              className="btn btn-sm btn-success"
              onClick={onConfirm}
            >
              {" "}
              Confirm
            </button>
          </div>
        </div>
      </Modal>
    </div>
  ) : (
    <></>
  );

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  children: PropTypes.node
};

ConfirmationModal.defaultProps = {
  isOpen: false,
  onClose: () => {},
  onConfirm: () => {},
  children: <></>
};
export default ConfirmationModal;
