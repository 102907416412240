import React, { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import api from "../services/RapApi";
import Constants from "../common/Constants";
import CTSConstants from "../common/CTSConstants";

const FAILED = "failed";

const UploadPage = () => {
  const [fileInfo, setFileInfo] = useState({
    mslFrom: null,
    mslTo: null,
    processCount: null
  });

  const [totalProgress, setTotalProgress] = useState(-1);
  const [uploadStatus, setUploadStatus] = useState("");
  // const [progressTimerId, setProgressTimerId] = useState(-1);
  //
  // const startProgresssTimer = (startTimer) => {
  //   if (startTimer) {
  //     const intervalId = setInterval(() => {
  //       // Just stay at 90 so that the progress bar only updates when its done
  //       if (totalProgress < 90) {
  //         setTotalProgress(totalProgress + 10);
  //       }
  //     }, 1000);
  //     setProgressTimerId(intervalId);
  //   }
  // };

  const onFileDialogOpen = useCallback(() => {
    // Reset the state values
    setFileInfo({
      mslFrom: null,
      mslTo: null,
      processCount: null
    });
    setTotalProgress(-1);
    setUploadStatus("");
  });

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      setTotalProgress(10);
      reader.onabort = () => {
        setTotalProgress(100);
        console.log("file reading was aborted");
      };
      reader.onerror = () => {
        setTotalProgress(100);
        console.log("file reading has failed");
      };

      reader.onload = async () => {
        setTotalProgress(20);
        const binaryStr = reader.result;
        // Get the Base64 String from the File reader
        const base64String = binaryStr.replace("data:", "").replace(/^.+,/, "");
        const intervalId = setInterval(() => {
          // Just stay at 90 so that the progress bar only updates when its done
          if (totalProgress < 90) {
            setTotalProgress(totalProgress + 10);
          }
        }, 1000);

        try {
          // Call the API Method to upload the data
          const result = (await api.uploadNewBusiness(base64String)) || {};
          const returnData = result.data;
          // Set status and display details after file is uploaded
          if (
            result.status === "success" &&
            returnData &&
            returnData.processCount
          ) {
            setFileInfo({
              processCount: returnData.processCount,
              mslFrom: returnData.mslFrom,
              mslTo: returnData.mslTo
            });
            setTotalProgress(100);
            clearInterval(intervalId);
            setUploadStatus(CTSConstants.SUCCESS);
            toast.success(
              "File Uploaded Successfully",
              Constants.TOAST_OPTIONS
            );
          } else {
            clearInterval(intervalId);
            setTotalProgress(0);
            setUploadStatus(FAILED);

            // Show error
            console.log(result.error);
            toast.error(
              "There was a problem in uploading the file. Please check file contents.",
              Constants.TOAST_OPTIONS
            );
          }
        } catch (ex) {
          clearInterval(intervalId);
          setTotalProgress(0);
          setUploadStatus(FAILED);
          console.log(ex);
          toast.error(
            `There was an unexpected error in saving the data - ${ex.message}`,
            Constants.TOAST_OPTIONS
          );
        }
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    onDrop,
    onFileDialogOpen,
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    // Restrict it to only select 1 file at a time
    maxFiles: 1,
    // Limit it to only accept .xls and .xlsx extensions
    accept:
      "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  });

  const files = acceptedFiles.map((file) => (
    <div key={file.path} className="col-md-12">
      <div className="row">
        <b>Selected File:</b>&nbsp;{file.path}
      </div>
      <div className="row">
        <b>File Size:</b>&nbsp;{file.size} bytes
      </div>
      <div className="row">
        {fileInfo.mslFrom && (
          <>
            <b>MSL Pin From:</b>&nbsp;{fileInfo.mslFrom}
          </>
        )}
      </div>
      <div className="row">
        {fileInfo.mslTo && (
          <>
            <b>MSL Pin To:</b>&nbsp;{fileInfo.mslTo}
          </>
        )}
      </div>
      <div className="row">
        {fileInfo.processCount && (
          <>
            <b>Total Rows:</b>&nbsp;{fileInfo.processCount}
          </>
        )}
      </div>
    </div>
  ));

  let uploadIcon = "success-message far fa-check-circle";
  if (uploadStatus === FAILED) {
    uploadIcon = "error-message far fa-times-circle";
  }

  return (
    <>
      <div className="row ml-4 mt-3 mb-3">
        <h5>
          <b>New Business Upload</b>
        </h5>
      </div>
      <div className="card ml-4 mr-2">
        <div className="row mt-4 ml-2">
          <div className="col-md-6">
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              <span className="rounded-right">
                <i className="fas fa-cloud-upload-alt fa-4x" />
              </span>
              <p className="dropzone-file-info">
                <b>Drag and Drop File</b>
              </p>
              <p className="dropzone-file-info">OR</p>
              <button type="button" className="btn btn-success" onClick={open}>
                Browse
              </button>
            </div>
          </div>
          <div className="col-md-5 dropzone-file-info ml-2">
            <div className="row col-md-12">
              <b>Information about Uploaded File - </b>
            </div>
            <div className="row col-md-12">{files}</div>
          </div>
        </div>
        {totalProgress !== -1 && (
          <>
            <div className="row ml-2 mb-2">
              <div className="col-md-6">
                <div className="float-left">{`Progress ${totalProgress}%`}</div>
                {(totalProgress === 100 ||
                  (uploadStatus === FAILED && totalProgress === 0)) && (
                  <div className="float-right">
                    <i className={`${uploadIcon} fa-2x`} />
                  </div>
                )}
              </div>
            </div>
            <div className="row ml-2 mb-2">
              <div className="col-md-6">
                <div className="progress">
                  <div
                    aria-label="progress-bar"
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: `${totalProgress}%` }}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default UploadPage;
