import PropTypes from "prop-types";
import React from "react";
import Modal from "react-modal";

import Spinner from "./shared/Spinner";
import RapConstants from "../common/RapConstants";

/**
 * Modal which gets displayed when a user updates a MSL PIN to a value that
 * matches an existing MSL PIN in the backend, this is meant to show the
 * user existing values and a big red warning telling them they are over-riding things!
 * */
class ConfirmMSLPinUpdateModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false
    };
  }

  componentDidMount() {
    Modal.setAppElement("body");
  }

  getUpdateSummaryTable = () => {
    const { fieldValues } = this.props;
    const finalRows = [];
    for (let key in fieldValues) {
      // eslint-disable-next-line no-prototype-builtins
      if (fieldValues.hasOwnProperty(key)) {
        // Add this line to filter prototype properties
        const finalKey = `confirm-${key}`;
        let fieldValue = fieldValues[key];
        // If its a List field, the field value contains the value in {label: '', value:''} format
        if (RapConstants.LIST_FIELDS.includes(key)) {
          fieldValue = fieldValue && fieldValues.value;
        }
        // Skip showing allocations, notes and changeReason here
        if (
          key !== "allocations" &&
          key !== "notes" &&
          key !== "changeReason"
        ) {
          finalRows.push(
            <tr key={finalKey}>
              <td>{RapConstants.FORM_FIELDS[key]}</td>
              <td className="td-confirm-modal">
                <b>{fieldValues[key]}</b>
              </td>
            </tr>
          );
        }
      }
    }

    return finalRows;
  };

  getAllocations = () => {
    const { fieldValues } = this.props;
    const key = "allocations";
    const finalRows = [];
    const allocations = fieldValues[key];
    // Iterate through record allocation rows
    if (allocations) {
      allocations.forEach((row, index) => {
        const finalKey = `alloc-${index}`;
        // Only display ACTIVE Allocations
        if (row.status === "Active") {
          finalRows.push(
            <tr key={finalKey}>
              <td>
                <b>{row.allocation}</b>
              </td>
              <td>
                <b>{row.client}</b>
              </td>
              <td>
                <b>{row.clientLob}</b>
              </td>
              <td>
                <b>{row.rateCard}</b>
              </td>
            </tr>
          );
        }
      });
    }
    if (finalRows.length === 0) {
      finalRows.push(
        <tr key="alloc-0">
          <td>No Allocations</td>
        </tr>
      );
    }
    return finalRows;
  };

  confirmUpdate = () => {
    const { onConfirmMSLPinUpdateModalSubmit } = this.props;
    this.setState(() => ({ isSubmitting: true }));
    onConfirmMSLPinUpdateModalSubmit();
  };

  render() {
    const {
      isModalOpen,
      fieldValues,
      onConfirmMSLPinUpdateModalClose
      // originalRecordForMSLPinUpdate
    } = this.props;
    const { isSubmitting } = this.state;
    if (!isModalOpen) {
      return (
        <div>
          Loading...
          <Spinner waiting />
        </div>
      );
    }
    let buttonMessage = "Confirmed, Overwrite";
    if (isSubmitting) {
      buttonMessage = "Saving....";
    }
    return (
      <div className="container">
        <Modal
          id="ConfirmMSLPinUpdateModal"
          role="dialog"
          isOpen={isModalOpen}
          onRequestClose={onConfirmMSLPinUpdateModalClose}
          contentLabel="Confirm Updated Values"
          closeTimeoutMS={0}
          shouldCloseOnEsc={false}
          shouldCloseOnOverlayClick={false}
          parentSelector={() => document.body}
          className="confirm-modalcontent"
          overlayClassName="modaloverlay"
          ariaHideApp={false}
        >
          <div id="confirm-modal" className="form-group mt-2">
            <div className="row">
              <div className="warning-red col-md-11">
                <h4>
                  <span>
                    {
                      "WARNING: You are attempting to overwrite an existing record with MSL PIN - "
                    }
                    <b>{`${fieldValues.mslPin}`}</b>
                  </span>
                </h4>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-6">
                <h5>
                  <span className="header-confirm-modal">
                    {"Existing Record Values - "}
                  </span>
                  &quot;{fieldValues.mslPin}
                  <span className="title-delimiter">|</span>
                  {`${fieldValues.familyName}, ${fieldValues.givenName}`}&quot;
                </h5>
              </div>
              <div className="col-md-6">
                <h5 className="float-left">Allocations</h5>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="confirm-table-wrap">
                  <table className="table">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Title</th>
                        <th scope="col">Value</th>
                      </tr>
                    </thead>
                    <tbody>{this.getUpdateSummaryTable()}</tbody>
                  </table>
                </div>
              </div>
              <div className="col-md-6 right-col-wrap">
                <div className="allocation-table-wrap">
                  <table className="table">
                    <thead className="thead-light th-allocation">
                      <tr>
                        <th scope="col">Allocation</th>
                        <th scope="col">Client</th>
                        <th scope="col">Client LOB</th>
                        <th scope="col" className="td-rateCard">
                          Rate Card
                        </th>
                      </tr>
                    </thead>
                    <tbody>{this.getAllocations()}</tbody>
                  </table>
                </div>
                <br />
                <div>
                  <div>
                    <b>Notes</b>
                  </div>
                  <div>
                    <span className="notes-field">{fieldValues.notes}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="form-group mt-2">
            <button
              type="button"
              className="btn btn-sm btn-success"
              onClick={this.confirmUpdate}
              disabled={isSubmitting}
            >
              {buttonMessage}
            </button>{" "}
            <button
              type="button"
              onClick={onConfirmMSLPinUpdateModalClose}
              className="btn btn-sm btn-danger"
              disabled={isSubmitting}
            >
              Cancel
            </button>{" "}
            {isSubmitting && <Spinner waiting />}
          </div>
        </Modal>
      </div>
    );
  }
}

ConfirmMSLPinUpdateModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onConfirmMSLPinUpdateModalClose: PropTypes.func.isRequired,
  onConfirmMSLPinUpdateModalSubmit: PropTypes.func.isRequired,
  fieldValues: PropTypes.shape({
    value: PropTypes.string,
    mslPin: PropTypes.string,
    familyName: PropTypes.string,
    givenName: PropTypes.string,
    changeReason: PropTypes.string,
    notes: PropTypes.string
  }).isRequired
};

export default ConfirmMSLPinUpdateModal;
