const filterArray = (mainArray, itemsToInclude) => {
  return mainArray.filter((item) => itemsToInclude.includes(item));
};

const getValueFromArray = (arrayValue) => {
  if (arrayValue !== undefined && arrayValue.length > 0) {
    const [value] = arrayValue;
    return value === "null" ? "" : value;
  }
  return null;
};

export default {
  filterArray,
  getValueFromArray
};
