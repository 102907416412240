import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import api from "../../services/UserAdminApi";
import Constants from "../../common/Constants";

const Groups = (permissionType) => {
  const [groups, setGroups] = useState([]);

  const getGroups = async () => {
    try {
      const res = await api.getGroups(permissionType);
      if (res && res.groups) {
        const groups = res.groups.map((group) => ({
          label: group,
          value: group
        }));
        setGroups(groups);
      } else {
        toast.error(
          "There was an error in fetching groups",
          Constants.TOAST_OPTIONS
        );
      }
    } catch (ex) {
      toast.error(
        `There was an error in fetching groups - ${ex.message}`,
        Constants.TOAST_OPTIONS
      );
    }
  };

  useEffect(() => {
    getGroups();
  }, []);

  return groups;
};

export default Groups;
