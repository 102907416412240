import PropTypes from "prop-types";
import React, { useState } from "react";

import Dropdown from "./Dropdown";

const MenuItems = ({ items }) => {
  const [dropdown, setDropdown] = useState(false);

  const toggleDropDown = (toggle) => {
    setDropdown(toggle);
  };

  return (
    <div className="nav-dropdown">
      {items.submenu ? (
        <>
          <div onClick={() => setDropdown((prev) => !prev)}>{items.title} </div>
          <Dropdown
            submenus={items.submenu}
            dropdown={dropdown}
            toggleDropDown={toggleDropDown}
          />
        </>
      ) : (
        <a href={items.url}>{items.title}</a>
      )}
    </div>
  );
};

MenuItems.propTypes = {
  items: PropTypes.instanceOf(Object),
  submenu: PropTypes.instanceOf(Array),
  url: PropTypes.string,
  title: PropTypes.string
};

MenuItems.defaultProps = {
  items: [],
  submenu: [],
  url: "",
  title: ""
};

export default MenuItems;
