import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import PropTypes from "prop-types";
import LoadingSpinner from "../../shared/LoadingSpinner";
import TicketHistoryCard from "./TicketHistoryCard";
import ParApi from "../../../services/ParApi";
import "../../../styles/par/ticket_history.scss";
import ParConstants from "../../../common/ParConstants";

const TicketHistory = ({ parRequestId }) => {
  const [ticketHistory, setTicketHistory] = useState([]);
  const [visibleTicketHistory, setVisibleTicketHistory] = useState(null);
  const [visibleTicketHistoryCount, setVisibleTicketHistoryCount] = useState(0);
  const getTodayDate = () => format(new Date(), "MM/dd/yyyy");
  const getYesterdayDate = () => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return format(yesterday, "MM/dd/yyyy");
  };

  // group the data based on date as key and items as array of records
  const groupByDateWithSpecialCases = (data) => {
    const todayDate = getTodayDate();
    const yesterdayDate = getYesterdayDate();
    return data.reduce((acc, item) => {
      const itemDate = format(new Date(item.created_date), "MM/dd/yyyy");
      let key;
      if (itemDate === todayDate) {
        key = "Today";
      } else if (itemDate === yesterdayDate) {
        key = "Yesterday";
      } else {
        key = itemDate;
      }

      const existingGroup = acc.find((group) => group.date === key);

      if (existingGroup) {
        existingGroup.items.push(item);
      } else {
        acc.push({ date: key, items: [item] });
      }
      return acc;
    }, []);
  };

  const getTicketHistory = async () => {
    const ticketHistories = await ParApi.getParHistory({
      par_request_id: parRequestId
    });

    if (ticketHistories && ticketHistories.length > 0) {
      const sortedData = ticketHistories.sort(
        (a, b) => new Date(b.created_date) - new Date(a.created_date)
      );
      setTicketHistory(sortedData);

      const groupedData = groupByDateWithSpecialCases(
        sortedData.slice(0, ParConstants.PAR_TICKET_HISTORY_COUNT)
      );
      setVisibleTicketHistoryCount(ParConstants.PAR_TICKET_HISTORY_COUNT);
      setVisibleTicketHistory(groupedData);
    } else {
      setVisibleTicketHistoryCount(0);
      setTicketHistory([]);
      setVisibleTicketHistory([]);
    }
  };

  const showMoreTicket = () => {
    const newCount =
      visibleTicketHistoryCount + ParConstants.PAR_TICKET_HISTORY_COUNT;
    const groupedData = groupByDateWithSpecialCases(
      ticketHistory.slice(0, newCount)
    );
    setVisibleTicketHistory(groupedData);
    setVisibleTicketHistoryCount(newCount);
  };

  useEffect(() => {
    getTicketHistory(parRequestId);
  }, []);

  return (
    <>
      <div>
        {!visibleTicketHistory && (
          <LoadingSpinner
            status="Loading"
            textMessage="Please wait, ticket history is being loaded..."
          />
        )}
        {visibleTicketHistory && visibleTicketHistory.length === 0 && (
          <LoadingSpinner
            status="NoData"
            textMessage="No Ticket History is available"
          />
        )}
        {visibleTicketHistory &&
          visibleTicketHistory.length > 0 &&
          visibleTicketHistory.map((ticket) => (
            <div key={ticket.date} className="ticket-history-group-container">
              <div className="ticket-history-date mb-1"> {ticket.date}</div>
              {ticket.items &&
                ticket.items.map((item) => (
                  <TicketHistoryCard item={item} key={item.action} />
                ))}
            </div>
          ))}
      </div>
      {visibleTicketHistory &&
        visibleTicketHistory.length > 0 &&
        visibleTicketHistoryCount < ticketHistory.length && (
          <div className="row show-more-history">
            <div className="ml-2">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => showMoreTicket()}
              >
                Show More History
              </button>
            </div>
          </div>
        )}
    </>
  );
};

TicketHistory.propTypes = {
  parRequestId: PropTypes.string
};

TicketHistory.defaultProps = {
  parRequestId: null
};

export default TicketHistory;
