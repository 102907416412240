import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import UploadFileSource from "../shared/UploadFileSource";
import Spinner from "../shared/Spinner";

import api from "../../services/CtsApi";
import Constants from "../../common/Constants";
import CTSConstants from "../../common/CTSConstants";

/*
 * Component that displays only system specific uploads, not shown to end users
 * Hidden path which we use to fix system data by uploading corrections
 */
const CTSSystemUploads = () => {
  // Get location data that is being set by the scenario list page
  const [batchSources, setBatchSources] = useState([]);
  const [isBatchSourceLoading, setIsBatchSourceLoading] = useState(false);

  const refreshBatchSources = async () => {
    try {
      setIsBatchSourceLoading(true);
      setBatchSources([]);
      const batchSourcesTemp = await api.getBatchSources(-1);
      if (batchSourcesTemp && batchSourcesTemp.batchSources) {
        setBatchSources(batchSourcesTemp.batchSources);
      } else {
        toast.error(
          "There was an error in fetching batch data",
          Constants.TOAST_OPTIONS
        );
      }
    } catch (ex) {
      // Ignore the exception, this means we did not get the data
      toast.error(
        `There was an error in fetching batch sources - ${ex.message}`,
        Constants.TOAST_OPTIONS
      );
    } finally {
      setIsBatchSourceLoading(false);
    }
  };

  useEffect(async () => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
    await refreshBatchSources();
  }, []);

  const renderSourceUpload = () => {
    let finalSources = [];
    CTSConstants.CTS_SYSTEM_SOURCES.forEach((source) => {
      const sourceObj =
        batchSources && batchSources.find((b) => b.sourceType === source);
      finalSources.push(
        <div className="mr-4" key={source}>
          <UploadFileSource
            batchId={-1}
            isBatchSourceLoading={isBatchSourceLoading}
            isScenarioLocked={false}
            source={sourceObj}
            sourceName={source}
            onSuccessfulUpload={refreshBatchSources}
          />
        </div>
      );
    });
    return <>{finalSources}</>;
  };

  return (
    <div className="ctsClass">
      <div className="row ml-3 mt-2 mb-2">
        <h4 className="ml-4 mt-2 mb-2">Upload System Sources</h4>
      </div>
      {isBatchSourceLoading && (
        <div className="row ml-4 mr-2 mb-4">
          Loading Sources.. <Spinner className="spinner text-center" />
        </div>
      )}
      {!isBatchSourceLoading && (
        <div className="row ml-3 mt-2 mb-2">
          <div className="col-md-4">{renderSourceUpload()}</div>
        </div>
      )}
    </div>
  );
};

export default CTSSystemUploads;
