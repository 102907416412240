import React from "react";
import PropTypes from "prop-types";

const Sidebar = ({ isOpen, onClose, children, header }) => {
  if (!isOpen) return null;

  return (
    <div className="sidebar-overlay" onClick={onClose}>
      <div className="sidebar-content" onClick={(e) => e.stopPropagation()}>
        <div className="sidebar-header">
          <h5>{header}</h5>
          <div className="sidebar-close" onClick={onClose}>
            &times;
          </div>
        </div>
        <div className="sidebar-body">{children}</div>
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node,
  header: PropTypes.string
};

Sidebar.defaultProps = {
  isOpen: false,
  onClose: () => {},
  children: <></>,
  header: ""
};

export default Sidebar;
