import { format } from "date-fns";

const getTimeStampFromDate = (dateStr) => {
  const timestamp = new Date(dateStr).getTime();
  /*
   *  Output of new Date(dateStr).getTime() is generally a number
   *  for valid date-time formats which in this
   *  case it is 'YYYY-MM-DDTHH:MM:SS.000Z'.
   *
   *  For other inputs:
   *   n - For any 'number' n
   *   NaN - For strings not in valid time format
   *   NaN - For 'undefined'
   *   0 - For 'null'
   * */
  return Number.isNaN(timestamp) ? 0 : timestamp;
};

const getUIDisplayDate = (dateTime) =>
  format(Date.parse(dateTime.replace(/ /g, "T")), "dd MMM yyyy h:mm a");

const formatDate = (datetime, dateFormat) => {
  const date = new Date(datetime);
  return format(date, dateFormat);
};

const getDate = (daysOffset = 0) => {
  const date = new Date();
  date.setDate(date.getDate() + daysOffset);
  return date.toISOString();
};

export default {
  getTimeStampFromDate,
  getUIDisplayDate,
  formatDate,
  getDate
};
