/* eslint-disable no-prototype-builtins */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";

import { toast } from "react-toastify";
import { isValid, format } from "date-fns";

import api from "../../services/CtsApi";
import ScenarioSelect from "./ScenarioSelect";

import Constants from "../../common/Constants";
import RapConstants from "../../common/RapConstants";
import CTSConstants from "../../common/CTSConstants";

import DatePickerBoxField from "../shared/DatePickerBoxField";
import ProgressOverlay from "../shared/ProgressOverlay";
import TextBoxField from "../shared/TextBoxField";
import TypeAheadBoxField from "../shared/TypeAheadBoxField";
import Spinner from "../shared/Spinner";
import Tooltip from "../shared/Tooltip";

import SuccessSound from "../../assets/success-sound-effect.mp3";

/**
 * The page that is used to edit individual employee record
 * for a selected scenario
 */

const ScenarioEditRecord = () => {
  // Get location data that is being set by the scenario list page
  const location = useLocation();

  const [selectedScenario, setSelectedScenario] = useState(location.state);
  const [isDataLoading, setIsDataLoading] = useState(true);

  // Sort the employee table
  const [sortField, setSortField] = useState("workdayId");
  const [sortOrder, setSortOrder] = useState("up");
  // Select a row in the table
  const [selectedRow, setSelectedRow] = useState({});

  // Set the form data after getting record details used to display right form
  const [formInput, setFormInput] = useState([]);
  const [isFormInputLoaded, setFormInputLoaded] = useState(false);

  // Show/Hide CY tables
  const [showCYTableOne, setShowCYTableOne] = useState(true);
  const [showCYTableTwo, setShowCYTableTwo] = useState(false);

  // Show/Hide Allocation section
  const [showAllocationNo, setShowAllocationNo] = useState(0);

  // Is form submiting or NOT
  const [isSubmitting, setSubmitting] = useState(false);

  // To render the table in left panel
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [employeeRecords, setEmployeeRecords] = useState([]);

  // To store the status of data processing process
  const [isDataProcessing, setIsDataProcessing] = useState(false);
  const [dataProcessingMsg, setDataProcessingMsg] = useState(<></>);

  const searchInputRef = React.createRef();

  // To navigate to next page
  const navigate = useNavigate();

  const currentYear = new Date().getFullYear();
  const nextYear = currentYear + 1;

  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
    // If we come to this page without selecting a scenario 1st on the list page
    if (!selectedScenario) {
      navigate("/scenarios");
    }
  }, []);

  const getEmployeeList = async (scenario) => {
    try {
      setIsDataLoading(true);
      const finalScenario = scenario || selectedScenario;
      // Get the employee list data
      const employeeList = await api.getEmployeeList({
        batchId: finalScenario.latestBatchId
      });
      if (employeeList) {
        setEmployeeRecords(employeeList);
        setFilteredRecords(employeeList);
      } else {
        toast.error(
          "There was an error in fetching employee list data",
          Constants.TOAST_OPTIONS
        );
      }
    } catch (ex) {
      // Ignore the exception, this means we did not get the data
      console.error(ex);
      toast.error(
        `There was an error in fetching employee list data' - ${ex.message}`,
        Constants.TOAST_OPTIONS
      );
    } finally {
      setIsDataLoading(false);
    }
  };

  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
    // If we come to this page without selecting a scenario 1st on the list page
    if (!selectedScenario) {
      navigate("/scenarios");
    }
    const timeOut = setTimeout(() => getEmployeeList(), 50);
    return () => {
      if (timeOut) {
        clearTimeout(timeOut);
      }
    };
  }, []);

  const handleScenarioChange = (value) => {
    setSelectedScenario(value);
    getEmployeeList(value);
    setFormInput([]);
    setFormInputLoaded(false);
  };

  const sortList = (field) => {
    const newSortOrder =
      sortField !== field ? "up" : sortOrder === "up" ? "down" : "up";
    let dir = newSortOrder === "up" ? 1 : -1;
    let dat = [...filteredRecords];
    setSortField(field);
    setSortOrder(newSortOrder);
    dat.sort((x, y) => {
      if (x[field] === y[field]) return 0;
      return x[field] < y[field] ? -1 * dir : 1 * dir;
    });
    setFilteredRecords(dat);
  };

  // Get the record details when a record is selected in the left table
  const loadRecord = async (workdayId) => {
    // confirm that it's not the "NULL" from WorkflowList
    // which indicates it wants to clear the form
    if (workdayId && workdayId !== RapConstants.EMPTY_RECORD_ID) {
      try {
        setFormInputLoaded(false);
        // Get the employee Detail data
        const employeeDetail = await api.getEmployeeDetails({
          batchId: selectedScenario.latestBatchId,
          workdayId
        });
        if (employeeDetail.records) {
          setFormInputLoaded(true);
          setFormInput(employeeDetail.records);
        } else {
          toast.error(
            "There was an error in fetching employee data",
            Constants.TOAST_OPTIONS
          );
        }
      } catch (ex) {
        console.error(ex);
        toast.error(
          `There was an error in loading the record - ${ex.message}`,
          Constants.TOAST_OPTIONS
        );
      }
    } else {
      setFormInputLoaded(true);
      setFormInput({});
    }
  };

  const showRowDetails = (row) => {
    setSelectedRow(row);
    loadRecord(row.workdayId || RapConstants.EMPTY_RECORD_ID);
  };

  // Method to get the search results for the left table by searching in the search bar
  const getSearchResults = () => {
    // Clear any previous call that's still running
    const enteredText = (searchInputRef.current.value || "").trim();
    if (enteredText === "") {
      // Do nothing
    }
    if (enteredText !== "") {
      // Load filtered employee List
      const filteredList = employeeRecords.filter(
        (record) =>
          (record.workdayId && record.workdayId.includes(enteredText)) ||
          (record.name &&
            record.name.toLowerCase().includes(enteredText.toLowerCase()))
      );
      setFilteredRecords(filteredList);
    } else {
      setFilteredRecords(employeeRecords);
    }
  };

  const sortIndicator = (field) =>
    field === sortField ? (
      <i className={"fas fa-sort-" + sortOrder} />
    ) : (
      <div />
    );

  const renderCYRows = (formikProps, year, rowIndex) => {
    let fieldKey = "cy";
    const fieldName = "allocations";
    if (currentYear !== year) {
      // Get fields for next year
      fieldKey = "cyNext";
    }

    const finalRows = [];
    CTSConstants.MONTHS.forEach((monthFull, index) => {
      // Need to get the Salary and Percent Fields from this
      const finalKey = `cy-${index}`;
      const month = monthFull.slice(0, 3);
      finalRows.push(
        <tr key={finalKey}>
          <td className="td-history">
            <div className="mt-2">{monthFull}</div>
          </td>
          <td className="td-history">
            <div className="col-md-4">
              <TextBoxField
                formikProps={formikProps}
                fieldName={`${fieldName}.${rowIndex}.${fieldKey}${month}Percent`}
                label=""
                className="mt-1 mr-2 mb-1"
                isOptional
                hideHeader
              />
            </div>
          </td>
          <td className="td-history">
            <div className="col-md-4">
              <TextBoxField
                formikProps={formikProps}
                fieldName={`${fieldName}.${rowIndex}.${fieldKey}${month}Salary`}
                label=""
                className="mt-1 mr-2 mb-1"
                isOptional
                hideHeader
              />
            </div>
          </td>
        </tr>
      );
    });
    return finalRows;
  };

  const renderCYTable = (formikProps, year, rowIndex) => (
    <table className="table">
      <thead className="thead-light">
        <tr>
          <th scope="col">Month</th>
          <th scope="col">
            <div className="col-md-4">Percentage</div>
          </th>
          <th scope="col">
            <div className="col-md-4">Salary</div>
          </th>
        </tr>
      </thead>
      <tbody>{renderCYRows(formikProps, year, rowIndex)}</tbody>
    </table>
  );

  const showTableOne = () => {
    setShowCYTableOne(true);
    setShowCYTableTwo(false);
  };

  const showTableTwo = () => {
    setShowCYTableOne(false);
    setShowCYTableTwo(true);
  };

  const getInitialValue = (formFieldRow, field) => {
    let initialValue = "";
    if (CTSConstants.LIST_FIELDS.includes(field)) {
      initialValue = null;
      if (formFieldRow[field] && formFieldRow[field] !== "null") {
        initialValue = {
          value: formFieldRow[field],
          label: formFieldRow[field]
        };
      }
    } else if (
      CTSConstants.DATE_FIELDS.includes(field) &&
      formFieldRow[field] !== "null"
    ) {
      initialValue = "";
      if (formFieldRow[field]) {
        // Convert to date which is required for the datePicker field
        const convertedDate = new Date(formFieldRow[field]);
        initialValue = isValid(convertedDate) ? convertedDate : "";
      }
    } else if (formFieldRow[field] && formFieldRow[field] !== "null") {
      initialValue = formFieldRow[field];
    } else {
      initialValue = "";
    }
    return initialValue;
  };

  // Method to get initial values for the form
  const getInitialValues = () => {
    let formInitialValues = {};
    const allocations = (formInput || []).map((row) => ({
      id: row.id,
      ...CTSConstants.ALLOCATION_FIELDS.reduce((acc, field) => {
        acc[field] = getInitialValue(row, field);
        return acc;
      }, {})
    }));
    formInitialValues.allocations = allocations;

    const formFields = Object.keys(CTSConstants.EDIT_FORM_FIELDS).filter(
      (field) =>
        !CTSConstants.ALLOCATION_FIELDS.includes(field) &&
        formInput &&
        formInput.length > 0
    );
    if (formFields.length > 0) {
      // Get the latest Updated Allocation Record, which will have
      // the correctRAP State
      // Filter all records that have endDate equal or after the Current Date
      const latestRecords = (formInput || []).filter(
        (rec) =>
          new Date(rec.statusEffEndDateCY).getTime() >= new Date().getTime()
      );

      // Default to last Allocation Record
      let latestRAPRecord = formInput[formInput.length - 1];
      // Execute the logic for checking the latest RAP entry only if
      // latestRecords array is not empty (i.e statusEffEndDateCY is not null)
      if (latestRecords && latestRecords.length > 0) {
        // Get the record with max date
        const latestRecord = latestRecords.reduce((a, b) =>
          new Date(a.statusEffEndDateCY) > new Date(b.statusEffEndDateCY)
            ? a
            : b
        );
        latestRAPRecord = latestRecord;
        // In case the record with max Date has no PIN (might have come from timesheets),
        // then we get the next best record with a valid MSL PIN/RoleId
        if (latestRecord && !latestRecord.roleId) {
          const recordsWithPIN = latestRecords.filter(
            (rec) => rec.roleId && rec.roleId !== ""
          );
          latestRAPRecord = recordsWithPIN.reduce((a, b) =>
            new Date(a.statusEffEndDateCY) > new Date(b.statusEffEndDateCY)
              ? a
              : b
          );
        }
      }

      const initialValues = formFields.reduce((acc, field) => {
        acc[field] = getInitialValue(latestRAPRecord, field);
        return acc;
      }, {});
      formInitialValues = { ...formInitialValues, ...initialValues };
    }

    return formInitialValues;
  };

  const renderAllocationsHeader = (formikProps) => (
    <div className="row ctsClass ml-1">
      {formikProps.values.allocations.map((allocation, index) => {
        const allocationId = allocation.id;
        const isActive = showAllocationNo === index;
        return (
          <div
            key={`head-allocation-${allocationId}`}
            className={`allocation-header cy-link${isActive ? "-active" : ""}`}
          >
            <a onClick={() => setShowAllocationNo(index)}>
              <span>{`Allocation - ${index + 1}`}</span>
            </a>
          </div>
        );
      })}
    </div>
  );

  const renderAllocationsSection = (formikProps) => {
    const fieldName = "allocations";
    const index = showAllocationNo;
    return (
      <FieldArray
        name={fieldName}
        render={() => (
          <div key={`allocation_row_${index}`}>
            <div className="row mb-2 ml-1">
              <div className="col-md-2 mr-4">
                <TextBoxField
                  formikProps={formikProps}
                  fieldName={`${fieldName}.${index}.clientTitle`}
                  label="Client - Title"
                  isOptional
                />
              </div>
              <div className="col-md-2 mr-4">
                <TextBoxField
                  formikProps={formikProps}
                  fieldName={`${fieldName}.${index}.clientScope`}
                  label="Client Scope"
                  isOptional
                />
              </div>
              <div className="col-md-2 mr-4">
                <TextBoxField
                  formikProps={formikProps}
                  fieldName={`${fieldName}.${index}.client`}
                  label="Client"
                  isOptional
                />
              </div>
              <div className="col-md-2 mr-4">
                <TextBoxField
                  formikProps={formikProps}
                  fieldName={`${fieldName}.${index}.clientAOR`}
                  label="Client AOR"
                  isOptional
                />
              </div>
              <div className="col-md-2 mr-4">
                <TextBoxField
                  formikProps={formikProps}
                  fieldName={`${fieldName}.${index}.clientDiscipline`}
                  label="Client - Discipline"
                  isOptional
                />
              </div>
            </div>
            <div className="row mb-2 ml-1">
              <div className="col-md-2 mr-4">
                <TextBoxField
                  formikProps={formikProps}
                  fieldName={`${fieldName}.${index}.ftePlanned`}
                  label="FTE % Planned"
                  isOptional
                />
              </div>
              <div className="col-md-2 mr-4">
                <TextBoxField
                  formikProps={formikProps}
                  fieldName={`${fieldName}.${index}.salaryPlanned`}
                  label="Salary Planned"
                  isOptional
                />
              </div>
              <div className="col-md-2 mr-4 mt-2">
                <DatePickerBoxField
                  formikProps={formikProps}
                  label="Status Eff. Date CY"
                  isArrayField
                  arrayFieldName="allocations"
                  arrayIndex={index}
                  fieldName="statusEffDateCY"
                />
              </div>
              <div className="col-md-2 mr-4">
                <DatePickerBoxField
                  formikProps={formikProps}
                  label="Status Eff. End Date CY"
                  isArrayField
                  arrayFieldName="allocations"
                  arrayIndex={index}
                  fieldName="statusEffEndDateCY"
                />
              </div>
            </div>

            <div className="row ctsClass ml-1 mt-4">
              <div
                className={`col-sm-1 cy-link${showCYTableOne ? "-active" : ""}`}
              >
                <a
                  onClick={() => {
                    showTableOne();
                  }}
                >
                  <span>{`CY (${currentYear})`}</span>
                </a>
              </div>
              <div
                className={`col-sm-4 cy-link${showCYTableTwo ? "-active" : ""}`}
              >
                <a
                  onClick={() => {
                    showTableTwo();
                  }}
                >
                  <span>{`CY + 1 (${nextYear})`}</span>
                </a>
              </div>
            </div>
            <div className="ctsClass">
              <hr />
            </div>
            {showCYTableOne && (
              <div className="row mb-2 ml-2">
                {renderCYTable(formikProps, currentYear, index)}
              </div>
            )}
            {showCYTableTwo && (
              <>
                <div className="row mb-2 ml-1">
                  <div className="col-md-2 mr-4">
                    <TextBoxField
                      formikProps={formikProps}
                      fieldName={`${fieldName}.${index}.cyNextProposedPromoAnnualSalary`}
                      label="Proposed Promo Annual Salary %"
                      isOptional
                    />
                  </div>
                  <div className="col-md-2 mr-4">
                    <TextBoxField
                      formikProps={formikProps}
                      fieldName={`${fieldName}.${index}.cyNextYPlusOneProposedPromoAnnualSalary`}
                      label="Y+1 Proposed Promo Salary $"
                      isOptional
                    />
                  </div>
                  <div className="col-md-2 mr-4 mt-2">
                    <DatePickerBoxField
                      formikProps={formikProps}
                      label="Y+1 Proposed Date"
                      isArrayField
                      arrayFieldName="allocations"
                      arrayIndex={index}
                      fieldName="cyNextYPlusOneProposedDate"
                    />
                  </div>
                  <div className="col-md-2 mr-4">
                    <TextBoxField
                      formikProps={formikProps}
                      fieldName={`${fieldName}.${index}.cyNextCyEffectiveRaise`}
                      label="CY Effective Raise"
                      isOptional
                    />
                  </div>
                  <div className="col-md-2 mr-4">
                    <TextBoxField
                      formikProps={formikProps}
                      fieldName={`${fieldName}.${index}.cyNextYPlusOneSalary`}
                      label="Promo/Merit/Salary Adj Y+1"
                      isOptional
                    />
                  </div>
                </div>
                <div className="row mb-2 ml-1">
                  <div className="col-md-2 mr-4">
                    <TextBoxField
                      formikProps={formikProps}
                      fieldName={`${fieldName}.${index}.cyNextYPlusOneStatus`}
                      label="Status Y+1"
                      isOptional
                    />
                  </div>
                  <div className="col-md-2 mr-4 mt-2">
                    <DatePickerBoxField
                      formikProps={formikProps}
                      label="Status Eff. Date Y+1"
                      isArrayField
                      arrayFieldName="allocations"
                      arrayIndex={index}
                      fieldName="cyNextYPlusStatusEffDate"
                    />
                  </div>
                </div>
                <div className="row mb-2 ml-2 mt-4">
                  {renderCYTable(formikProps, nextYear, index)}
                </div>
              </>
            )}
          </div>
        )}
      />
    );
  };

  const renderScenarioEditForm = (formikProps) => (
    <div className="card mr-1 mainform-card">
      <div className="form-horizontal col-md-12">
        <div className="row mb-2 ml-3 mt-3">
          <div className="scenario-header">
            {formInput.length > 0 && formInput[0].name}
          </div>
        </div>

        <div className="row mb-2 ml-3 mt-3">
          <div className="mismatch-header">Employee</div>
        </div>
        <div className="row mb-2 ml-1">
          <div className="col-md-2 mr-4">
            <TextBoxField
              formikProps={formikProps}
              fieldName="payrollId"
              label="Payroll ID"
              isOptional
            />
          </div>
          <div className="col-md-2 mr-4">
            <TextBoxField
              formikProps={formikProps}
              fieldName="workdayId"
              label="Employee Workday ID"
              isOptional={false}
            />
          </div>
          <div className="col-md-2 mr-4">
            <TextBoxField
              formikProps={formikProps}
              fieldName="company"
              label="Company"
              isOptional
            />
          </div>
          <div className="col-md-2 mr-4">
            <TextBoxField
              formikProps={formikProps}
              fieldName="profitCenter"
              label="Profit Center"
              isOptional
            />
          </div>
          <div className="col-md-2 mr-4">
            <TextBoxField
              formikProps={formikProps}
              fieldName="name"
              label="NAME"
              isOptional
            />
          </div>
        </div>
        <div className="row mb-2 ml-1">
          <div className="col-md-2 mr-4">
            <TextBoxField
              formikProps={formikProps}
              fieldName="employeeHub"
              label="Employee Hub "
              isOptional
            />
          </div>
          <div className="col-md-2 mr-4">
            <TextBoxField
              formikProps={formikProps}
              fieldName="employeeDiscipline"
              label="Employee Discipline"
              isOptional
            />
          </div>
          <div className="col-md-2 mr-4">
            <TextBoxField
              formikProps={formikProps}
              fieldName="employeeFunction"
              label="Employee Function"
              isOptional
            />
          </div>
          <div className="col-md-2 mr-4">
            <TextBoxField
              formikProps={formikProps}
              fieldName="businessTitle"
              label="Business Title"
              isOptional
            />
          </div>
          <div className="col-md-2 mr-4">
            <TextBoxField
              formikProps={formikProps}
              fieldName="location"
              label="Location"
              isOptional
            />
          </div>
        </div>
        <div className="row mb-3 ml-1">
          <div className="col-md-2 mr-4">
            <TextBoxField
              formikProps={formikProps}
              fieldName="mgmtLevel"
              label="MGMT LVL "
              isOptional
            />
          </div>
          <div className="col-md-2 mr-4">
            <TypeAheadBoxField
              formikProps={formikProps}
              suggestions={RapConstants.EMPLOYEE_STATUS_VALUES}
              fieldName="statusCY"
              label="Status CY"
              isOptional
            />
          </div>
          <div className="col-md-2 mt-2 mr-4">
            <TypeAheadBoxField
              formikProps={formikProps}
              suggestions={CTSConstants.BPC_SEGMENT_VALUES}
              fieldName="bpcSegment"
              label="BPC Segment"
              isOptional
            />
          </div>
          <div className="col-md-2 mr-4">
            <TextBoxField
              formikProps={formikProps}
              fieldName="roleId"
              label="Role ID/PIN"
              isOptional
            />
          </div>
        </div>
        <div className="left-margin-highlight ml-2 mb-2 highlighted-div">
          <div className="row mb-2 ml-3 mt-4">
            <div className="mismatch-header">Salary</div>
          </div>
          <div className="row mb-2 ml-1">
            <div className="col-md-3">
              <TextBoxField
                formikProps={formikProps}
                fieldName="annualSalary"
                label="Annual (USD)"
                isOptional
              />
            </div>
            <div className="col-md-3 mt-2">
              <TypeAheadBoxField
                formikProps={formikProps}
                suggestions={CTSConstants.SALARY_TYPE_VALUES}
                fieldName="salaryType"
                label="Salary Type"
                isOptional
              />
            </div>
            <div className="col-md-3">
              <TextBoxField
                formikProps={formikProps}
                fieldName="proposedAnnualSalary"
                label="CY Proposed Promo Annual Salary %"
                isOptional
              />
            </div>
            <div className="col-md-3">
              <TextBoxField
                formikProps={formikProps}
                fieldName="proposedCYSalary"
                label="CY Proposed Promo Salary $"
                isOptional
              />
            </div>
          </div>
          <div className="row mb-2 ml-1">
            <div className="col-md-3 mt-2">
              <DatePickerBoxField
                formikProps={formikProps}
                label="CY Proposed Date"
                fieldName="proposedCYDate"
              />
            </div>
            <div className="col-md-3">
              <TextBoxField
                formikProps={formikProps}
                fieldName="effectiveCYRaise"
                label="CY Effective Raise"
                isOptional
              />
            </div>
            <div className="col-md-3">
              <TypeAheadBoxField
                formikProps={formikProps}
                suggestions={CTSConstants.SALARY_ADJCY_VALUES}
                fieldName="salaryAdjCY"
                label="Promo/Merit/Salary Adj CY"
                isOptional
              />
            </div>
          </div>
        </div>

        <div className="row mb-3 ml-3 mt-4">
          <div className="mismatch-header">Allocations</div>
        </div>

        {renderAllocationsHeader(formikProps)}
        <div className="ctsClass">
          <hr />
        </div>
        {renderAllocationsSection(formikProps)}
      </div>
    </div>
  );

  // Submit the Form
  const handleSubmit = async (formData) => {
    try {
      setSubmitting(true);
      let finalValues = [];

      // Set the allocations data
      if (formData.allocations) {
        formData.allocations.forEach((allocation) => {
          let allocationRow = {};
          for (let allocKey in allocation) {
            if (allocation.hasOwnProperty(allocKey)) {
              allocationRow[allocKey] = allocation[allocKey];
              if (CTSConstants.DATE_FIELDS.includes(allocKey)) {
                allocationRow[allocKey] =
                  allocation[allocKey] && allocation[allocKey] !== ""
                    ? format(allocation[allocKey], "MM/dd/yyyy")
                    : "";
              }
            }
          }
          finalValues.push(allocationRow);
        });
      }

      for (let key in formData) {
        if (formData.hasOwnProperty(key)) {
          let finalVal = "";
          if (key !== "allocations") {
            if (CTSConstants.LIST_FIELDS.includes(key)) {
              // Get only the value from the list object
              finalVal = formData[key] ? formData[key].value : "";
            } else if (CTSConstants.DATE_FIELDS.includes(key)) {
              // This will always set the date in MM/DD/YYYY format
              finalVal =
                formData[key] && formData[key] !== ""
                  ? format(formData[key], "MM/dd/yyyy")
                  : "";
            } else {
              finalVal = formData[key] ? formData[key] : "";
            }
            finalValues.forEach((item) => {
              item[key] = finalVal;
            });
          }
        }
      }

      // Set the form values as is, since the backend is not updated,
      // so we need to set it with the values that were sent into the Update Method
      setFormInput(finalValues);

      // Update the employee record
      const result = await api.updateEmployeeDetails({
        batchId: selectedScenario.latestBatchId,
        workdayId: finalValues[0].workdayId,
        employeeRecordsList: finalValues
      });

      if (result && result.status === CTSConstants.SUCCESS) {
        const updatedRecord = {
          name: finalValues[0].name,
          workdayId: finalValues[0].workdayId
        };
        const updatedEmployees = employeeRecords;
        const foundIndex = employeeRecords.findIndex(
          (rec) => rec.workdayId === finalValues[0].workdayId
        );
        updatedEmployees[foundIndex] = updatedRecord;

        const updatedFilteredRecords = filteredRecords;
        const index = filteredRecords.findIndex(
          (rec) => rec.workdayId === finalValues[0].workdayId
        );
        updatedFilteredRecords[index] = updatedRecord;
        setEmployeeRecords(updatedEmployees);
        setFilteredRecords(updatedFilteredRecords);
        toast.success(
          "Employee Record updates saved. Please press Reprocess to finalize the updates in report data.",
          Constants.TOAST_OPTIONS
        );
      } else {
        toast.error(
          "There was an error in saving the employee record",
          Constants.TOAST_OPTIONS
        );
      }
    } catch (ex) {
      // Ignore the exception, this means we did not get the data
      console.error(ex);
      toast.error(
        `There was an error in saving the employee record' - ${ex.message}`,
        Constants.TOAST_OPTIONS
      );
    } finally {
      setSubmitting(false);
    }
  };

  // Method to poll the current status of the data ReProcess kickoff
  const pollDataProcessStatus = async (batchId) => {
    let processStatus = "";
    try {
      const batchDetails = await api.getBatchDetails(batchId);
      if (batchDetails && batchDetails.processStatus) {
        processStatus = batchDetails.processStatus;
      }
    } catch (ex) {
      console.error(ex);
      toast.error(
        `There was an error in reprocessing data - ${ex.message}`,
        Constants.TOAST_OPTIONS
      );
    }
    return processStatus;
  };

  const reprocessData = async (workdayId) => {
    let processStatus = "";
    let isDataProcessDone = false;
    setIsDataProcessing(true);
    const message = (
      <>Reprocessing Report Data. Please wait till the process completes.</>
    );
    setDataProcessingMsg(message);
    try {
      // Just kick off the data process without checking its results
      await api.runDataProcess({ batchId: selectedScenario.latestBatchId });
    } catch (ex) {
      // Ignore the error "Failed to fetch", this indicates the gateway timeout
      // that the lambda gateway might return if the process runs longer
      if (ex.message !== "Failed to fetch") {
        // Show the error if its a genuine issue
        toast.error(
          "There was an error in reprocessing the data",
          Constants.TOAST_OPTIONS
        );
        isDataProcessDone = true;
        setIsDataProcessing(false);
        setDataProcessingMsg(<></>);
        return;
      }
    }
    try {
      // Keep Polling till this process is done (i.e. processStatus column value is done)
      do {
        processStatus = await pollDataProcessStatus(
          selectedScenario.latestBatchId
        );
        isDataProcessDone =
          processStatus === CTSConstants.PROCESS_DONE ||
          processStatus === CTSConstants.PROCESS_FINAL;
        // wait a second...
        await new Promise((resolve) => setTimeout(resolve, 1000)); // eslint-disable-line
      } while (!isDataProcessDone);
      if (isDataProcessDone) {
        // Reload the record to get the actual saved values
        loadRecord(workdayId);
        toast.success("Data reprocessed successfully", Constants.TOAST_OPTIONS);
        // Play a success sound when reprocessing is done
        const successAlert = new Audio(SuccessSound);
        successAlert.play();
      } else {
        toast.error(
          "There was an error in reprocessing the data",
          Constants.TOAST_OPTIONS
        );
      }
    } catch (ex) {
      console.error(ex);
      toast.error(
        `There was an error in reprocessing the data - ${ex.message}`,
        Constants.TOAST_OPTIONS
      );
    } finally {
      setIsDataProcessing(false);
      setDataProcessingMsg(<></>);
    }
  };

  const downloadFile = async (batchId, sourceId, fileType) => {
    let filePath = "";
    try {
      // Download final ReportResult file
      const response = await api.downloadDataFile({
        batchId,
        sourceId,
        fileType
      });
      // Get full url
      if (response && response.url) {
        filePath = response.url;
        await window.open(`${filePath}`, "_blank");
      } else {
        // Show error
        toast.error(
          "There was a problem in downloading the file.",
          Constants.TOAST_OPTIONS
        );
      }
    } catch (ex) {
      console.error(ex);
      toast.error(
        `There was an unexpected error in downloading the file - ${ex.message}`,
        Constants.TOAST_OPTIONS
      );
    }
  };

  const getValidationSchema = () => {
    const errorMsg = "Please enter a value";
    const schemaObj = {};
    // Only validate workdayId
    if (!schemaObj.workdayId) {
      // Skip validations for some fields that are not required
      schemaObj.workdayId = Yup.string().trim().required(errorMsg);
    }
    return Yup.object(schemaObj);
  };

  return (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={getInitialValues()}
      validationSchema={getValidationSchema()}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form id="mainCTSForm">
          <div className="ctsClass">
            <div className="row search-header ml-4 mr-2 mb-4">
              <div className="col-md-4">
                <ScenarioSelect
                  initialScenarioValue={selectedScenario}
                  navigationLink="/ctsupload"
                  changeScenario={handleScenarioChange}
                />
              </div>
              <div className="col-md-2 blue-text mt-2">
                <a
                  onClick={() =>
                    downloadFile(
                      selectedScenario.latestBatchId,
                      null,
                      "ReportResult"
                    )
                  }
                >
                  <i className="fas fa-download" />
                  &nbsp; Download Scenario Data
                </a>
              </div>
              <div className="col-md-6">
                <div className="float-right">
                  <a
                    className="blue-text mt-1 mr-3"
                    onClick={() => {
                      navigate("/ctsupload", { state: selectedScenario });
                    }}
                  >
                    Cancel
                  </a>
                  <button
                    type="button"
                    className="btn btn-sm btn-secondary mr-3"
                    disabled={isSubmitting}
                    onClick={() => {
                      reprocessData(formikProps.values.workdayId);
                    }}
                  >
                    Reprocess
                  </button>
                  <button
                    type="submit"
                    className={`btn btn-sm btn-${
                      formikProps.isValid ? "success" : "invalid"
                    }`}
                    disabled={
                      isSubmitting || !formikProps.dirty || !formikProps.isValid
                    }
                  >
                    {isSubmitting ? "Submitting..." : "Save"}
                  </button>
                </div>
              </div>
            </div>
          </div>
          {!isDataLoading && (
            <div className="row ml-1 pr-0">
              <div className="col-sm-2 pr-0">
                <div>
                  <table className="col-md-12 top-filter">
                    <tbody>
                      <tr>
                        <td width="100%">
                          <div
                            style={{
                              margin: 0,
                              padding: 0,
                              width: "100%",
                              maxWidth: "unset"
                            }}
                            className="col-md-7"
                          >
                            <table className="col-md-12">
                              <tbody>
                                <tr className="top-filter">
                                  <td width="100%" className="top-filter">
                                    <Tooltip text="Type Workday ID or Name and press Enter or Search Button">
                                      <input
                                        type="text"
                                        className="form-control"
                                        style={{ width: "100% !important" }}
                                        ref={searchInputRef}
                                        onKeyDown={(e) => {
                                          if (e.key === "Enter") {
                                            e.preventDefault();
                                            setTimeout(
                                              () => getSearchResults(),
                                              10
                                            );
                                            return false;
                                          }
                                          return true;
                                        }}
                                      />
                                    </Tooltip>
                                  </td>
                                  <td className="top-filter">
                                    <div
                                      className="input-group-append"
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setTimeout(
                                          () => getSearchResults(),
                                          10
                                        );
                                        return false;
                                      }}
                                    >
                                      <span className="input-group-text rounded-right">
                                        <i
                                          className="fa fa-search"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {(!filteredRecords || filteredRecords.length === 0) && (
                  <div>
                    {!isDataLoading && (
                      <div className="mt-2 card">
                        <div className="no-records-div">
                          <div className="no-records-text">
                            No records found
                          </div>
                        </div>
                      </div>
                    )}
                    {isDataLoading && (
                      <div className="ml-2 mt-2">
                        Loading Records...
                        <Spinner />
                      </div>
                    )}
                  </div>
                )}
                {filteredRecords && filteredRecords.length > 0 && (
                  <div className="ctsClass">
                    <div className="form-group mt-2 card">
                      <div className="wfTable ml-2">
                        <div className="wfTableHead">
                          <div
                            className="ctsWorkdayIdHead"
                            onClick={() => sortList("workdayId")}
                          >
                            Workday ID&nbsp;{sortIndicator("workdayId")}
                          </div>
                          <div
                            className="ctsNameHead"
                            onClick={() => sortList("name")}
                          >
                            Name&nbsp;{sortIndicator("name")}
                          </div>
                        </div>
                      </div>
                      <div className="table-wrap scrollable ml-2 mr-1">
                        <div className="wfTable">
                          {filteredRecords.map((r) => (
                            <div
                              key={`workflow-${r.workdayId}-${r.name}`}
                              className={
                                "wfTableRow" +
                                (r.workdayId === selectedRow.workdayId &&
                                r.name === selectedRow.name
                                  ? " selected"
                                  : "")
                              }
                              onClick={() => showRowDetails(r)}
                            >
                              <div className="ctsWorkdayId">{r.workdayId}</div>
                              <div className="ctsName">{r.name}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-sm-10">
                {isFormInputLoaded &&
                  formInput.length > 0 &&
                  formInput[0].workdayId &&
                  renderScenarioEditForm(formikProps)}
                {
                  // Display a placeholder msg till the data is not loaded
                  !isFormInputLoaded && (
                    <div className="ctsClass">
                      <div className="card">
                        <div className="cts-no-data">
                          <div className="row col-md-12">
                            <div className="col-md-3" />
                            {selectedRow.workdayId && (
                              <>
                                Loading Record..
                                <Spinner waiting />
                              </>
                            )}
                            {!selectedRow.workdayId && (
                              <div className="col-md-9">
                                Select record on the left to edit
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          )}
          {isDataLoading && (
            <div className="row ml-4 pr-0">
              Loading Employee List..
              <Spinner waiting />
            </div>
          )}
          {isDataProcessing && <ProgressOverlay message={dataProcessingMsg} />}
        </Form>
      )}
    </Formik>
  );
};

export default ScenarioEditRecord;
