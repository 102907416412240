/* eslint-disable no-return-assign */
import React from "react";
import PropTypes from "prop-types";
import TextBoxField from "../../shared/TextBoxField";
import TypeAheadBoxField from "../../shared/TypeAheadBoxField";
import DatePickerBoxField from "../../shared/DatePickerBoxField";
import ParConstants from "../../../common/ParConstants";
import RapConstants from "../../../common/RapConstants";
import CollapsibleCard from "../../shared/CollapsibleCard";

const InternalFill = ({
  formikProps,
  canModifyPARRecords,
  availablePINs,
  showDescription,
  showFieldsReadOnly,
  hideFields,
  parGeoRange,
  parPayRanges
}) => {
  const setEmployeeDetails = (value) => {
    let details = availablePINs.filter((x) => x.employeeName === value);
    if (details && details[0]) {
      formikProps.setFieldValue(
        "employee_workdayid",
        details[0]?.employeeName.split("-")[1].trim()
      );
      formikProps.setFieldValue(
        "employee_workdaytitle",
        details[0]?.workdayTitle
      );
      formikProps.setFieldValue("employee_type", {
        label: details[0]?.employeeType,
        value: details[0]?.employeeType
      });
      formikProps.setFieldValue("employee_location", details[0]?.location);
    }
    if (value === RapConstants.EMPLOYEE_TYPE_VALUES[1]) {
      formikProps.setFieldValue("job_code", null);
    }
  };

  const getCompRatioOfOffer = () => {
    /* eslint-disable camelcase */
    const { employee_location, job_code, requested_offer, par_level } =
      formikProps?.values || {};

    const level = par_level?.value?.match(/\d+/)?.[0];
    const jobCodeValue = job_code?.value;

    // Ensure all necessary fields are present before proceeding
    if (!employee_location || !jobCodeValue || !requested_offer || !level) {
      return "";
    }

    // Find the corresponding geo range and pay range
    const geoRange = parGeoRange.find((x) =>
      employee_location.includes(x.parentValue)
    );
    const parRange = parPayRanges.find(
      (parPayRange) =>
        parPayRange.job_profile === jobCodeValue &&
        parPayRange.level.includes(level)
    );
    /* eslint-enable camelcase */
    if (geoRange && parRange) {
      const marketMidpoints = {
        medium: parRange.mid_market_mid,
        high: parRange.high_market_mid,
        low: parRange.low_market_mid
      };

      const salaryMidPoint = parseFloat(marketMidpoints[geoRange.value] || 0);

      if (salaryMidPoint !== 0) {
        return parseFloat(
          (parseFloat(requested_offer) / salaryMidPoint).toFixed(2)
        );
      }
    }
    return "";
  };

  return (
    <CollapsibleCard
      className="highlighted-panel"
      title="Internal Fill Requirements"
    >
      <div className="row">
        <div className="col-md-3 mt-3">
          <TypeAheadBoxField
            formikProps={formikProps}
            suggestions={availablePINs.map((x) => x.employeeName)}
            fieldName="employee_name"
            label="Internal Fill Employee"
            disable={!canModifyPARRecords}
            onValueChange={setEmployeeDetails}
            showDescription={showDescription}
          />
        </div>
        {formikProps.values?.employee_type &&
          formikProps.values.employee_type?.value !==
            RapConstants.EMPLOYEE_TYPE_VALUES[1] &&
          !hideFields.compa_ratio_of_offer && (
            <div className="mt-3 col-md-3">
              <div className="ml-1">
                <label htmlFor="compa_ratio_of_offer">
                  Compa Ratio of Offer
                </label>
                <div
                  id="compa_ratio_of_offer"
                  className="form-description mt-2"
                >
                  <strong>{getCompRatioOfOffer()}</strong>
                </div>
              </div>
            </div>
          )}
      </div>
      {formikProps.values.employee_name &&
        formikProps.values.employee_name.value && (
          <>
            <div className="row">
              <div className="col-md-10 mt-3">
                <div className="row par-table par-table-highlight ml-0">
                  <div className="col-md-3">
                    <strong>Employee Workday ID</strong>
                  </div>
                  <div className="col-md-3">
                    <strong>Business Title</strong>
                  </div>
                  <div className="col-md-3">
                    <strong>Employee Type</strong>
                  </div>
                  <div className="col-md-3">
                    <strong>Employee Location</strong>
                  </div>
                </div>
              </div>
              <div className="col-md-10">
                <div className="row par-table  par-table-content ml-0">
                  <div className="col-md-3">
                    <strong>{formikProps.values?.employee_workdayid}</strong>
                  </div>
                  <div className="col-md-3">
                    <strong>{formikProps.values?.employee_workdaytitle}</strong>
                  </div>
                  <div className="col-md-3">
                    <strong>{formikProps.values?.employee_type?.value}</strong>
                  </div>
                  <div className="col-md-3">
                    <strong>{formikProps.values?.employee_location}</strong>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              {!hideFields.current_base_salary && (
                <div className="col-md-2 mt-3">
                  <TextBoxField
                    className="ml-1"
                    type="number"
                    formikProps={formikProps}
                    fieldName="current_base_salary"
                    label="Current Base Salary"
                    disable={!canModifyPARRecords}
                    isOptional
                    showDescription={showFieldsReadOnly.current_base_salary}
                  />
                </div>
              )}
              {!hideFields.requested_offer && (
                <div className="col-md-2 mt-3">
                  <TextBoxField
                    className="ml-1"
                    type="number"
                    formikProps={formikProps}
                    fieldName="requested_offer"
                    label="Requested Offer"
                    disable={!canModifyPARRecords}
                    isOptional
                    showDescription={showFieldsReadOnly.requested_offer}
                  />
                </div>
              )}
              {!hideFields.date_of_last_compensation_change && (
                <div className="col-md-2 mt-3">
                  <DatePickerBoxField
                    className="ml-0"
                    formikProps={formikProps}
                    label="Date Of Last Compensation Change"
                    isOptional
                    fieldName="date_of_last_compensation_change"
                    disable={!canModifyPARRecords}
                    showDescription={
                      showFieldsReadOnly.date_of_last_compensation_change
                    }
                  />
                </div>
              )}
              {!hideFields.date_of_lastpromotion && (
                <div className="col-md-2 mt-3">
                  <DatePickerBoxField
                    className="ml-0"
                    formikProps={formikProps}
                    label="Date Of Last Promotion"
                    isOptional
                    fieldName="date_of_lastpromotion"
                    disable={!canModifyPARRecords}
                    showDescription={showFieldsReadOnly.date_of_lastpromotion}
                  />
                </div>
              )}
              {!hideFields.current_performance_rating && (
                <div className="col-md-2 mt-3">
                  <TypeAheadBoxField
                    formikProps={formikProps}
                    suggestions={ParConstants.PERFORMANCE_RATINGS}
                    fieldName="current_performance_rating"
                    label="Current Performance Rating"
                    disable={!canModifyPARRecords}
                    isOptional
                    showDescription={
                      showFieldsReadOnly.current_performance_rating
                    }
                  />
                </div>
              )}
            </div>
          </>
        )}
    </CollapsibleCard>
  );
};

InternalFill.propTypes = {
  formikProps: PropTypes.shape({
    values: PropTypes.shape({
      employee_name: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      }),
      employee_workdayid: PropTypes.string,
      employee_workdaytitle: PropTypes.string,
      employee_location: PropTypes.string,
      employee_type: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      })
    }),
    setFieldValue: PropTypes.func,
    handleBlur: PropTypes.func
  }).isRequired,
  canModifyPARRecords: PropTypes.bool,
  showDescription: PropTypes.bool,
  availablePINs: PropTypes.instanceOf(Array),
  parGeoRange: PropTypes.instanceOf(Array),
  parPayRanges: PropTypes.instanceOf(Array),
  showFieldsReadOnly: PropTypes.shape({
    current_performance_rating: PropTypes.bool,
    current_base_salary: PropTypes.bool,
    date_of_last_compensation_change: PropTypes.bool,
    date_of_lastpromotion: PropTypes.bool,
    requested_offer: PropTypes.bool
  }),
  hideFields: PropTypes.shape({
    current_performance_rating: PropTypes.bool,
    current_base_salary: PropTypes.bool,
    date_of_last_compensation_change: PropTypes.bool,
    date_of_lastpromotion: PropTypes.bool,
    requested_offer: PropTypes.bool,
    compa_ratio_of_offer: PropTypes.bool
  })
};

InternalFill.defaultProps = {
  availablePINs: [],
  parGeoRange: [],
  parPayRanges: [],
  canModifyPARRecords: false,
  showDescription: false,
  showFieldsReadOnly: {},
  hideFields: {}
};

export default InternalFill;
