import PropTypes from "prop-types";
import React, { useState, useRef, useEffect } from "react";
import ParConstants from "../../../common/ParConstants";

// Dropdown Menu Component
const DropdownMenu = ({ parWorkFlows, row, handleClick }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const toggleDropdown = () => setIsOpen(!isOpen);
  let dropdownOptions = parWorkFlows;

  if (
    row.allCells.filter((item) => item.column.id === "pinStatus").length > 0
  ) {
    const pinStatus = row.allCells.filter(
      (item) => item.column.id === "pinStatus"
    )[0].value;
    if (pinStatus === ParConstants.PIN_STATUS.TBH) {
      // For TBH PIN's we show only "Open Requisition (TBH PIN)" and "ReAllocation" Options
      dropdownOptions = parWorkFlows.filter(
        (item) =>
          item.name.toLowerCase() ===
            ParConstants.PAR_WORKFLOW.OPEN_REQUISITION_TBH.toLowerCase() ||
          item.name.toLowerCase() ===
            ParConstants.PAR_WORKFLOW.REALLOCATION.toLowerCase()
      );
    } else {
      // For Active PIN's we show all options except
      // "Open Requisition" & "Open Requisition (TBH PIN)"
      dropdownOptions = parWorkFlows.filter(
        (item) =>
          !item.name
            .toLowerCase()
            .includes(ParConstants.PAR_WORKFLOW.OPEN_REQUISITION.toLowerCase())
      );
    }
  }

  // Function to close the dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <>
      {row.depth === 0 && (
        <div ref={dropdownRef} style={{ position: "relative" }}>
          <button type="button" className="menuButton" onClick={toggleDropdown}>
            <i className="fas fa-bars fa-xs" />
          </button>
          {isOpen && (
            <div className="dropdownmenu">
              <ul style={{ listStyle: "none", padding: 0 }}>
                {dropdownOptions.map((item) => (
                  <li
                    className="options"
                    key={item.workflow_id}
                    onClick={(event) => handleClick(event, item, row.original)}
                  >
                    {item.name}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
    </>
  );
};

DropdownMenu.propTypes = {
  row: PropTypes.shape({
    depth: PropTypes.number,
    allCells: PropTypes.arrayOf(PropTypes.shape({})),
    values: PropTypes.shape({}),
    original: PropTypes.shape({})
  }),
  parWorkFlows: PropTypes.instanceOf(Array).isRequired,
  handleClick: PropTypes.func
};

DropdownMenu.defaultProps = {
  row: [],
  handleClick: () => {}
};

export default DropdownMenu;
