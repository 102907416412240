import React, { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import debounce from "lodash.debounce";

import UserTable from "./UserTable";
import ConfirmDeleteUserModal from "./ConfirmDeleteUserModal";
import Users from "../hooks/Users";
import api from "../../services/UserAdminApi";
import Constants from "../../common/Constants";
import "../../styles/user-manager.scss";

const UserManager = () => {
  const navigate = useNavigate();
  const { userTableData, uiState, refreshUsers } = Users();
  const [selectedRow, setSelectedRow] = useState(null);
  const [usersSearchPayload, setUsersSearchPayload] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const clearSearchInput = () => {
    setUsersSearchPayload("");
    document.getElementById("searchUsers").value = "";
  };

  const debouncedHandleAudienceSearchTermChange = debounce(
    (search) => setUsersSearchPayload(search.toLowerCase()),
    500
  );

  const routeChange = (page) => {
    if (page === "edit") {
      const enabled = selectedRow.status === "active";
      navigate(`/user-manager/edit`, { state: { ...selectedRow, enabled } });
    } else {
      navigate(`/user-manager/create`);
    }
  };

  const onDeleteUser = async (userId) => {
    const resp = (await api.deleteUser(userId)) || {};
    if (resp.result === "success") {
      refreshUsers();
      toast.success(resp.message, Constants.TOAST_OPTIONS);
    } else {
      toast.error(resp.message, Constants.TOAST_OPTIONS);
    }
    setIsOpen(false);
  };

  const filteredData = useMemo(
    () =>
      userTableData.filter((user) =>
        `${user.name} ${user.email}`.toLowerCase().includes(usersSearchPayload)
      ),
    [userTableData, usersSearchPayload]
  );

  return (
    <div className="user_manager_page">
      <div>
        <h4>User Manager</h4>
      </div>
      <div className="card">
        <div className="card_header">
          <div className="search_container">
            <input
              id="searchUsers"
              type="text"
              className="form-control"
              placeholder="Search Users"
              onChange={(e) =>
                debouncedHandleAudienceSearchTermChange(e.target.value)
              }
            />
            {usersSearchPayload.length > 0 ? (
              <i
                className="fas fa-times clear-icon"
                onClick={clearSearchInput}
              />
            ) : (
              <i className="fas fa-search" />
            )}
          </div>
          <div className="btn_container">
            <button
              className="btn btn-primary"
              type="button"
              onClick={() => routeChange("create")}
            >
              Create
            </button>
            <button
              className="btn btn-primary"
              type="button"
              disabled={!selectedRow}
              onClick={() => routeChange("edit")}
            >
              Edit
            </button>
            <button
              className="btn btn-primary"
              type="button"
              disabled={!selectedRow}
              onClick={() => setIsOpen(true)}
            >
              Delete
            </button>
          </div>
        </div>
        <hr />
        <div className="card-body">
          <UserTable
            data={filteredData}
            setSelectedRow={setSelectedRow}
            uiState={uiState}
          />
        </div>
      </div>
      {selectedRow && (
        <ConfirmDeleteUserModal
          user={selectedRow}
          isModalOpen={isOpen}
          onModalClose={() => setIsOpen(false)}
          onModalSubmit={onDeleteUser}
        />
      )}
    </div>
  );
};

export default UserManager;
