import CTSConstants from "../common/CTSConstants";
import baseApi from "./BaseApi";

/**
 * Cost to Serve API wrapper.
 *
 * Service file that contains all the common API Methods that are used by the
 * Cost to Serve Section of the Tool
 */

/**
 * Abstract the core HTTP call with specific verb functions.
 */
const postRequest = (path, data) =>
  baseApi.baseRequest("POST", CTSConstants.CTS_API_ROOT_URL + path, data);
const getRequest = (path) =>
  baseApi.baseRequest("GET", CTSConstants.CTS_API_ROOT_URL + path);

async function getScenarios(request) {
  return await postRequest("/getScenarios", request || {});
}

async function createScenario(payload) {
  return await postRequest("/createScenario", payload);
}

async function getBatchSources(batchId) {
  return await postRequest("/getBatchSources", { batchId });
}

async function getBatchDetails(batchId) {
  return await postRequest("/getBatchDetails", { batchId });
}

async function getDataProcessingIssues(batchId) {
  return await postRequest("/getDataProcessingIssues", { batchId });
}

async function lockScenario(scenarioId, batchId) {
  return await postRequest("/lockScenario", {
    scenario: { scenarioId, batchId }
  });
}

async function renameScenario(payload) {
  return await postRequest("/renameScenario", payload);
}

async function getEmployeeDetails(payload) {
  return await postRequest("/getEmployeeDetails", payload);
}

async function getEmployeeList(payload) {
  return await postRequest("/getEmployeeList", payload);
}

async function updateDataProcessingIssue(payload) {
  return await postRequest("/updateDataProcessingIssue", payload);
}

async function downloadDataFile(payload) {
  return await postRequest("/downloadDataFile", payload);
}

async function uploadUpdatedExcel(batchId, base64FileData, fileName) {
  const maxPartSize = 5e6; // 5mb
  const totalParts = Math.ceil(base64FileData.length / maxPartSize);
  const parts = Array.from(Array(totalParts)).map((_, i) =>
    base64FileData.substring(i * maxPartSize, (i + 1) * maxPartSize)
  );

  console.log(
    `Uploading file of size: ${
      base64FileData.length / 1e6
    }mb in ${totalParts} parts.`
  );

  let response = null;
  for (let partNumber = 1; partNumber <= totalParts; partNumber++) {
    const part = parts[partNumber - 1];
    response = await postRequest("/uploadUpdatedExcel", {
      fileName,
      totalParts,
      partNumber,
      base64FileData: part,
      batchId
    });
  }
  return response;
}

async function uploadDataSourceFile(
  batchId,
  fileName,
  base64FileData,
  sourceType
) {
  const maxPartSize = 5e6; // 5mb
  const totalParts = Math.ceil(base64FileData.length / maxPartSize);
  const parts = Array.from(Array(totalParts)).map((_, i) =>
    base64FileData.substring(i * maxPartSize, (i + 1) * maxPartSize)
  );

  console.log(
    `Uploading file of size: ${
      base64FileData.length / 1e6
    }mb in ${totalParts} parts.`
  );

  let response = null;
  for (let partNumber = 1; partNumber <= totalParts; partNumber++) {
    const part = parts[partNumber - 1];
    response = await postRequest("/uploadDataSourceFile", {
      fileName,
      totalParts,
      partNumber,
      base64FileData: part,
      batchId,
      sourceType
    });
  }
  return response;
}

async function updateEmployeeDetails(payload) {
  return await postRequest("/updateEmployeeDetails", payload);
}

async function runDataProcess(payload) {
  return await postRequest("/runDataProcess", payload);
}

async function takeRapSnapshot(payload) {
  return await postRequest("/takeRapSnapshot", payload);
}

async function takeBFSSnapshot(payload) {
  return await postRequest("/takeBfsSnapshot", payload);
}

async function updateBFSScenarioForBatch(payload) {
  return await postRequest("/updateBFSScenarioForBatch", payload);
}

async function getBFSScenariosList() {
  return await postRequest("/getBFSScenariosList");
}
export default {
  getScenarios,
  createScenario,
  getBatchSources,
  getBatchDetails,
  getDataProcessingIssues,
  lockScenario,
  renameScenario,
  getEmployeeList,
  getEmployeeDetails,
  updateDataProcessingIssue,
  downloadDataFile,
  uploadDataSourceFile,
  updateEmployeeDetails,
  uploadUpdatedExcel,
  runDataProcess,
  takeRapSnapshot,
  takeBFSSnapshot,
  getBFSScenariosList,
  updateBFSScenarioForBatch
};
