import React, { useState } from "react";
import { Formik, Form } from "formik";
import Constants from "../../common/Constants";
import UploadFileSource from "../shared/UploadFileSource";

const ParUploads = () => {
  const [bulkUpdateData, setBulkUpdateData] = useState({
    rowCount: 0,
    status: "loading"
  });

  const updateBulkUploadData = async (count) => {
    setBulkUpdateData({ rowCount: count, status: "loaded" });
  };

  return (
    <Formik>
      {() => (
        <Form
          className="form-horizontal mainform-card col-sm-12"
          name="parupload"
        >
          <div className="card mt-4">
            <div className="row">
              <div className="col-sm-4 mt-2 mb-1 ml-4">
                <h5>Upload PAR Pay Range Data</h5>
              </div>
            </div>
            <div className="row ml-1 mb-2">
              <div className="col-md-4">
                <div className="mr-2 ctsClass">
                  <UploadFileSource
                    batchId={-1}
                    isBatchSourceLoading={false}
                    isScenarioLocked={false}
                    source={bulkUpdateData}
                    sourceName={Constants.SOURCE_PAR_PAY_RANGE}
                    onSuccessfulUpload={updateBulkUploadData}
                  />
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

ParUploads.propTypes = {};

ParUploads.defaultProps = {};

export default ParUploads;
