import React from "react";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import Constants from "./common/Constants";
import Cookies from "./helpers/Cookies";
import OktaSignInWidget from "./OktaSignInWidget";

const OktaLoginPage = ({ config }) => {
  const { oktaAuth, authState } = useOktaAuth();

  const onSuccess = (tokens) => {
    oktaAuth.tokenManager.setTokens(tokens);
    if (tokens && tokens.accessToken && tokens.idToken) {
      const token = tokens.accessToken.accessToken;
      const { length } = token;
      const loginKey = token.slice(0, length / 2);
      const loginKeyAppend = token.slice(length / 2, length);

      Cookies.setCookie(Constants.FINOPS_LOGIN_KEY, loginKey, 1);
      Cookies.setCookie(Constants.FINOPS_LOGIN_KEY_APPEND, loginKeyAppend, 1);
      Cookies.setCookie(
        Constants.FINOPS_LOGIN_USER,
        tokens.accessToken.claims.sub,
        1
      );
      oktaAuth.handleLoginRedirect(tokens);
    }
  };

  const onError = (err) => {
    console.log("error logging in", err);
  };

  if (!authState) {
    return null;
  }

  return authState.isAuthenticated ? (
    <Navigate to={{ pathname: "/" }} />
  ) : (
    <div id="main-display">
      <OktaSignInWidget
        config={config}
        onSuccess={onSuccess}
        onError={onError}
      />
    </div>
  );
};
OktaLoginPage.propTypes = {
  config: PropTypes.shape({
    issuer: PropTypes.string,
    clientId: PropTypes.string,
    redirectUri: PropTypes.string,
    scopes: PropTypes.arrayOf(PropTypes.string)
  }).isRequired
};
export default OktaLoginPage;
