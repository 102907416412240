/* eslint-disable no-return-assign */
import React from "react";
import PropTypes from "prop-types";
import ParConstants from "../../../common/ParConstants";
import RapConstants from "../../../common/RapConstants";

import CollapsibleCard from "../../shared/CollapsibleCard";
import TextBoxField from "../../shared/TextBoxField";

const ParStatus = ({
  parType,
  fillType,
  formikProps,
  showFieldsReadOnly,
  hideFields
}) => (
  <CollapsibleCard title="PAR Status">
    <div className="row">
      <div className="col-md-4 mt-3">
        <label htmlFor="par_type">PAR Type</label>
        <div id="par_type" className="form-description">
          <strong>
            {`${
              parType != null
                ? parType
                : ParConstants.PAR_WORKFLOW.OPEN_REQUISITION
            }${fillType !== null ? `, ${fillType.value}` : ""}`}
          </strong>
        </div>
      </div>
      {formikProps.values?.current_queue_name && (
        <div className="col-md-4 mt-3">
          <label htmlFor="par_ticket_status">Ticket Status</label>
          <div id="par_ticket_status" className="form-description">
            <strong>
              {formikProps.values?.current_queue_name.toUpperCase()}
            </strong>
          </div>
        </div>
      )}
    </div>
    {/* Show requisition_id for non-global employee types */}
    {formikProps.values?.employee_type &&
      formikProps.values.employee_type?.value !==
        RapConstants.EMPLOYEE_TYPE_VALUES[1] &&
      !hideFields.requisition_id && (
        <div className="row">
          <div className="col-md-3 mt-3">
            <TextBoxField
              formikProps={formikProps}
              fieldName="requisition_id"
              label="Requisition ID"
              className="ml-1"
              showDescription={showFieldsReadOnly.requisition_id}
            />
          </div>
        </div>
      )}
  </CollapsibleCard>
);

ParStatus.propTypes = {
  parType: PropTypes.string,
  fillType: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  }),
  formikProps: PropTypes.shape({
    values: PropTypes.shape({
      current_queue_name: PropTypes.string,
      employee_type: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      })
    })
  }).isRequired,
  showFieldsReadOnly: PropTypes.shape({
    requisition_id: PropTypes.bool
  }),
  hideFields: PropTypes.shape({
    requisition_id: PropTypes.bool
  })
};

ParStatus.defaultProps = {
  parType: null,
  fillType: {},
  hideFields: {},
  showFieldsReadOnly: {}
};

export default ParStatus;
