import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";
import { getIn } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CustomDate = React.forwardRef(
  (
    {
      onClick,
      formikprops,
      isArrayField,
      isOptional,
      error,
      fieldname,
      ...props
    },
    ref
  ) => {
    const hasError = isArrayField ? error : formikprops.errors[fieldname];
    return (
      <div
        className="input-group"
        onClick={onClick}
        style={
          hasError
            ? { border: "1px solid #DC2F23", borderRadius: "5px" }
            : { borderRadius: "5px" }
        }
      >
        <input
          type="text"
          {...props}
          className="form-control input-with-icon"
          ref={ref}
          data-testid={fieldname}
        />
        <div className="input-group-append">
          <span className="input-group-text input-icon">
            <i aria-hidden="true" className="fa fa-calendar-alt" />
          </span>
        </div>
      </div>
    );
  }
);
CustomDate.displayName = "CustomDate";
CustomDate.propTypes = {
  isArrayField: PropTypes.bool,
  isOptional: PropTypes.bool,
  error: PropTypes.string,
  onClick: PropTypes.func,
  fieldname: PropTypes.string.isRequired,
  formikprops: PropTypes.shape({
    errors: PropTypes.shape({})
  })
};

CustomDate.defaultProps = {
  isOptional: false,
  isArrayField: false,
  error: "",
  onClick: () => {},
  formikprops: { errors: {} }
};

const DatePickerBoxField = ({
  formikProps,
  label,
  isArrayField,
  showDescription,
  arrayFieldName,
  arrayIndex,
  fieldName,
  isOptional,
  disable
}) => {
  const error = isArrayField
    ? formikProps?.errors[arrayFieldName]?.[arrayIndex]?.[fieldName]
    : formikProps?.errors[fieldName];
  const [selectedDate, setSelectedDate] = useState(
    !isArrayField
      ? formikProps.values[fieldName]
      : getIn(
          formikProps.values,
          `${arrayFieldName}[${arrayIndex}][${fieldName}]` || ""
        )
  );
  useEffect(() => {
    if (!isArrayField) {
      setSelectedDate(formikProps.values[fieldName]);
    } else {
      setSelectedDate(
        getIn(
          formikProps.values,
          `${arrayFieldName}[${arrayIndex}][${fieldName}]` || ""
        )
      );
    }
  }, [formikProps]);

  const formatWeekDay = (nameOfDay) => nameOfDay.substring(0, 3);

  return (
    <>
      <label htmlFor={fieldName}>
        {label}
        {!isOptional && <span className="red-text">&nbsp;*</span>}
      </label>
      {!showDescription && (
        <DatePicker
          selected={selectedDate}
          onChange={(date) => {
            setSelectedDate(date);
            if (!isArrayField) {
              formikProps.setFieldValue(fieldName, date);
            } else {
              formikProps.setFieldValue(
                `${arrayFieldName}[${arrayIndex}][${fieldName}]`,
                date
              );
            }
          }}
          showYearDropdown
          dateFormatCalendar="MMMM"
          customInput={
            <CustomDate
              error={error}
              isOptional={isOptional}
              isArrayField={isArrayField}
              fieldname={fieldName}
              formikprops={formikProps}
            />
          }
          popperPlacement="bottom-start"
          showMonthDropdown
          dropdownMode="select"
          autoComplete="off"
          placeholderText="Select Date"
          formatWeekDay={formatWeekDay}
          calendarWeeks
          name={fieldName}
          id={fieldName}
          renderDayContents={(day, date) => date.getDate()}
          dateFormat="MM/dd/yyyy"
          disabled={disable}
        />
      )}
      {showDescription && (
        <div id={`${fieldName}-description`} className="form-description">
          <strong>
            {formikProps.values[fieldName]
              ? format(new Date(formikProps.values[fieldName]), "MM/dd/yyyy")
              : ""}
          </strong>
        </div>
      )}
      {error && (
        <div className="mt-1 error-message">
          {error.replace(fieldName, label)}
        </div>
      )}
    </>
  );
};

DatePickerBoxField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isArrayField: PropTypes.bool,
  arrayIndex: PropTypes.number,
  arrayFieldName: PropTypes.string,
  isOptional: PropTypes.bool,
  showDescription: PropTypes.bool,
  formikProps: PropTypes.shape({
    values: PropTypes.shape({}),
    errors: PropTypes.shape({}),
    setFieldValue: PropTypes.func
  }).isRequired,
  disable: PropTypes.bool
};

DatePickerBoxField.defaultProps = {
  isArrayField: false,
  arrayFieldName: "",
  arrayIndex: 0,
  isOptional: false,
  showDescription: false,
  disable: false
};
export default DatePickerBoxField;
