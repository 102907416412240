import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import PropTypes from "prop-types";
import ParApi from "../../../services/ParApi";
import NotificationCard from "./NotificationCard";
import LoadingSpinner from "../../shared/LoadingSpinner";

const Notification = ({ onClose }) => {
  const [notifications, setNotifications] = useState(null);
  const [notificationCount, setNotificationCount] = useState(0);
  const getTodayDate = () => format(new Date(), "MM/dd/yyyy");
  const getYesterdayDate = () => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return format(yesterday, "MM/dd/yyyy");
  };

  const groupByDateWithSpecialCases = (data) => {
    const todayDate = getTodayDate();
    const yesterdayDate = getYesterdayDate();

    return data.reduce((acc, item) => {
      const itemDate = format(new Date(item.created_date), "MM/dd/yyyy");

      let key;
      if (itemDate === todayDate) {
        key = "Today";
      } else if (itemDate === yesterdayDate) {
        key = "Yesterday";
      } else {
        key = itemDate;
      }

      const existingGroup = acc.find((group) => group.date === key);

      if (existingGroup) {
        existingGroup.items.push(item);
      } else {
        acc.push({ date: key, items: [item] });
      }
      return acc;
    }, []);
  };

  const getNotifications = async () => {
    const notification = await ParApi.getPARAlertsForUser();
    if (notification && notification.data && notification.data.length > 0) {
      const sortedData = notification.data.sort(
        (a, b) => new Date(b.created_date) - new Date(a.created_date)
      );
      const groupedData = groupByDateWithSpecialCases(sortedData);
      setNotificationCount(notification.data.length);
      setNotifications(groupedData);
    } else {
      setNotificationCount(0);
      setNotifications([]);
    }
  };

  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <div>
      <h5>Notifications({notificationCount})</h5>

      {!notifications && (
        <LoadingSpinner
          status="Loading"
          textMessage="Please wait, notifications are being loaded..."
        />
      )}
      {notifications && notifications.length === 0 && (
        <LoadingSpinner
          status="NoData"
          textMessage="No Notifications are available"
        />
      )}
      {notifications &&
        notifications.length > 0 &&
        notifications.map((notification) => (
          <div key={notification.date} className="notification-group-container">
            <div className="notification-date mb-1"> {notification.date}</div>
            {notification.items &&
              notification.items.map((item) => (
                <NotificationCard
                  item={item}
                  key={item.notification_id}
                  onClose={onClose}
                />
              ))}
          </div>
        ))}
    </div>
  );
};

Notification.propTypes = {
  onClose: PropTypes.func
};

Notification.defaultProps = {
  onClose: () => {}
};

export default Notification;
