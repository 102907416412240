import PropTypes from "prop-types";
import React from "react";

class Spinner extends React.PureComponent {
  render() {
    let spinner = null;
    const { className, waiting } = this.props;
    if (waiting) {
      spinner = <i className="fa fa-spinner fa-spin" />;
    }
    return <span className={className}>{spinner}</span>;
  }
}

Spinner.propTypes = {
  waiting: PropTypes.bool,
  className: PropTypes.string
};

Spinner.defaultProps = {
  waiting: true,
  className: ""
};

export default Spinner;
