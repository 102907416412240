import PropTypes from "prop-types";
import React from "react";
import Modal from "react-modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Spinner from "./shared/Spinner";

import AutoComplete from "./shared/AutoComplete";
import ButtonGroup from "./shared/ButtonGroup";
import RapConstants from "../common/RapConstants";

const filterFieldNames = [
  "clientBrand",
  "textSearch",
  "familyName",
  "givenName",
  "level",
  "mslPin",
  "workdayTitle",
  "pinStatus",
  "requisitionId",
  "status",
  "workdayId",
  "summaryDepartment",
  "serviceLineDepartment",
  "includeExpiredPINs",
  "approvedPINs",
  "tbhCategoryList",
  "employeeType",
  // from allocation list
  "client"
];

const listFields = [
  "client",
  "clientBrand",
  "level",
  "summaryDepartment",
  "serviceLineDepartment"
];

class SearchModal extends React.Component {
  constructor(props) {
    super(props);
    this.focused = {};
  }

  componentDidMount() {
    Modal.setAppElement("body");
  }

  focusThis = (el) => {
    setTimeout(() => {
      if (el && !this.focused[el.name]) {
        el.focus();
        this.focused[el.name] = el;
      }
    }, 100);
  };

  renderTextBox = (fieldName, label) => (
    <div className="mt-2">
      <label htmlFor={fieldName}>{label}</label>
      <Field
        className="form-control"
        type="text"
        name={fieldName}
        id={fieldName}
        innerRef={(el) => (fieldName === "mslPin" ? this.focusThis(el) : null)}
      />
      <div className="text-danger">
        <ErrorMessage name={fieldName} />
      </div>
    </div>
  );

  // Get the values from the select list object before sending it
  sendFilterValues = (formInput) => {
    let finalValues = formInput;
    finalValues.client = formInput.client ? formInput.client.value : "";
    finalValues.clientBrand = formInput.clientBrand
      ? formInput.clientBrand.value
      : "";
    finalValues.summaryDepartment = formInput.summaryDepartment
      ? formInput.summaryDepartment.value
      : "";
    finalValues.serviceLineDepartment = formInput.serviceLineDepartment
      ? formInput.serviceLineDepartment.value
      : "";
    finalValues.tbhCategory = formInput.tbhCategory
      ? formInput.tbhCategory.value
      : "";
    finalValues.level = formInput.level ? formInput.level.value : "";
    finalValues.status = formInput.status
      ? formInput.status.map((st) => st.value).join(",")
      : "";
    finalValues.employeeType = formInput.employeeType
      ? formInput.employeeType.map((st) => st.value).join(",")
      : "";
    this.props.onSearchModalSubmit(finalValues);
  };

  render() {
    const { isModalOpen, validations, onSearchModalClose } = this.props;

    if (!isModalOpen) {
      return (
        <div>
          Loading...
          <Spinner waiting />
        </div>
      );
    }

    const formInitialValues = {};
    // Set the initial values for the form
    filterFieldNames.forEach((field) => {
      if (listFields.includes(field)) {
        formInitialValues[field] = null;
      } else {
        formInitialValues[field] = "";
      }
    });
    let finalClientBrandList = [];
    // Get both client and clientbrand from the clientList array
    if (validations.clientList) {
      // Get the list in label, value objects form
      finalClientBrandList = validations.clientList.map((s) => ({
        label: s,
        value: s
      }));
    }

    const finalSummaryDeptList = validations.summaryDepartmentList
      ? validations.summaryDepartmentList.map((s) => ({ label: s, value: s }))
      : [];
    // this is to only pick unique service line deparments For e.g. executive service line has multiple records for multiple summary deparment
    const UniqueserviceLineDeptList = validations.serviceLineDepartmentList
      ? Array.from(
          new Set(validations.serviceLineDepartmentList.map((s) => s.value))
        )
      : [];
    const finalServiceLineDeptList = UniqueserviceLineDeptList.map((s) => ({
      label: s,
      value: s
    }));
    const finalLevelList = validations.levelList
      ? validations.levelList.map((s) => ({ label: s, value: s }))
      : [];
    const finalStatusList = RapConstants.EMPLOYEE_STATUS_VALUES.map((s) => ({
      label: s,
      value: s
    }));

    const employeeType = RapConstants.EMPLOYEE_TYPE_VALUES.map((s) => ({
      label: s,
      value: s
    }));
    const tbhCategoryList = validations.tbhCategoryList
      ? validations.tbhCategoryList.map((s) => ({ label: s, value: s }))
      : [];

    return (
      <div className="container">
        <Modal
          id="searchModal"
          role="dialog"
          isOpen={isModalOpen}
          onRequestClose={onSearchModalClose}
          contentLabel="Apply Filters"
          closeTimeoutMS={0}
          shouldCloseOnEsc
          shouldCloseOnOverlayClick={false}
          parentSelector={() => document.body}
          className="modalcontent"
          overlayClassName="modaloverlay"
          ariaHideApp={false}
        >
          <Formik
            enableReinitialize
            initialValues={formInitialValues}
            onSubmit={this.sendFilterValues}
          >
            {(formikProps) => (
              <Form className="form-horizontal col-sm-12" name="filterList">
                <div className="row">
                  <div className="col-sm-6">
                    {this.renderTextBox("mslPin", "MSL Pin")}
                  </div>
                  <div className="col-sm-6">
                    {this.renderTextBox("workdayId", "Workday ID")}
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    {this.renderTextBox("givenName", "Given Name")}
                  </div>
                  <div className="col-sm-6">
                    {this.renderTextBox("familyName", "Family Name")}
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 mt-2">
                    <label htmlFor="level">Level</label>
                    <AutoComplete
                      name="level"
                      options={finalLevelList}
                      handleCreate={(value) => {
                        formikProps.setFieldValue("level", {
                          label: value.trim(),
                          value: value.trim()
                        });
                      }}
                      clearUserAddedValidations={() => {}}
                      showCreateNewOption={false}
                    />
                  </div>
                  <div className="col-sm-6">
                    {this.renderTextBox("workdayTitle", "Workday Title")}
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-sm-6">
                    <label htmlFor="client">Client - Brand</label>
                    <AutoComplete
                      name="client"
                      options={finalClientBrandList}
                      handleCreate={(value) => {
                        formikProps.setFieldValue("client", {
                          label: value.trim(),
                          value: value.trim()
                        });
                      }}
                      clearUserAddedValidations={() => {}}
                      showCreateNewOption={false}
                    />
                  </div>
                  <div className="col-sm-6">
                    <label htmlFor="tbhCategory">TBH Category</label>
                    <AutoComplete
                      name="tbhCategory"
                      options={tbhCategoryList}
                      handleCreate={(value) => {
                        formikProps.setFieldValue("tbhCategory", {
                          label: value.trim(),
                          value: value.trim()
                        });
                      }}
                      clearUserAddedValidations={() => {}}
                      showCreateNewOption={false}
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-sm-6">
                    <label htmlFor="summaryDepartment">
                      Summary Department
                    </label>
                    <AutoComplete
                      name="summaryDepartment"
                      options={finalSummaryDeptList}
                      handleCreate={(value) => {
                        formikProps.setFieldValue("summaryDepartment", {
                          label: value.trim(),
                          value: value.trim()
                        });
                      }}
                      clearUserAddedValidations={() => {}}
                      showCreateNewOption={false}
                    />
                  </div>
                  <div className="col-sm-6">
                    <label htmlFor="serviceLineDepartment">
                      Service Line Department
                    </label>
                    <AutoComplete
                      name="serviceLineDepartment"
                      options={finalServiceLineDeptList}
                      handleCreate={(value) => {
                        formikProps.setFieldValue("serviceLineDepartment", {
                          label: value.trim(),
                          value: value.trim()
                        });
                      }}
                      clearUserAddedValidations={() => {}}
                      showCreateNewOption={false}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    {this.renderTextBox("requisitionId", "Requisition ID")}
                  </div>
                  <div className="col-sm-6 mt-2">
                    <ButtonGroup
                      fieldName="pinStatus"
                      fieldValue={formikProps.values.pinStatus}
                      label="PIN Status"
                      buttonValues={RapConstants.PIN_STATUS_VALUES}
                      setButtonValue={(value) => {
                        formikProps.setFieldValue("pinStatus", value);
                      }}
                      multiple
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 mt-2">
                    <ButtonGroup
                      fieldName="includeExpiredPINs"
                      fieldValue={formikProps.values.includeExpiredPINs}
                      label="Include Expired PINs"
                      buttonValues={RapConstants.BOOLEAN_VALUES}
                      setButtonValue={(value) => {
                        formikProps.setFieldValue("includeExpiredPINs", value);
                      }}
                    />
                  </div>
                  <div className="col-sm-6 mt-2">
                    <ButtonGroup
                      fieldName="approvedPINs"
                      fieldValue={formikProps.values.approvedPINs}
                      label="Approved PINs"
                      buttonValues={RapConstants.BOOLEAN_VALUES}
                      setButtonValue={(value) => {
                        formikProps.setFieldValue("approvedPINs", value);
                      }}
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-sm-6">
                    <label htmlFor="status">Employee Status</label>
                    <AutoComplete
                      name="status"
                      options={finalStatusList}
                      isMultiSelect
                      placeholder="Select one more more..."
                      handleCreate={(value) => {
                        formikProps.setFieldValue("status", {
                          label: value.trim(),
                          value: value.trim()
                        });
                      }}
                      clearUserAddedValidations={() => {}}
                      showCreateNewOption={false}
                    />
                  </div>
                  <div className="col-sm-6">
                    <label htmlFor="employeeType">Employee Type</label>
                    <AutoComplete
                      name="employeeType"
                      options={employeeType}
                      isMultiSelect
                      placeholder="Select one more more..."
                      handleCreate={(value) => {
                        formikProps.setFieldValue("employeeType", {
                          label: value.trim(),
                          value: value.trim()
                        });
                      }}
                      clearUserAddedValidations={() => {}}
                      showCreateNewOption={false}
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-sm-12">
                    <label htmlFor="textSearch">
                      Text Search
                      <span style={{ color: "#aaa", marginLeft: "1em" }}>
                        (comma delimited ids, etc)
                      </span>
                    </label>
                    <Field
                      as="textarea"
                      className="form-control textarea-fixed"
                      name="textSearch"
                      id="textSearch"
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-sm-12">
                    <label htmlFor="textSearch">Reason For Change</label>
                    <Field
                      className="form-control"
                      type="text"
                      name="noteSearch"
                      id="noteSearch"
                    />
                  </div>
                </div>
                <br />
                <div className="form-group mt-2">
                  <button type="submit" className="btn btn-sm btn-success">
                    Submit
                  </button>{" "}
                  <button
                    type="button"
                    onClick={onSearchModalClose}
                    className="btn btn-sm btn-danger"
                  >
                    Cancel
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal>
      </div>
    );
  }
}

SearchModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onSearchModalClose: PropTypes.func.isRequired,
  onSearchModalSubmit: PropTypes.func.isRequired,
  validations: PropTypes.shape({
    approvedPinValues: PropTypes.instanceOf(Array),
    clientBrandList: PropTypes.instanceOf(Array),
    clientList: PropTypes.instanceOf(Array),
    clientLobList: PropTypes.instanceOf(Array),
    dvSgaValues: PropTypes.instanceOf(Array),
    payrollCompanyList: PropTypes.instanceOf(Array),
    payrollDepartmentList: PropTypes.instanceOf(Array),
    payrollProfitCenterList: PropTypes.instanceOf(Array),
    serviceLineDepartmentList: PropTypes.instanceOf(Array),
    summaryDepartmentList: PropTypes.instanceOf(Array),
    tbhCategoryList: PropTypes.instanceOf(Array),
    levelList: PropTypes.instanceOf(Array)
  }).isRequired
};

export default SearchModal;
