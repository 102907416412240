import React, { useState, useEffect } from "react";
import useCollapse from "react-collapsed";
import "../../styles/shared/collapse-panel.scss";
import PropTypes from "prop-types";

const Collapsible = ({
  expandPanel,
  children,
  formikProps,
  arrayIndex,
  fieldName,
  expandAllPanels
}) => {
  const [isExpanded, setExpanded] = useState(expandPanel);
  const [isExpandedAll, setExpandedAll] = useState(false);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

  const calculateAllocation = (allocation) =>
    Number.isNaN(allocation)
      ? ""
      : (parseFloat(allocation) * 100).toFixed(0) + "%" || "";

  useEffect(() => {
    if (expandAllPanels !== isExpandedAll) {
      setExpandedAll(expandAllPanels);
      setExpanded(expandAllPanels);
    }
  });

  return (
    <div className="collapsible">
      <div
        className="header"
        {...getToggleProps({
          onClick: () => {
            setExpanded((prevExpanded) => !prevExpanded);
            setExpandedAll(expandAllPanels);
          }
        })}
      >
        <span className="allocationHeader">
          {formikProps.values[fieldName][arrayIndex].allocation &&
          formikProps.values[fieldName][arrayIndex].allocation.toString()
            .length > 0 ? (
            <>
              <span>
                {`${calculateAllocation(
                  formikProps.values[fieldName][arrayIndex].allocation
                )}`}
              </span>
              {` | `}
              <span>
                {formikProps.values[fieldName][arrayIndex].client?.label || ""}
              </span>
              {` | `}
              <span>
                {formikProps.values[fieldName][arrayIndex].clientLob?.label ||
                  ""}
              </span>
            </>
          ) : (
            <span>New Allocation</span>
          )}
        </span>
        {isExpanded ? (
          <>
            <i className="fa fa-caret-up fa-lg pl-1" aria-hidden="true" />
          </>
        ) : (
          <>
            <i className="fa fa-caret-down fa-lg pl-1" aria-hidden="true" />
          </>
        )}
      </div>
      <div {...getCollapseProps()}>
        <div className="content">{children}</div>
      </div>
    </div>
  );
};

Collapsible.propTypes = {
  children: PropTypes.node.isRequired,
  fieldName: PropTypes.string.isRequired,
  arrayIndex: PropTypes.number,
  formikProps: PropTypes.shape({
    values: PropTypes.shape({}),
    errors: PropTypes.shape({}),
    setFieldValue: PropTypes.func
  }).isRequired,
  expandPanel: PropTypes.bool,
  expandAllPanels: PropTypes.bool
};

Collapsible.defaultProps = {
  arrayIndex: 0,
  expandPanel: false,
  expandAllPanels: false
};

export default Collapsible;
