/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from "prop-types";
import React from "react";
import { toast } from "react-toastify";
import Select from "react-select";

import Spinner from "../shared/Spinner";
import Constants from "../../common/Constants";
import CTSConstants from "../../common/CTSConstants";
import api from "../../services/CtsApi";

const STATUS_ACTIVE = "Active";
const STATUS_IGNORE = "Ignore";
const STATUS_RESOLVED = "Resolved";

class MismatchTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMismatchLoading: true,
      mismatchTable: null,
      // To control the status update on ignore checkbox change
      isStatusUpdateLoading: false,

      // For storing the checked ignore rows column
      ignoreAllChecked: false,
      // To store which rows are expanded currently
      expandedRows: [],
      // Status Filter
      statusFilter: null
    };
    this.fileSelectRef = React.createRef();
  }

  componentDidMount() {
    this.setState(() => ({
      isMismatchLoading: false,
      mismatchTable: this.props.mismatchData
    }));
  }

  componentDidUpdate(prevProps) {
    // If we reload the record, reset the userAddedValidations
    if (this.props.mismatchData !== prevProps.mismatchData) {
      this.setState(() => ({
        isMismatchLoading: false,
        mismatchTable: this.props.mismatchData
      }));
    }
  }

  handleRowClick(rowIndex) {
    const currentExpandedRows = this.state.expandedRows;
    const isRowCurrentlyExpanded = currentExpandedRows.includes(rowIndex);

    const newExpandedRows = isRowCurrentlyExpanded
      ? currentExpandedRows.filter((id) => id !== rowIndex)
      : currentExpandedRows.concat(rowIndex);

    this.setState(() => ({ expandedRows: newExpandedRows }));
  }

  // API Call to update status of an issue
  updateIssueStatus = async (
    issueIds,
    batchId,
    status,
    finalTableData,
    ignoreAllChecked
  ) => {
    try {
      this.setState(() => ({ isStatusUpdateLoading: true }));
      const issue = { issueIds, batchId, status };

      this.setState(() => ({
        ignoreAllChecked,
        mismatchTable: finalTableData
      }));
      if (issueIds && issueIds.length > 0) {
        const response = await api.updateDataProcessingIssue(issue);
        if (!response || response.status !== CTSConstants.SUCCESS) {
          toast.error(
            "There was an error in updating the status on the issue",
            Constants.TOAST_OPTIONS
          );
        }
      }
    } catch (ex) {
      console.error(ex);
      toast.error(
        `There was an error in updating the status on the issue - ${ex.message}`,
        Constants.TOAST_OPTIONS
      );
    } finally {
      // Update State
      this.setState(() => ({ isStatusUpdateLoading: false }));
    }
  };

  onIgnoreAllCheck = (e) => {
    const { mismatchTable } = this.state;
    const updatedStatus = e.target.checked ? STATUS_IGNORE : STATUS_ACTIVE;

    // Check / UnCheck All Items
    // Filter the array to exclude the info status rows
    let nonInfoRows = mismatchTable.filter(
      (m) => m.type.toLowerCase() !== "info"
    );
    // Get all the ids that need to be updated (all ids except info rows)
    const allIds = nonInfoRows.map((d) => d.id);
    nonInfoRows = nonInfoRows.map((mismatch) => ({
      ...mismatch,
      status: updatedStatus
    }));

    const infoRows = mismatchTable.filter(
      (m) => m.type.toLowerCase() === "info"
    );
    // Get final table
    const updatedTable = nonInfoRows.concat(infoRows);
    this.updateIssueStatus(
      allIds,
      updatedTable[0].batchId,
      updatedStatus,
      updatedTable,
      e.target.checked
    );
  };

  // Update List Item's state and Master Checkbox State
  onRowCheck = (e, row) => {
    const { mismatchTable } = this.state;
    let tempData = mismatchTable;
    const updatedStatus = e.target.checked ? STATUS_IGNORE : STATUS_ACTIVE;

    tempData.map((mismatch) => {
      if (mismatch.id === row.id) {
        mismatch.status = updatedStatus;
      }
      return mismatch;
    });
    // To Control Ignore All Checkbox State
    const totalCheckedItems = tempData.filter(
      (c) => c.status.toLowerCase() === STATUS_IGNORE.toLowerCase()
    ).length;
    const totalItems = mismatchTable.length;

    this.updateIssueStatus(
      [row.id],
      row.batchId,
      updatedStatus,
      tempData,
      totalCheckedItems === totalItems
    );
  };

  renderMismatchData = () => {
    const { mismatchTable, statusFilter, isStatusUpdateLoading } = this.state;
    const { isScenarioLocked } = this.props;

    const finalRows = [];
    let reOrderedTable = [];
    let filteredTable = mismatchTable;
    // Apply the filter on the table
    if (statusFilter && statusFilter.value !== "") {
      filteredTable = mismatchTable.filter(
        (t) => t.status.toLowerCase() === statusFilter.value.toLowerCase()
      );
    }
    // Re-arrange the table so that we show the rows in the order
    // 1. Active 2. Resolved 3. Ignore
    reOrderedTable = [
      ...reOrderedTable,
      filteredTable.filter(
        (f) => f.status.toLowerCase() === STATUS_ACTIVE.toLowerCase()
      )
    ];
    reOrderedTable = [
      ...reOrderedTable,
      filteredTable.filter(
        (f) => f.status.toLowerCase() === STATUS_RESOLVED.toLowerCase()
      )
    ];
    reOrderedTable = [
      ...reOrderedTable,
      filteredTable.filter(
        (f) => f.status.toLowerCase() === STATUS_IGNORE.toLowerCase()
      )
    ];

    if (!reOrderedTable) {
      return finalRows;
    }
    reOrderedTable.flat().forEach((row, index) => {
      const clickCallback = () => this.handleRowClick(index);
      const finalKey = `mismatch-${index}`;
      const isInfo = (row.type || "").toLowerCase() === "info";
      const rowColorClass = row.type.toLowerCase();
      const hideViewIcon =
        !row.dataSourceId && (!row.details || row.details === "null");

      // Date of Change / Changed By / Changes / Reason of Change
      // Show the collapsed row
      finalRows.push(
        <tr key={finalKey} className={`mismatch-background-${rowColorClass}`}>
          <td className="td-history">{row.title}</td>
          <td className="td-history">
            <b>{row.type}</b>
          </td>
          <td className="td-history">{row.ruleName}</td>

          {!hideViewIcon && (
            <td className="td-history" onClick={clickCallback}>
              <i className="fa fa-eye view-icon" />
            </td>
          )}

          {hideViewIcon && <td className="td-history" />}

          <td
            className={`td-history mismatch-status-${row.status.toLowerCase()}`}
          >
            <b>{`${
              isInfo
                ? ""
                : row.status[0].toUpperCase() +
                  row.status.slice(1).toLowerCase()
            }`}</b>
          </td>
          <td className="td-history">
            {row.type.toLowerCase() !== "info" && (
              <>
                <input
                  type="checkbox"
                  disabled={isScenarioLocked}
                  checked={
                    row.status.toLowerCase() === STATUS_IGNORE.toLowerCase()
                  }
                  id={`ignoreRow_${index}`}
                  onChange={(e) => this.onRowCheck(e, row)}
                />
                {isStatusUpdateLoading && (
                  <>
                    &nbsp;
                    <Spinner waiting />
                  </>
                )}
              </>
            )}
          </td>
        </tr>
      );
      if (this.state.expandedRows.includes(index)) {
        let rowDetails = [];
        rowDetails.push(
          <div className="row ml-2 mt-2 mb-1" key={`detail-${row.id}`}>
            <b>Full Details</b>
          </div>
        );

        // Add file details row
        if (row.fileName != null) {
          rowDetails.push(
            <div className="row ml-0 mt-2 mb-2" key={`detail0-${row.id}`}>
              <div className="col-md-3">
                Row Count
                <br />
                <b>{row.rowCount}</b>
              </div>
              <div className="col-md-3">
                Size
                <br />
                <b>{row.fileSize}</b>
              </div>
              <div className="col-md-3">
                Date/Time
                <br />
                <b>{row.fileUpdated}</b>
              </div>
              <div className="col-md-3">
                Link
                <br />
                <a
                  className="blue-text"
                  onClick={() =>
                    this.downloadFile(
                      row.batchId,
                      row.dataSourceId,
                      "Byproducts"
                    )
                  }
                >
                  <i className="fas fa-download" />
                  &nbsp; Download
                </a>
              </div>
            </div>
          );
        }
        // Add Explanation row
        if (row.details) {
          const details = JSON.parse(row.details);

          if (
            details &&
            Object.prototype.hasOwnProperty.call(details, "description")
          ) {
            rowDetails.push(
              <div key={`detail1-${row.id}`}>
                <div className="row ml-2 mt-2 mb-1">
                  <pre>{details.description}</pre>
                </div>
              </div>
            );
          }
        }

        finalRows.push(
          <tr key={`detail2-${row.id}`}>
            <td colSpan="7">
              <div className="card">{rowDetails}</div>
            </td>
          </tr>
        );
      }
    });
    return finalRows;
  };

  handleStatusFilterChange = (value) => {
    this.setState(() => ({ statusFilter: value }));
  };

  downloadFile = async (batchId, sourceId, fileType) => {
    let filePath = "";
    try {
      // Download final ReportResult file
      const response = await api.downloadDataFile({
        batchId,
        sourceId,
        fileType
      });
      // Get full url
      if (response && response.url) {
        filePath = response.url;
        await window.open(`${filePath}`, "_blank");
      } else {
        // Show error
        toast.error(
          "There was a problem in downloading the file.",
          Constants.TOAST_OPTIONS
        );
      }
    } catch (ex) {
      console.log(ex);
      toast.error(
        `There was an unexpected error in downloading the file - ${ex.message}`,
        Constants.TOAST_OPTIONS
      );
    }
  };

  // Method to open the file selector
  openFileSelector = () => {
    this.fileSelectRef.current.click();
  };

  // Method to upload the file
  uploadFile = async (e, batchId) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.onUploadFile(e.target.files[0], batchId);
  };

  render() {
    const {
      isMismatchLoading,
      mismatchTable,
      isStatusUpdateLoading,
      ignoreAllChecked
    } = this.state;
    const { mismatchData, isScenarioLocked, batchId } = this.props;

    if (isMismatchLoading) {
      return (
        <div>
          Loading Mismatch Data...Please wait....
          <Spinner waiting />
        </div>
      );
    }

    let statusList = [];
    CTSConstants.CTS_ISSUES_STATUS.forEach((status) => {
      statusList.push({ label: status, value: status });
    });

    return (
      <div className="card">
        <div id="record-history" className="mr-2 ml-2">
          <div className="row mt-2 mb-2">
            <div className="col-md-7">
              <div className="row ml-1">
                <div className="mismatch-header">Mismatches</div>
                <span className="warning-text mt-2 ml-3">
                  <i className="fa fa-exclamation-triangle" />
                  &nbsp;{mismatchTable.length} Issues
                </span>
              </div>
            </div>
            <div className="col-md-5">
              <div className="row blue-text float-right mr-2">
                <input
                  type="file"
                  style={{ display: "none" }}
                  accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  ref={this.fileSelectRef}
                  onChange={(e) => this.uploadFile(e, batchId)}
                />
                {!isScenarioLocked && (
                  <a
                    className="float-right mt-2 mr-4"
                    onClick={() => this.openFileSelector()}
                  >
                    <i className="fas fa-upload" />
                    &nbsp; Upload Data
                  </a>
                )}
                <a
                  className="float-right mt-2 ml-4 mr-4"
                  onClick={() =>
                    this.downloadFile(batchId, null, "ReportResult")
                  }
                >
                  <i className="fas fa-download" />
                  &nbsp; Download Data
                </a>

                <Select
                  name="statusFilter"
                  id="statusFilter"
                  isClearable
                  className="ml-2"
                  backspaceRemovesValue
                  placeholder="Filter by Status"
                  options={statusList}
                  value={this.state.statusFilter}
                  onChange={(value) => this.handleStatusFilterChange(value)}
                />
              </div>
            </div>
          </div>
          {!mismatchData ||
            (mismatchData.length === 0 && (
              <div className="cts-no-data">
                <div className="row col-md-12">
                  <div className="col-md-3" />
                  <div className="col-md-9">
                    Upload Source to get mismatches
                  </div>
                </div>
              </div>
            ))}
          {mismatchData && mismatchData.length > 0 && (
            <table className="table">
              <thead className="thead-light">
                <tr>
                  <th scope="col">Issue Value</th>
                  <th scope="col">Issue Type</th>
                  <th scope="col">Rule Name</th>
                  <th scope="col">View</th>
                  <th scope="col">Status</th>
                  <th scope="col">
                    <input
                      type="checkbox"
                      disabled={isScenarioLocked}
                      checked={ignoreAllChecked}
                      id="ignoreAllCheck"
                      name="ignoreAllCheck"
                      onChange={(e) => this.onIgnoreAllCheck(e)}
                    />
                    &nbsp;Ignore All
                    {isStatusUpdateLoading && (
                      <>
                        &nbsp;
                        <Spinner waiting />
                      </>
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>{this.renderMismatchData()}</tbody>
            </table>
          )}
        </div>
      </div>
    );
  }
}

MismatchTable.propTypes = {
  mismatchData: PropTypes.instanceOf(Array),
  isScenarioLocked: PropTypes.bool,
  onUploadFile: PropTypes.func.isRequired,
  batchId: PropTypes.number.isRequired
};

MismatchTable.defaultProps = {
  mismatchData: null,
  isScenarioLocked: false
};

export default MismatchTable;
