/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from "prop-types";
import React from "react";
import { toast } from "react-toastify";

import Spinner from "./shared/Spinner";
import api from "../services/RapApi";
import Constants from "../common/Constants";
import RapConstants from "../common/RapConstants";

class RecordHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isHistoryLoading: true,
      history: null,
      currentExpandedRowIndex: null,
      expandAll: false
    };
  }

  componentDidMount() {
    this.loadHistory();
  }

  componentDidUpdate(prevProps) {
    // If we reload the record, reset the userAddedValidations
    if (this.props.recordHistory !== prevProps.recordHistory) {
      this.setState(() => ({
        isHistoryLoading: false,
        history: this.props.recordHistory
      }));
    }
  }

  loadHistory = async () => {
    try {
      let history = await api.getRecordHistory(this.props.recordId);
      this.setState(() => ({ isHistoryLoading: false, history }));
    } catch (ex) {
      console.log(ex);
      toast.error(
        `There was an error in loading the history data - ${ex.message}`,
        Constants.TOAST_OPTIONS
      );
    }
  };

  renderHistoryData = () => {
    const { history, currentExpandedRowIndex, expandAll } = this.state;
    const finalRows = [];
    let linkText = "";

    history.forEach((row, index) => {
      const clickCallback = () => this.handleRowClick(index);
      const finalKey = `history-${index}`;
      if (currentExpandedRowIndex === index || expandAll) {
        linkText = "- Collapse";
      } else {
        linkText = "+ Expand";
      }

      // Get the change Rows
      const diffRows = this.renderChanges(row.diff);
      //  Date of Change / Changed By / Changes / Reason of Change
      // Show the collapsed row
      let changes = diffRows[0];
      if (currentExpandedRowIndex === index || expandAll) {
        // Show the expanded row
        changes = diffRows;
      }
      finalRows.push(
        <tr key={finalKey} onClick={clickCallback}>
          <td className="td-history">{row.editedBy}</td>
          <td className="td-history">{row.date}</td>
          <td className="td-history">{changes}</td>
          <td className="td-history">{row.reason}</td>
          <td className="td-history">
            <a onClick={() => clickCallback}>
              <div className="collapsed-row">{linkText}</div>
            </a>
          </td>
        </tr>
      );
    });
    return finalRows;
  };

  expandAllRecords = () => {
    this.setState((prevState) => ({
      expandAll: !prevState.expandAll,
      currentExpandedRowIndex: null
    }));
  };

  handleRowClick = (rowIndex) => {
    this.setState((prevState) => ({
      currentExpandedRowIndex:
        rowIndex === prevState.currentExpandedRowIndex ? null : rowIndex
    }));
  };

  renderChanges = (changeRow) => {
    const finalRows = [];
    for (let key in changeRow) {
      // eslint-disable-next-line no-prototype-builtins
      if (changeRow.hasOwnProperty(key)) {
        const finalKey = `change-${key}`;
        let fieldValue = changeRow[key];

        let keyDisplayValue = RapConstants.FORM_FIELDS[key];
        const oldVal = fieldValue && fieldValue[0];
        const newVal = fieldValue && fieldValue[1];
        const isAllocationsKey = key.startsWith("allocations");
        const isEliminatedKey = key.startsWith("Eliminated");
        if (isAllocationsKey) {
          // Capitalize the 1st letter of the allocations key
          keyDisplayValue = key[0].toUpperCase() + key.slice(1);
        }
        // Check if both old and new value is not null &
        const skipBlankRecords =
          (oldVal === "" && !newVal) ||
          (newVal === "" && !oldVal) ||
          (!oldVal && !newVal);
        // Skip any blank records or pinStatus update
        if (!skipBlankRecords && key !== "pinStatus") {
          if (
            isAllocationsKey &&
            ((oldVal && typeof oldVal === "object") ||
              (newVal && typeof newVal === "object"))
          ) {
            const oldValRows = this.renderAllocationChanges(oldVal);
            finalRows.push(
              <div key={`${finalKey}-old`}>
                {oldValRows.length > 0 && (
                  <div className="row">Old Allocation Row: </div>
                )}
                {oldValRows}
              </div>
            );

            const newValRows = this.renderAllocationChanges(newVal);
            finalRows.push(
              <div key={`${finalKey}-new`}>
                {newValRows.length > 0 && (
                  <div className="row">New Allocation Row: </div>
                )}
                {newValRows}
              </div>
            );
          } else if (
            isEliminatedKey &&
            ((oldVal && typeof oldVal === "object") ||
              (newVal && typeof newVal === "object"))
          ) {
            const newValRows = this.renderAllocationChanges(newVal);
            finalRows.push(
              <div key={`${finalKey}-eliminated`}>
                {newValRows.length > 0 && (
                  <div className="row">Eliminated Allocation: </div>
                )}
                {newValRows}
              </div>
            );
          } else if (typeof fieldValue === "string") {
            // This means the value has no old and new values but just one string
            finalRows.push(
              <div className="row" key={finalKey}>
                {keyDisplayValue}&nbsp;-&nbsp;<b>{fieldValue}</b>
              </div>
            );
          } else if (newVal) {
            // Its a regular array type
            finalRows.push(
              <div className="row" key={finalKey}>
                {keyDisplayValue}&nbsp;-&nbsp;<b>{newVal}</b>
                &nbsp;/&nbsp;
                <span className="td-confirm-modal grayed-text">{oldVal}</span>
              </div>
            );
          } else {
            finalRows.push(
              <div className="row" key={finalKey}>
                {keyDisplayValue}&nbsp;-&nbsp;
                <span className="td-confirm-modal grayed-text">{oldVal}</span>
              </div>
            );
          }
        }
      }
    }
    return finalRows;
  };

  renderAllocationChanges = (allocationVal) => {
    const finalRows = [];
    // If its an allocation array is returned (usually
    // when multiple allocations get added for the 1st time)
    if (Array.isArray(allocationVal)) {
      allocationVal.forEach((row) => {
        finalRows.push(
          <div className="row" key={`${row.client}${row.clientLob}${row.id}`}>
            <ul>
              <li>Allocation: {row.allocation}</li>
              <li>Client: {row.client}</li>
              <li>Client LOB: {row.clientLob}</li>
              <li>Rate Card: {row.rateCard}</li>
              <li>Status: {row.status}</li>
            </ul>
          </div>
        );
      });
    } else if (allocationVal) {
      // This means the value has come back as an object and not an array
      finalRows.push(
        <div className="row" key="allocation-0">
          <ul>
            {allocationVal.allocation && (
              <li>Allocation: {allocationVal.allocation}</li>
            )}
            {allocationVal.client && <li>Client: {allocationVal.client}</li>}
            {allocationVal.allocation && (
              <li>Client LOB: {allocationVal.clientLob}</li>
            )}
            {allocationVal.status && <li>Status: {allocationVal.status}</li>}
          </ul>
        </div>
      );
    }
    return finalRows;
  };

  render() {
    const { isHistoryLoading, expandAll } = this.state;
    if (isHistoryLoading) {
      return (
        <div>
          Loading History Data...Please wait....
          <Spinner waiting />
        </div>
      );
    }
    const topExpandText = expandAll ? "- Collapse All" : "+ Expand All";

    return (
      <div id="record-history" className="ml-2 row mr-1">
        <h5 className="float-left mt-2">History of Changes</h5>
        <table className="table mt-2">
          <thead className="thead-light">
            <tr>
              <th scope="col">Changed By </th>
              <th scope="col">Date of Change</th>
              <th scope="col">Changes</th>
              <th scope="col">Reason of Change</th>
              <th scope="col">
                <a onClick={() => this.expandAllRecords()}>{topExpandText}</a>
              </th>
            </tr>
          </thead>
          <tbody>{this.renderHistoryData()}</tbody>
        </table>
      </div>
    );
  }
}

RecordHistory.propTypes = {
  recordId: PropTypes.string.isRequired,
  recordHistory: PropTypes.instanceOf(Array)
};

RecordHistory.defaultProps = {
  recordHistory: null
};

export default RecordHistory;
