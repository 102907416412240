const capitalizeFirstLetter = (str) =>
  str.charAt(0).toUpperCase() + str.slice(1);

const isNullOrEmptyOrUndefined = (str) => {
  return str === null || str === undefined || str.trim() === "";
};

const getInitials = (name) => {
  const nameArray = name ? name.split(" ") : [];
  const firstNameInitial =
    nameArray.length > 0 ? nameArray[0].charAt(0).toUpperCase() : "";
  const lastNameInitial =
    nameArray.length > 1
      ? nameArray[nameArray.length - 1].charAt(0).toUpperCase()
      : "";
  return firstNameInitial + lastNameInitial;
};

const isFloat = (str) => {
  const parsed = parseFloat(str);

  return !isNaN(parsed) && parsed.toString() === str;
};

export default {
  capitalizeFirstLetter,
  isNullOrEmptyOrUndefined,
  getInitials,
  isFloat
};
