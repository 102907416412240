import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useSortBy, useTable } from "react-table";

const PermissionsTable = ({ data }) => {
  if (!data || data.length === 0) {
    return <div>No permissions available.</div>;
  }

  const tableColumns = useMemo(
    () => [
      {
        accessor: "name",
        Header: "All Permissions"
      }
    ],
    [data]
  );

  const { getTableProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns: tableColumns,
      data,
      initialState: {
        sortBy: [
          {
            id: "name",
            desc: false
          }
        ]
      },
      disableSortRemove: true
    },
    useSortBy
  );

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                className="col-select align-center"
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {column.render("Header")}
                <span>
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <i className="fas sort-button fa-arrow-down" />
                    ) : (
                      <i className="fas sort-button fa-arrow-up" />
                    )
                  ) : (
                    ""
                  )}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {rows.map(
          (row) =>
            prepareRow(row) || (
              <tr
                style={{
                  textAlign: "left"
                }}
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                ))}
              </tr>
            )
        )}
      </tbody>
    </table>
  );
};

PermissionsTable.propTypes = {
  data: PropTypes.instanceOf(Array)
};

PermissionsTable.defaultProps = {
  data: []
};

export default PermissionsTable;
