import React, { useEffect, useRef } from "react";
import OktaSignIn from "@okta/okta-signin-widget";
import PropTypes from "prop-types";

const OktaSignInWidget = ({ config, onSuccess, onError }) => {
  const widgetRef = useRef();
  useEffect(() => {
    if (!widgetRef.current) return false;

    const widget = new OktaSignIn(config);

    const oktaAuthClient = widget.authClient;
    oktaAuthClient.session.exists().then(() => {
      oktaAuthClient.token
        .getWithoutPrompt({
          responseType: ["token", "id_token"]
        })
        .then((res) => {
          oktaAuthClient.handleLoginRedirect(res.tokens);
        });
    });

    // if no session, render sign-in widget
    widget
      .showSignInToGetTokens({
        el: widgetRef.current
      })
      .then(onSuccess)
      .catch(onError);

    return () => widget.remove();
  }, [config, onSuccess, onError]);

  return <div ref={widgetRef} />;
};
OktaSignInWidget.propTypes = {
  config: PropTypes.shape({
    issuer: PropTypes.string,
    clientId: PropTypes.string,
    redirectUri: PropTypes.string,
    scopes: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired
};

export default OktaSignInWidget;
