import { useState, useCallback, useRef, useEffect } from "react";
import { toast } from "react-toastify"; // Import the toast function
import parApi from "../../services/ParApi"; // Adjust the import path as needed

const useFileUpload = (parRequestId) => {
  const [files, setFiles] = useState([]);
  const intervalRefs = useRef({});
  const completeCallback = useRef(null);
  const uploadingCount = useRef(0);

  const cancelUpload = (file) => {
    setFiles(files.filter((x) => x.file !== file));
    if (intervalRefs.current[file.name]) {
      clearInterval(intervalRefs.current[file.name]);
      delete intervalRefs.current[file.name];
    }
    // Decrement the count if a file was uploading and was canceled
    uploadingCount.current = Math.max(0, uploadingCount.current - 1);
  };

  const readFileAsBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });

  useEffect(
    () =>
      // Cleanup intervals on component unmount
      () => {
        Object.values(intervalRefs.current).forEach(clearInterval);
      },
    []
  );

  const uploadFiles = useCallback(
    async (newFiles) => {
      if (newFiles && newFiles.length) {
        const updatedFiles = newFiles.map((file) => ({
          file,
          progress: 0,
          downloadURL: "", // Initialize with an empty URL
          fileS3Path: "",
          isUploading: true // Track if the file is uploading
        }));
        setFiles((prevFiles) => [...prevFiles, ...updatedFiles]);
        uploadingCount.current += newFiles.length;
        const simulateProgress = (file) => {
          const fileIndex = updatedFiles.findIndex(
            (f) => f.file.name === file.name && f.file.size === file.size
          );
          if (fileIndex === -1) return;

          intervalRefs.current[file.name] = setInterval(() => {
            setFiles((prevFiles) => {
              const recentFiles = [...prevFiles];
              const fileToUpdate = recentFiles[fileIndex];
              if (fileToUpdate.progress < 90) {
                fileToUpdate.progress += 5;
                return recentFiles;
              }
              clearInterval(intervalRefs.current[file.name]);
              delete intervalRefs.current[file.name];
              return recentFiles;
            });
          }, 500);
        };

        try {
          await Promise.all(
            newFiles.map(async (file) => {
              simulateProgress(file); // Start simulating progress

              try {
                const base64String = await readFileAsBase64(file);
                const response = await parApi.uploadPARFile(
                  file.name,
                  base64String,
                  parRequestId?.value
                );

                if (response.success) {
                  setFiles((prevFiles) =>
                    prevFiles.map((f) =>
                      f.file === file
                        ? {
                            ...f,
                            progress: 100,
                            downloadURL: response.downloadURL,
                            fileS3Path: response.fileS3Path,
                            isUploading: false
                          }
                        : f
                    )
                  );
                } else {
                  setFiles((prevFiles) =>
                    prevFiles.map((f) =>
                      f.file === file
                        ? {
                            ...f,
                            progress: 0,
                            downloadURL: "",
                            fileS3Path: "",
                            isUploading: false
                          }
                        : f
                    )
                  );
                  toast.error(`File "${file.name}" upload failed.`);
                }
              } catch (error) {
                if (error.name === "AbortError") {
                  toast.info(`File "${file.name}" upload canceled.`);
                } else {
                  toast.error(
                    `File "${file.name}" upload failed: ${error.message}`
                  );
                }
                setFiles((prevFiles) =>
                  prevFiles.map((f) =>
                    f.file === file
                      ? {
                          ...f,
                          progress: 0,
                          downloadURL: "", // Reset URL on failure
                          fileS3Path: "",
                          isUploading: false
                        }
                      : f
                  )
                );
              } finally {
                // Decrement the count when a file finishes uploading
                uploadingCount.current = Math.max(
                  0,
                  uploadingCount.current - 1
                );

                // Check if all uploads are complete
                if (uploadingCount.current === 0 && completeCallback.current) {
                  completeCallback.current();
                }
              }
            })
          );
        } catch (error) {
          toast.error(`An error occurred: ${error.message}`);
        } finally {
          // Clear all intervals on completion or error
          Object.values(intervalRefs.current).forEach(clearInterval);
          intervalRefs.current = {};
        }
      }
    },
    [files]
  );

  const onComplete = useCallback((callback) => {
    completeCallback.current = callback;
  }, []);

  return {
    files,
    uploadFiles,
    cancelUpload,
    onComplete
  };
};

export default useFileUpload;
