import { useEffect, useState, useCallback } from "react";
import { toast } from "react-toastify";

import Constants from "../../common/Constants";
import api from "../../services/UserAdminApi";

const Users = () => {
  const [uiState, setUIState] = useState(Constants.UI_STATE_SUCCESS);
  const [userTableData, setUserTableData] = useState([]);

  const getUsers = useCallback(async () => {
    try {
      setUIState(Constants.UI_STATE_LOADING);
      const input = {
        searchTerm: "",
        groupSearch: ""
      };
      const res = await api.getUsers(input);

      if (res.users) {
        if (res.users.length) {
          setUIState(Constants.UI_STATE_SUCCESS);
          setUserTableData(res.users);
        } else {
          setUIState(Constants.UI_STATE_EMPTY);
        }
      } else {
        setUIState(Constants.UI_STATE_ERROR);
        toast.error(
          "There was an error in fetching users",
          Constants.TOAST_OPTIONS
        );
      }
    } catch (ex) {
      setUIState(Constants.UI_STATE_ERROR);
      // Ignore the exception, this means we did not get the data
      toast.error(
        `There was an error in fetching users - ${ex.message}`,
        Constants.TOAST_OPTIONS
      );
    }
  }, []);

  useEffect(() => {
    getUsers();
  }, []);

  return { userTableData, uiState, refreshUsers: getUsers };
};

export default Users;
