import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Formik, Form } from "formik";
import { toast } from "react-toastify";

import api from "../../services/RapApi";
import Constants from "../../common/Constants";
import RapConstants from "../../common/RapConstants";
import TypeAheadBoxField from "../shared/TypeAheadBoxField";
import TextBoxField from "../shared/TextBoxField";
import UploadFileSource from "../shared/UploadFileSource";

const LIST_FIELDS = [
  "dropdownType",
  "summaryDepartment",
  "serviceLineDepartment",
  "clientBrand"
];

const TEXT_FIELDS = ["dropdownValue", "clientBrandValue"];
const FIELDS = { clientLob: "Client LOB", client: "Client Brand" };
const FORM_FIELDS = { ...RapConstants.FORM_FIELDS, ...FIELDS };

let firstTextboxValue = "";
let secondTextboxValue = "";

const allfields = [...LIST_FIELDS, ...TEXT_FIELDS];

const DataManagementForm = () => {
  const [validations, setValidations] = useState(null);
  const [dropdownTypeValue, setDropdownTypeValue] = useState("");
  const [serviceLineDept, setServiceLineDept] = useState([]);
  const [disableValueTextBox, setDisableValueTextBox] = useState(true);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [flagSummaryDepartment, setFlagSummaryDepartment] = useState(false);
  const [flagServiceLineDepartment, setFlagServiceLineDepartment] =
    useState(false);
  const [flagClientBrand, setFlagClientBrand] = useState(false);
  const [firstTextBoxLabel, setFirstTextBoxLabel] = useState("");
  const [secondTextBoxLabel, setSecondTextBoxLabel] = useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [bulkUpdateData, setBulkUpdateData] = useState({
    rowCount: 0,
    status: "loading"
  });

  const loadValidationValues = async () => {
    try {
      const lstvalidations = await api.getValidations();
      setValidations(lstvalidations);
    } catch (ex) {
      console.log(ex);
      toast.error(
        `There was an error in loading the dropdown values - ${ex.message}`,
        Constants.TOAST_OPTIONS
      );
    }
  };

  useEffect(() => {
    loadValidationValues();
  }, []);

  const filterServiceLineDept = (value) => {
    // Clearing previous state
    setServiceLineDept([]);
    // filtering service line based on summary department value
    let filteredServiceLine = validations.serviceLineDepartmentList.filter(
      (x) => x.parentValue?.split(",").indexOf(value) > -1
    );

    setServiceLineDept(filteredServiceLine);
  };

  const dropdownTypeSelection = (value, formikProps) => {
    setDropdownTypeValue(value);

    // reset flag for all dropdowns
    setFlagSummaryDepartment(false);
    setFlagServiceLineDepartment(false);
    setFlagClientBrand(false);

    // disable save button
    setDisableSubmit(true);

    // clear the value from variables
    firstTextboxValue = "";
    secondTextboxValue = "";

    // reset selection in all other fields
    allfields.forEach((field) => {
      if (field !== "dropdownType") {
        if (LIST_FIELDS.includes(field)) {
          formikProps.setFieldValue(field, null);
        } else {
          formikProps.setFieldValue(field, "");
        }
      }
    });
  };

  const getInitialValues = () => {
    const formInitialValues = {};
    allfields.forEach((field) => {
      if (LIST_FIELDS.includes(field)) {
        formInitialValues[field] = null;
      } else {
        formInitialValues[field] = "";
      }
    });

    return formInitialValues;
  };

  const calculateFinalValues = (formInput) => {
    // method to calculate final values to be passed to lambda
    // setting default value of parent and parent value
    const finalValues = {
      parent: null,
      parentValue: null
    };

    // setting propName based on dropdown option selected
    finalValues.propName = RapConstants.PROPERTY_NAME_LIST[dropdownTypeValue];
    // property value to be added to DB
    finalValues.value = formInput.dropdownValue;

    // setting parent and parent value based on field selected
    if (dropdownTypeValue === FORM_FIELDS.serviceLineDepartment) {
      finalValues.parent =
        RapConstants.PROPERTY_NAME_LIST[FORM_FIELDS.summaryDepartment];
      finalValues.parentValue = formInput.summaryDepartment.value;
    } else if (dropdownTypeValue === FORM_FIELDS.payrollDepartment) {
      finalValues.parent =
        RapConstants.PROPERTY_NAME_LIST[FORM_FIELDS.serviceLineDepartment] +
        "~" +
        RapConstants.PROPERTY_NAME_LIST[FORM_FIELDS.summaryDepartment];
      finalValues.parentValue =
        formInput.serviceLineDepartment.value +
        "~" +
        formInput.summaryDepartment.value;
    } else if (dropdownTypeValue === FORM_FIELDS.clientLob) {
      finalValues.parent = RapConstants.PROPERTY_NAME_LIST[FORM_FIELDS.client];
      finalValues.parentValue = formInput.clientBrand.value;
    } else if (dropdownTypeValue === FORM_FIELDS.client) {
      finalValues.value =
        formInput.dropdownValue + " - " + formInput.clientBrandValue;
    }

    return finalValues;
  };

  const handleSubmit = async (formData) => {
    const finalValues = calculateFinalValues(formData);
    // disable save button
    setIsSubmitting(true);
    try {
      const data = (await api.saveValidationsData(finalValues)) || {};

      if (data.duplicateData) {
        toast.error("The value is already present", Constants.TOAST_OPTIONS);
      } else if (data.records) {
        toast.success("The value saved successfully", Constants.TOAST_OPTIONS);
      }
    } catch (ex) {
      toast.error(
        "There was a problem in saving the data",
        Constants.TOAST_OPTIONS
      );
    }
    setIsSubmitting(false);
  };

  const handleChange = async (formData) => {
    setDisableSubmit(true);

    // based on dropdown type selected
    // if values are provided in appropriate text fields then enable save button

    let fieldName = formData.target.name;
    let fieldValue = formData.target.value.trim();

    if (dropdownTypeValue !== FORM_FIELDS.client) {
      if (fieldValue) {
        setDisableSubmit(false);
      }
    } else {
      if (fieldName === "dropdownValue") {
        firstTextboxValue = fieldValue;
      }

      if (fieldName === "clientBrandValue") {
        secondTextboxValue = fieldValue;
      }

      if (firstTextboxValue && secondTextboxValue) {
        setDisableSubmit(false);
      }
    }
  };

  const handleTextBoxLabel = () => {
    // set text box fields label according to dropdown option selected
    switch (dropdownTypeValue) {
      case "Client Brand":
        setFirstTextBoxLabel("Client");
        setSecondTextBoxLabel("Brand");
        break;

      default:
        setFirstTextBoxLabel(dropdownTypeValue);
        break;
    }
  };

  const onSelection = () => {
    // on selection of options in summary department, service line department, client brand dropdowns
    // evaluate if text fields should be enabled or disabled

    setDisableValueTextBox(true);
    handleTextBoxLabel();

    if (
      dropdownTypeValue === FORM_FIELDS.serviceLineDepartment &&
      flagSummaryDepartment
    ) {
      setDisableValueTextBox(false);
    } else if (
      dropdownTypeValue === FORM_FIELDS.payrollDepartment &&
      flagSummaryDepartment &&
      flagServiceLineDepartment
    ) {
      setDisableValueTextBox(false);
    } else if (dropdownTypeValue === FORM_FIELDS.clientLob && flagClientBrand) {
      setDisableValueTextBox(false);
    } else if (
      dropdownTypeValue === FORM_FIELDS.client ||
      dropdownTypeValue === FORM_FIELDS.summaryDepartment ||
      dropdownTypeValue === FORM_FIELDS.workdayTitle ||
      dropdownTypeValue === FORM_FIELDS.payrollProfitCenter ||
      dropdownTypeValue === FORM_FIELDS.payrollCompany ||
      dropdownTypeValue === FORM_FIELDS.location
    ) {
      setDisableValueTextBox(false);
    }
  };

  const setClientBrand = (value, formikProps) => {
    setFlagClientBrand(value);
    if (!value) {
      formikProps.setFieldValue("dropdownValue", "");
      setDisableSubmit(true);
    }
  };

  const setSummaryDepartment = (value, formikProps) => {
    setFlagSummaryDepartment(Boolean(value));
    filterServiceLineDept(value);
    setFlagServiceLineDepartment(false);
    setDisableSubmit(true);

    formikProps.setFieldValue("serviceLineDepartment", null);
    formikProps.setFieldValue("dropdownValue", "");
  };

  const setServiceLineDepartment = (value, formikProps) => {
    setFlagServiceLineDepartment(value);
    if (!value) {
      formikProps.setFieldValue("dropdownValue", "");
      setDisableSubmit(true);
    }
  };

  useEffect(() => {
    onSelection();
  }, [
    dropdownTypeValue,
    flagSummaryDepartment,
    flagServiceLineDepartment,
    flagClientBrand,
    serviceLineDept
  ]);

  const updateBulkUploadData = async (count) => {
    setBulkUpdateData({ rowCount: count, status: "loaded" });
  };

  return (
    <Formik initialValues={getInitialValues()} onSubmit={handleSubmit}>
      {(formikProps) => (
        <Form
          className="form-horizontal mainform-card col-sm-12"
          name="dataManagement"
          onChange={handleChange}
        >
          <div className="card">
            <div className="row">
              <div className="col-sm-12 mt-2 mb-3 ml-3">
                <h5>Add New RAP Dropdown Entries</h5>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-2 mt-2 ml-3">
                <TypeAheadBoxField
                  formikProps={formikProps}
                  suggestions={RapConstants.DROPDOWN_TYPE_LIST}
                  fieldName="dropdownType"
                  label="Dropdown Type"
                  placeholder="Please select a dropdown type."
                  onValueChange={(value) =>
                    dropdownTypeSelection(value, formikProps)
                  }
                />
              </div>

              {(dropdownTypeValue === FORM_FIELDS.serviceLineDepartment ||
                dropdownTypeValue === FORM_FIELDS.payrollDepartment) && (
                <>
                  <div className="col-sm-2 mt-2 ml-2">
                    <TypeAheadBoxField
                      formikProps={formikProps}
                      suggestions={
                        validations ? validations.summaryDepartmentList : []
                      }
                      fieldName="summaryDepartment"
                      label="Summary Department"
                      onValueChange={(value) =>
                        setSummaryDepartment(value, formikProps)
                      }
                    />
                  </div>
                </>
              )}

              {dropdownTypeValue === FORM_FIELDS.payrollDepartment && (
                <>
                  <div className="col-sm-2 mt-2 ml-2">
                    <TypeAheadBoxField
                      formikProps={formikProps}
                      suggestions={serviceLineDept.map((x) => x.value)}
                      fieldName="serviceLineDepartment"
                      label="Service Line Department"
                      onValueChange={(value) =>
                        setServiceLineDepartment(Boolean(value), formikProps)
                      }
                    />
                  </div>
                </>
              )}

              {dropdownTypeValue === FORM_FIELDS.clientLob && (
                <>
                  <div className="col-sm-2 mt-2 ml-2">
                    <TypeAheadBoxField
                      formikProps={formikProps}
                      suggestions={validations ? validations.clientList : []}
                      fieldName="clientBrand"
                      label="Client - Brand"
                      placeholder="Please select a client."
                      onValueChange={(value) =>
                        setClientBrand(Boolean(value), formikProps)
                      }
                    />
                  </div>
                </>
              )}

              <div className="col-sm-2 ml-2">
                <TextBoxField
                  formikProps={formikProps}
                  fieldName="dropdownValue"
                  label={firstTextBoxLabel || "Option Value"}
                  isOptional={false}
                  className="mt-2"
                  disable={disableValueTextBox}
                />
              </div>

              {dropdownTypeValue === FORM_FIELDS.client && (
                <>
                  <div className="col-sm-2 ml-2">
                    <TextBoxField
                      formikProps={formikProps}
                      fieldName="clientBrandValue"
                      label={secondTextBoxLabel}
                      isOptional={false}
                      className="mt-2"
                      disable={disableValueTextBox}
                    />
                  </div>
                </>
              )}
            </div>
            <div className="row">
              <div className="col-sm-2 form-group mt-2 ml-3">
                <button
                  type="submit"
                  className={`btn btn-sm btn-${
                    formikProps.isValid ? "success" : "invalid"
                  }`}
                  disabled={disableSubmit || isSubmitting}
                >
                  Save
                </button>{" "}
              </div>
            </div>
          </div>
          <div className="card mt-4">
            <div className="row">
              <div className="col-sm-4 mt-2 mb-1 ml-4">
                <h5>Bulk Upload RAP Records</h5>
              </div>
              <div className="col-sm-4 mt-2 mb-1 ml-2">
                <h5>Bulk Upload Global Cost Card Data</h5>
              </div>
              <div className="col-sm-3 mt-2 mb-1">
                <h5>Bulk Update Smart Recruiter Data</h5>
              </div>
            </div>
            <div className="row ml-1 mb-2">
              <div className="col-md-4">
                <div className="mr-2 ctsClass">
                  <UploadFileSource
                    batchId={-1}
                    isBatchSourceLoading={false}
                    isScenarioLocked={false}
                    source={bulkUpdateData}
                    sourceName={Constants.SOURCE_RAP_BULK}
                    onSuccessfulUpload={updateBulkUploadData}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="ml-3 ctsClass">
                  <UploadFileSource
                    batchId={-1}
                    isBatchSourceLoading={false}
                    isScenarioLocked={false}
                    source={bulkUpdateData}
                    sourceName={Constants.SOURCE_GLOBAL_COST_DATA_BULK}
                    onSuccessfulUpload={updateBulkUploadData}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="ml-4 ctsClass">
                  <UploadFileSource
                    batchId={-1}
                    isBatchSourceLoading={false}
                    isScenarioLocked={false}
                    source={bulkUpdateData}
                    sourceName={Constants.SOURCE_SMARTRECRUITER_DATA_BULK}
                    onSuccessfulUpload={updateBulkUploadData}
                  />
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

DataManagementForm.propTypes = {
  validations: PropTypes.shape({
    clientList: PropTypes.instanceOf(Array),
    clientLobList: PropTypes.instanceOf(Array),
    payrollCompanyList: PropTypes.instanceOf(Array),
    payrollDepartmentList: PropTypes.instanceOf(Array),
    payrollProfitCenterList: PropTypes.instanceOf(Array),
    serviceLineDepartmentList: PropTypes.instanceOf(Array),
    summaryDepartmentList: PropTypes.instanceOf(Array),
    levelList: PropTypes.instanceOf(Array),
    locationList: PropTypes.instanceOf(Array)
  })
};

DataManagementForm.defaultProps = {
  validations: {}
};

export default DataManagementForm;
