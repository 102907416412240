import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Formik, Field, ErrorMessage, Form } from "formik";
import * as Yup from "yup";

import SelectBox from "../shared/SelectBox";
import Constants from "../../common/Constants";
import ParConstants from "../../common/ParConstants";

// Style
import "../../styles/user-manager.scss";

const UserForm = ({
  handleSubmit,
  initialValues,
  groupData,
  parGroupData,
  countryData,
  validationData
}) => {
  const navigate = useNavigate();
  const handleCancel = () => navigate(`/user-manager`);
  const {
    summaryDepartmentList,
    serviceLineDepartmentList,
    payrollProfitCenterList,
    clientBrandList
  } = validationData;

  const [filteredOptions, setFilteredOptions] = useState([]);

  const UserSchema = Yup.object().shape({
    displayName: Yup.string().required("Please enter a name"),
    email: Yup.string()
      .email("Invalid email")
      .required("Please enter an email"),
    enabled: Yup.boolean(),
    groups: Yup.array().of(Yup.string()),
    countries: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string(),
          name: Yup.string()
        })
      )
      .min(1, "Please add a country"),
    parGroup: Yup.array()
      .of(Yup.string())
      .test(
        "parGroup-required",
        "Please add a PAR permissions",
        (value, context) => {
          if (
            context.parent.groups.filter(
              (item) =>
                item === ParConstants.PAR_PERMISSIONS_MAPPING.finOps_PAR_approle
            ).length > 0
          ) {
            return (
              value !== undefined &&
              value[0] !== undefined &&
              value[0] !== null &&
              value[0].trim() !== ""
            );
          }
          return true;
        }
      ),
    summaryDepartment: Yup.array()
      .of(Yup.string())
      .test(
        "summary-required",
        "Summary Department should be selected if service line is selected",
        (value, context) => {
          const { serviceLineDepartment } = context.parent;
          if (
            serviceLineDepartment &&
            serviceLineDepartment.length > 0 &&
            serviceLineDepartment[0] &&
            serviceLineDepartment[0].trim() !== ""
          ) {
            return (
              value !== undefined &&
              value.length > 0 &&
              value[0] !== undefined &&
              value[0] !== null &&
              value[0].trim() !== ""
            );
          }
          return true;
        }
      ),
    serviceLineDepartment: Yup.array()
      .of(Yup.string())
      .test(
        "serviceline-required",
        "Please select Service Line Department",
        (value, context) => {
          const { summaryDepartment } = context.parent;
          if (
            summaryDepartment &&
            summaryDepartment.length > 0 &&
            summaryDepartment[0] &&
            summaryDepartment[0].trim() !== ""
          ) {
            return (
              value !== undefined &&
              value.length > 0 &&
              value[0] !== undefined &&
              value[0] !== null &&
              value[0].trim() !== ""
            );
          }
          return true;
        }
      ),
    payrollProfitCenter: Yup.array().of(Yup.string()),
    clientBrand: Yup.array().of(Yup.string())
  });

  const getParGroupValue = (values) => {
    if (values.parGroup) {
      return values.parGroup[0];
    }
    return "";
  };

  const filterServiceLine = (setFieldValue, value) => {
    setFieldValue("summaryDepartment", [value]);
    let filterOptions = [];

    filterOptions = serviceLineDepartmentList.filter(
      (option) => option.parent === value
    );

    setFilteredOptions(filterOptions || []);
  };

  return (
    <>
      <div className="form-horizontal m-2">
        <Formik
          initialValues={initialValues}
          validationSchema={UserSchema}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, dirty, isValid, isSubmitting }) => (
            <Form>
              <div className="form-group mt-1">
                <label htmlFor={Constants.USER_FORM_FIELDS.name}>
                  Name<span className="red-text">&nbsp;*</span>
                </label>
                <Field
                  type="text"
                  name={Constants.USER_FORM_FIELDS.name}
                  className="form-control"
                  aria-label={Constants.USER_FORM_FIELDS.name}
                />
                <div className="mt-1 error-message">
                  <ErrorMessage name={Constants.USER_FORM_FIELDS.name} />
                </div>
              </div>
              <div className="form-group mt-3">
                <label htmlFor="email">
                  Email<span className="red-text">&nbsp;*</span>
                </label>
                <Field
                  type="email"
                  name={Constants.USER_FORM_FIELDS.email}
                  className="form-control"
                  aria-label={Constants.USER_FORM_FIELDS.email}
                />
                <div className="mt-1 error-message">
                  <ErrorMessage name="email" />
                </div>
              </div>
              <div className="form-group mt-3">
                <label htmlFor={Constants.USER_FORM_FIELDS.countries}>
                  Country Access<span className="red-text">&nbsp;*</span>
                </label>

                <SelectBox
                  id="countryId"
                  name={Constants.USER_FORM_FIELDS.countries}
                  placeholder="Select countries"
                  defaultValue={values.countries}
                  options={countryData}
                  onSelectHandler={(value) => setFieldValue("countries", value)}
                  isMultiSelect
                />
                <div className="mt-1 error-message">
                  <ErrorMessage name={Constants.USER_FORM_FIELDS.countries} />
                </div>
              </div>
              <div className="form-group mt-3">
                <label htmlFor="permissionId">
                  Permissions<span className="red-text">&nbsp;*</span>
                </label>
                <SelectBox
                  id="permissionId"
                  name={Constants.USER_FORM_FIELDS.groups}
                  placeholder="Select groups"
                  defaultValue={values.groups}
                  options={groupData}
                  onSelectHandler={(value) => setFieldValue("groups", value)}
                  isMultiSelect
                />
                <div className="mt-1 error-message">
                  <ErrorMessage name={Constants.USER_FORM_FIELDS.groups} />
                </div>
              </div>
              <div>
                <label htmlFor="checkbox">Active</label>
                <Field
                  id="checkbox"
                  type="checkbox"
                  className="ml-1"
                  name={Constants.USER_FORM_FIELDS.status}
                />
              </div>
              {values?.groups?.filter(
                (item) =>
                  item ===
                  ParConstants.PAR_PERMISSIONS_MAPPING.finOps_PAR_approle
              ).length > 0 && (
                // only show PAR settings to finOps_PAR-approle
                <>
                  <hr />
                  <h3>PAR Permission Settings</h3>
                  <div className="form-group mt-3">
                    <label htmlFor={Constants.USER_FORM_FIELDS.parpermissions}>
                      PAR Permission<span className="red-text">&nbsp;*</span>
                    </label>
                    <SelectBox
                      id="parPermissionId"
                      name={Constants.USER_FORM_FIELDS.parpermissions}
                      placeholder="Select PAR permissions"
                      options={parGroupData}
                      defaultValue={values.parGroup || []}
                      onSelectHandler={(value) =>
                        setFieldValue("parGroup", [value])
                      }
                    />
                    <div className="mt-1 error-message">
                      <ErrorMessage
                        name={Constants.USER_FORM_FIELDS.parpermissions}
                      />
                    </div>
                  </div>
                  {getParGroupValue(values) ===
                    ParConstants.PAR_PERMISSIONS_MAPPING
                      .PAR_ScaledServiceLead && (
                    // fields applicable to finOps_PAR-ScaledServiceLead-userRole
                    <>
                      <div className="form-group mt-3">
                        <label htmlFor="summary_department">
                          Summary Department
                        </label>
                        <SelectBox
                          id="summary_department"
                          name={Constants.USER_FORM_FIELDS.summarydepartment}
                          placeholder="Select Summary Department"
                          defaultValue={values.summaryDepartment || []}
                          options={summaryDepartmentList}
                          onSelectHandler={(value) =>
                            filterServiceLine(setFieldValue, value)
                          }
                        />
                      </div>
                      <div className="form-group mt-3">
                        <label htmlFor="serviceline_Department">
                          Service Line Department
                        </label>
                        <SelectBox
                          id="serviceline_Department"
                          name={
                            Constants.USER_FORM_FIELDS.servicelinedepartment
                          }
                          placeholder="Select Service Line Department"
                          defaultValue={values.serviceLineDepartment}
                          options={filteredOptions}
                          onSelectHandler={(value) =>
                            setFieldValue("serviceLineDepartment", value)
                          }
                          isMultiSelect
                        />
                      </div>
                    </>
                  )}

                  {getParGroupValue(values) ===
                    ParConstants.PAR_PERMISSIONS_MAPPING.PAR_BrandCEO && (
                    // fields applicable to finops_PAR_BrandCEO-userRole
                    <div className="form-group mt-3">
                      <label htmlFor="profit_center">
                        Payroll - Profit Center
                      </label>
                      <SelectBox
                        id="profit_center"
                        name={Constants.USER_FORM_FIELDS.profitcenter}
                        placeholder="Select Payroll - Profit Center"
                        defaultValue={values.payrollProfitCenter}
                        options={payrollProfitCenterList}
                        onSelectHandler={(value) =>
                          setFieldValue("payrollProfitCenter", value)
                        }
                        isMultiSelect
                      />
                    </div>
                  )}

                  {(getParGroupValue(values) ===
                    ParConstants.PAR_PERMISSIONS_MAPPING.PAR_Finance ||
                    getParGroupValue(values) ===
                      ParConstants.PAR_PERMISSIONS_MAPPING
                        .PAR_ClientBrandSubmitter) && (
                    // fields application to finOps_PAR-Finance-userRole OR finOps_PAR-ClientBrandSubmitter-userRole

                    <div className="form-group mt-3">
                      <label
                        htmlFor={Constants.USER_FORM_FIELDS.clientbrandlist}
                      >
                        Brand
                      </label>
                      <SelectBox
                        id="par_brand"
                        name={Constants.USER_FORM_FIELDS.clientbrandlist}
                        placeholder="Select Brand"
                        defaultValue={values.clientBrandList}
                        options={clientBrandList}
                        onSelectHandler={(value) =>
                          setFieldValue("clientBrandList", value)
                        }
                        isMultiSelect
                      />
                    </div>
                  )}
                </>
              )}
              <div className="form-group pt-3">
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={!dirty || !isValid}
                >
                  {isSubmitting ? "Submitting..." : "Submit"}
                </button>
                <button
                  className="btn btn-default ml-2"
                  type="button"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default UserForm;

UserForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    displayName: PropTypes.string,
    email: PropTypes.string,
    groups: PropTypes.arrayOf(PropTypes.string),
    countries: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string
      })
    ),
    enabled: PropTypes.bool,
    parGroup: PropTypes.arrayOf(PropTypes.string),
    payrollProfitCenter: PropTypes.arrayOf(PropTypes.string),
    serviceLineDepartment: PropTypes.arrayOf(PropTypes.string),
    summaryDepartment: PropTypes.arrayOf(PropTypes.string),
    clientBrandList: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  groupData: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ),
  parGroupData: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ),
  countryData: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string
      })
    })
  ),
  validationData: PropTypes.shape({
    summaryDepartmentList: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      })
    ),
    serviceLineDepartmentList: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      })
    ),
    payrollProfitCenterList: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      })
    ),
    clientBrandList: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      })
    )
  }).isRequired
};

UserForm.defaultProps = {
  groupData: [],
  countryData: [],
  parGroupData: []
};
