import React from "react";
import PropTypes from "prop-types";

class ButtonGroup extends React.PureComponent {
  buttonClicked(v) {
    const { setButtonValue, multiple } = this.props;
    if (!multiple) {
      setButtonValue(v);
    } else {
      const { fieldValue } = this.props;

      let fieldValues = (fieldValue || "")
        .split(",")
        .map((s) => s.trim())
        .filter((s) => !!s);

      if (fieldValues.includes(v)) {
        fieldValues = fieldValues.filter((fv) => fv !== v);
        if (fieldValues.length) {
          setButtonValue(fieldValues.join(","));
        } else {
          setButtonValue("");
        }
      } else {
        setButtonValue([...new Set([...fieldValues, v])].join(","));
      }
    }
  }

  render() {
    const {
      fieldName,
      fieldValue,
      label,
      buttonValues,
      inError,
      extraButtonClass,
      isDisabled,
      isRequired,
      buttonLabels,
      disable,
      customColors,
      metaObj
      // Custom Colors should be an object with the following structure:
      // {
      //   LABEL_VALUE: DESIRED_COLOR,
      //   LABEL_VALUE: DESIRED_COLOR,
      // }
    } = this.props;
    const fieldValues = (fieldValue || "")
      .split(",")
      .map((s) => s.trim())
      .filter((s) => !!s);
    const finalList = [];
    buttonValues.forEach((buttonValue, count) => {
      const customColor =
        customColors && customColors[buttonValue.toLowerCase()];
      let buttonClass = fieldValues.includes(buttonValue)
        ? "btn-success"
        : "btn-empty";
      buttonClass =
        buttonValue === "Yes" &&
        buttonClass === "btn-success" &&
        fieldName === "pinApproved"
          ? "btn-approved"
          : buttonValue === "No" &&
            buttonClass === "btn-success" &&
            fieldName === "pinApproved"
          ? "btn-nonapproved"
          : buttonClass;

      if (customColors) {
        buttonClass =
          customColor && buttonClass === "btn-success"
            ? customColor
            : buttonClass;
      }

      buttonClass = count !== 0 ? `${buttonClass} btn-no-border` : buttonClass;

      const buttonLabel =
        buttonLabels.length > 0 ? buttonLabels[count] : buttonValue;
      finalList.push(
        <td
          key={buttonValue + "_td"}
          className={`btn btn-sm ${buttonClass}`}
          style={inError ? { borderColor: "#DC2F23" } : {}}
        >
          <button
            type="button"
            name="approvedPinNo"
            key={buttonValue}
            disabled={isDisabled || disable}
            className={`btn btn-sm ${buttonClass} ${extraButtonClass}`}
            onClick={() => this.buttonClicked(buttonValue)}
          >
            {buttonLabel}
          </button>
        </td>
      );
    });

    return (
      <div>
        <label htmlFor="btnGrpForm">
          {label}
          {isRequired && <span className="red-text"> *</span>}
        </label>
        <div id={`btnGrpForm-${fieldName}`} role="group">
          {/* setting the width directly here, because the width of the component is now determined by the container */}
          <table style={{ width: "100%" }}>
            <tbody>
              <tr className="btn-group" style={{ width: "100%" }}>
                {finalList}
              </tr>
            </tbody>
          </table>
        </div>
        {metaObj && metaObj.error && (
          <div className="mt-1 text-danger">{metaObj.error}</div>
        )}
      </div>
    );
  }
}

ButtonGroup.propTypes = {
  fieldName: PropTypes.string.isRequired,
  fieldValue: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  setButtonValue: PropTypes.func.isRequired,
  buttonValues: PropTypes.instanceOf(Array),
  buttonLabels: PropTypes.instanceOf(Array),
  inError: PropTypes.bool,
  multiple: PropTypes.bool,
  extraButtonClass: PropTypes.string,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  disable: PropTypes.bool,
  customColors: PropTypes.shape({}),
  metaObj: PropTypes.shape({
    error: PropTypes.string
  })
};

ButtonGroup.defaultProps = {
  inError: false,
  multiple: false,
  buttonValues: [],
  buttonLabels: [],
  extraButtonClass: "",
  isDisabled: false,
  isRequired: false,
  disable: false,
  customColors: {},
  metaObj: {}
};

export default ButtonGroup;
