/* eslint-disable no-return-assign */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { isValid } from "date-fns";
import { toast } from "react-toastify";
import "../../../styles/shared/collapse-panel.scss";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import security from "../../../services/Security";
import api from "../../../services/ParApi";
import Constants from "../../../common/Constants";
import RapConstants from "../../../common/RapConstants";
import ParConstants from "../../../common/ParConstants";
import ParStatus from "./ParStatus";
import PositionJustification from "./PositionJustification";
import RequisitionInstructions from "./RequisitionInstructions";
import PositionDetails from "./PositionDetails";
import Modal from "../../shared/Modal";
import ConfirmationModal from "../../shared/ConfirmationModal";

import TicketAction from "./TicketAction";
import Sidebar from "../../shared/Sidebar";
import TicketHistory from "../ticket_history/TicketHistory";

const ParForm = ({
  validations,
  parType,
  getValidationSchema,
  initialData,
  parAvailablePins,
  parPayRanges,
  refreshForm
}) => {
  const navigate = useNavigate();
  const DEFAULT_VALUES = {
    allocations: []
  };
  // Set PAR ID in case its an existing PAR Record
  const [parRequestId, setParRequestId] = useState(
    (initialData && initialData.par_request_id) || null
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [warningMessage, setWarningMessage] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSaved, setSaved] = useState(false);
  const [doNavigate, setDoNavigate] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isRefreshForm, setRefreshForm] = useState(false);

  const [modalMessage, setModalMessage] = useState("");
  const [availablePINs, setAvailablePINs] = useState(parAvailablePins || []);
  const [payRanges, setPayRanges] = useState(parPayRanges || []);
  const [globalCostData, setGlobalCostData] = useState([]);
  const canModifyPARRecords = security.canEditPARData();
  const [userRole] = useState(
    window.userSession.parPermissions.permission_name
  );

  // Permission Roles + Queues
  const isSubmitterRole =
    userRole === ParConstants.PAR_PERMISSIONS_MAPPING.PAR_ClientBrandSubmitter;
  const isRMIntake =
    initialData &&
    initialData.current_queue_name === ParConstants.PAR_QUEUE.RM_INTAKE;
  const isHRIntake =
    initialData &&
    initialData.current_queue_name === ParConstants.PAR_QUEUE.HR_INTAKE;
  const isHRAction =
    initialData &&
    initialData.current_queue_name === ParConstants.PAR_QUEUE.HR_ACTION;
  const isRMAction =
    initialData &&
    initialData.current_queue_name === ParConstants.PAR_QUEUE.RM_ACTION;
  const isScaledService =
    initialData &&
    initialData.current_queue_name === ParConstants.PAR_QUEUE.SCALED_SVC;
  const isFinance =
    initialData &&
    initialData.current_queue_name === ParConstants.PAR_QUEUE.FINANCE;
  const isBrandCEO =
    initialData &&
    initialData.current_queue_name === ParConstants.PAR_QUEUE.BRAND_CEO;
  const isExecutive =
    initialData &&
    initialData.current_queue_name === ParConstants.PAR_QUEUE.EXCECUTIVE;

  // Only if PAR is complete OR has permissions to be viewed,
  // we will show it as editable in the current user login
  const showCancelOnly = () => {
    let isCancelOnly = false;
    if (initialData && !initialData.existing_msl_pin) {
      // Check if PAR is complete
      isCancelOnly =
        initialData.ticket_status === ParConstants.PAR_ACTIONS.PAR_COMPLETE;
      // Check if user has opened the PAR But does not actually have access to it
      if (ParConstants.PAR_QUEUE_PERMISSION_MAP[userRole]) {
        isCancelOnly =
          isCancelOnly ||
          !ParConstants.PAR_QUEUE_PERMISSION_MAP[userRole].includes(
            initialData.current_queue_name
          );
      } else {
        // Its the client submitter role that means a client submitter is trying
        // to view a PAR that is not created by the viewing user
        isCancelOnly =
          isCancelOnly ||
          initialData.created_by !== window?.userSession?.userId;
      }
    }
    return isCancelOnly;
  };

  const isCancelOnly = showCancelOnly();
  // By default always show read only fields if the request is in a queue
  // OR if its already complete
  const showDescription =
    initialData && (initialData.current_queue_id != null || isCancelOnly);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (isRefreshForm) {
      const timer = setTimeout(() => {
        setRefreshForm(false);
        refreshForm(parRequestId);
      }, 1000); // Auto-close after 1 seconds
      return () => clearTimeout(timer);
    }
    return false;
  }, [isRefreshForm]);

  useEffect(() => {
    if (doNavigate) {
      const timer = setTimeout(() => {
        setDoNavigate(false);
        navigate("/par", {
          state: { currentTab: "tab2" }
        });
      }, 2000); // Auto-close after 2 seconds

      return () => clearTimeout(timer);
    }
    return false;
  }, [doNavigate]);

  useEffect(() => {
    if (isModalOpen) {
      const timer = setTimeout(() => {
        closeModal();
      }, 3000); // Auto-close after 3 seconds

      return () => clearTimeout(timer);
    }
    return false;
  }, [isModalOpen]);

  let abuseFormikProps = null;

  // Class Object that determines which fields are shown as read only in from
  let showFieldsReadOnly = {
    allocClient: true,
    allocLOB: true,
    allocVal: true,

    allocRateCard: true,
    allocReconcilable: true,
    profit_center: true,
    related_pars: true,
    is_exempt: true,
    partner: true,
    partner_cost: true,
    offshore_role: true,
    par_brand: true,
    par_axcompany: true,
    par_payroll_company: true,
    par_bpc: true,
    job_code: true,
    requisition_id: true,

    current_performance_rating: true,
    current_base_salary: true,
    date_of_last_compensation_change: true,
    date_of_lastpromotion: true,
    requested_offer: true,
    business_title: true,
    target_budget: true,
    salary_budget: true
  };

  // Class Object that determines which fields are hidden in the form
  let hideFields = {
    allocRateCard: true,
    allocReconcilable: true,
    profit_center: true,
    is_exempt: true,
    partner: true,
    partner_cost: true,
    offshore_role: true,
    par_brand: true,
    par_axcompany: true,
    par_payroll_company: true,
    par_bpc: true,
    job_code: true,
    requisition_id: true,

    current_performance_rating: true,
    current_base_salary: true,
    date_of_last_compensation_change: true,
    date_of_lastpromotion: true,
    requested_offer: true,
    compa_ratio_of_offer: true,
    target_budget: true,
    salary_budget: true
  };

  /**
   * Method that determines which fields will be visible
   * and will be either editable or readonly for each of the
   * workflow types
   * Captures the business rules from the requirements to set
   * field status for each field type per workflow.
   */
  const setFieldVisibilityAndStatus = () => {
    const workflowName = parType && parType.name;

    const showExemptField =
      workflowName === ParConstants.PAR_WORKFLOW.BACKFILL_REQUISITION ||
      workflowName === ParConstants.PAR_WORKFLOW.OPEN_REQUISITION_TBH;

    const isReqRelatedWorkflow =
      workflowName === ParConstants.PAR_WORKFLOW.OPEN_REQUISITION ||
      workflowName === ParConstants.PAR_WORKFLOW.BACKFILL_REQUISITION ||
      workflowName === ParConstants.PAR_WORKFLOW.OPEN_REQUISITION_TBH;

    const showReqBPCSection =
      isReqRelatedWorkflow ||
      workflowName === ParConstants.PAR_WORKFLOW.PROMOTION_IN_PLACE;

    if (isSubmitterRole) {
      showFieldsReadOnly.allocClient = showDescription;
      showFieldsReadOnly.allocLOB = showDescription;
      showFieldsReadOnly.allocVal = showDescription;
      showFieldsReadOnly.is_exempt = !showExemptField;
      hideFields.is_exempt = !showExemptField;

      // Internal Fill Fields need to be enabled and editable
      hideFields.current_performance_rating = showDescription;
      hideFields.current_base_salary = showDescription;
      hideFields.date_of_last_compensation_change = showDescription;
      hideFields.date_of_lastpromotion = showDescription;
      hideFields.requested_offer = showDescription;
      showFieldsReadOnly.current_performance_rating = showDescription;
      showFieldsReadOnly.current_base_salary = showDescription;
      showFieldsReadOnly.date_of_last_compensation_change = showDescription;
      showFieldsReadOnly.date_of_lastpromotion = showDescription;
      showFieldsReadOnly.requested_offer = showDescription;
      showFieldsReadOnly.business_title = showDescription;
      showFieldsReadOnly.related_pars = showDescription;
    } else if (isRMIntake) {
      // Make some fields enabled for RM Intake Queue
      const isOpenReq =
        workflowName === ParConstants.PAR_WORKFLOW.OPEN_REQUISITION;
      // For OPEN_REQUISITION Workflow, these 3 fields are non-editable
      showFieldsReadOnly.allocClient = isOpenReq;
      showFieldsReadOnly.allocLOB = isOpenReq;
      showFieldsReadOnly.allocVal = isOpenReq;
      // All these fields are visible and editable for RM Intake
      showFieldsReadOnly.allocRateCard = false;
      hideFields.allocRateCard = false;
      showFieldsReadOnly.allocReconcilable = false;
      hideFields.allocReconcilable = false;
      showFieldsReadOnly.profit_center = false;
      hideFields.profit_center = false;
      showFieldsReadOnly.partner = false;
      hideFields.partner = false;
      showFieldsReadOnly.partner_cost = true;
      hideFields.partner_cost = false;
      hideFields.offshore_role = false;
      showFieldsReadOnly.offshore_role = false;

      // Exempt is shown for all fields for the relevant queues
      showFieldsReadOnly.is_exempt = false;
      hideFields.is_exempt = !showExemptField;
      // Related PAR's can be edited by RM Intake
      showFieldsReadOnly.related_pars = false;

      // PAR Brand, AxCompany Payroll, BPC are shown as readonly fields
      // for specific queues
      hideFields.par_brand = !showReqBPCSection;
      hideFields.par_axcompany = !showReqBPCSection;
      hideFields.par_payroll_company = !showReqBPCSection;
      hideFields.par_bpc = !showReqBPCSection;
      hideFields.salary_budget = false;
    } else if (isScaledService || isHRIntake || isExecutive) {
      // Hide rateCard,reconcilable, profit_center fields for Scaled Service/HR Intake Queue
      // Partner and PartnerCost is not shown in HRIntake Queue
      hideFields.partner = !!isHRIntake;
      hideFields.partner_cost = !!isHRIntake;
      hideFields.offshore_role = !!isHRIntake;
      // PAR Brand, AxCompany, Payroll, BPC to be shown only for HR Intake
      hideFields.par_brand = !!(showReqBPCSection && !isHRIntake);
      hideFields.par_axcompany = !!(showReqBPCSection && !isHRIntake);
      hideFields.par_payroll_company = !!(showReqBPCSection && !isHRIntake);
      hideFields.par_bpc = !!(showReqBPCSection && !isHRIntake);
      // Exempt is shown for all fields for the relevant queues
      hideFields.is_exempt = !showExemptField;
      // job_code should be editable for HRIntake
      hideFields.job_code = !!(showReqBPCSection && !isHRIntake);
      showFieldsReadOnly.job_code = !(showReqBPCSection && isHRIntake);

      // is_exempt is editable for HR Intake
      showFieldsReadOnly.is_exempt = !(showExemptField && isHRIntake);

      // Internal Fill Fields need to be enabled and editable for HRIntake Only
      hideFields.current_performance_rating = false;
      hideFields.current_base_salary = false;
      hideFields.date_of_last_compensation_change = false;
      hideFields.date_of_lastpromotion = false;
      hideFields.requested_offer = false;
      hideFields.compa_ratio_of_offer = !(isHRIntake || isExecutive);
      showFieldsReadOnly.current_performance_rating = !isHRIntake;
      showFieldsReadOnly.current_base_salary = !isHRIntake;
      showFieldsReadOnly.date_of_last_compensation_change = !isHRIntake;
      showFieldsReadOnly.date_of_lastpromotion = !isHRIntake;
      showFieldsReadOnly.requested_offer = !isHRIntake;

      // HRIntake Queue should be able to edit business_title
      showFieldsReadOnly.business_title = !isHRIntake;
      // Executive level Hiring Manager is hidden
      hideFields.hiring_manager = isExecutive;
      hideFields.target_budget = !(
        isHRIntake ||
        !isScaledService ||
        isExecutive
      );
      hideFields.salary_budget = false;
      showFieldsReadOnly.target_budget = !isHRIntake;
    } else if (isFinance || isBrandCEO || isRMAction || isHRAction) {
      hideFields.allocRateCard = false;
      hideFields.allocReconcilable = false;
      hideFields.profit_center = false;
      hideFields.is_exempt = !showExemptField;
      // Partner and PartnerCost is not shown in HRAction Queue
      hideFields.partner = !!isHRAction;
      hideFields.partner_cost = !!isHRAction;
      hideFields.offshore_role = !!isHRAction;
      // PAR Brand, AxCompanym Payroll, BPC to be shown only for RM & HR action
      hideFields.par_brand = showReqBPCSection && !(isRMAction || isHRAction);
      hideFields.par_axcompany =
        showReqBPCSection && !(isRMAction || isHRAction);
      hideFields.par_payroll_company =
        showReqBPCSection && !(isRMAction || isHRAction);
      hideFields.par_bpc = showReqBPCSection && !(isRMAction || isHRAction);
      // job_code is displayed but is non-editable for RM & HR Action
      hideFields.job_code = showReqBPCSection && !(isRMAction || isHRAction);

      // requisition_id is displayed and editable for RM Action
      // requisition_id is displayed but is non-editable for HR Action
      hideFields.requisition_id = !(isHRAction || isRMAction);
      showFieldsReadOnly.requisition_id = !isRMAction;
      hideFields.compa_ratio_of_offer = !(
        isHRAction ||
        isBrandCEO ||
        isFinance
      );
      hideFields.target_budget = false;
      hideFields.salary_budget = false;
      showFieldsReadOnly.target_budget = true;
      showFieldsReadOnly.salary_budget = !isFinance;
      // Hide internal fill fields for RM action and finance for TBH workflow
      const hideSalaryFields =
        workflowName === ParConstants.PAR_WORKFLOW.OPEN_REQUISITION_TBH ||
        workflowName === ParConstants.PAR_WORKFLOW.BACKFILL_REQUISITION;
      hideFields.current_performance_rating =
        (hideSalaryFields && isFinance) || isRMAction;
      hideFields.current_base_salary =
        (hideSalaryFields && isFinance) || isRMAction;
      hideFields.date_of_last_compensation_change =
        (hideSalaryFields && isFinance) || isRMAction;
      hideFields.date_of_lastpromotion =
        (hideSalaryFields && isFinance) || isRMAction;
      hideFields.requested_offer = isRMAction;
      // Finance, Brand CEO Hiring Manager is hidden
      hideFields.hiring_manager = isFinance || isBrandCEO;
    }
  };

  const getSalaryBudget = (data) =>
    parseFloat(
      data?.allocations?.reduce(
        (sum, currentValue) =>
          sum +
          (currentValue.rate_card
            ? currentValue.rate_card * currentValue.allocation
            : 0),
        0
      )
    ).toFixed(2);

  const getInitialValues = (inputData) => {
    if (initialData) {
      inputData = initialData;
    }
    setFieldVisibilityAndStatus();

    if (!inputData) {
      inputData = {};
    }
    const formInitialValues = {};
    formInitialValues.allocations = [];
    const formFields = Object.keys(ParConstants.FORM_FIELDS);
    formFields.forEach((field) => {
      if (ParConstants.LIST_FIELDS.includes(field)) {
        if (inputData[field]) {
          if (field === "cc_emails" || field === "related_pars") {
            formInitialValues[field] = inputData[field].split(",").map((x) => ({
              value: x,
              label: x
            }));
          } else {
            formInitialValues[field] = {
              value: inputData[field],
              label: inputData[field]
            };
          }
        }
      } else if (field === "allocations") {
        formInitialValues[field] = [];
        if (inputData[field] && inputData[field].length > 0) {
          inputData[field].forEach((item, count) => {
            let allocationRow = {
              client_brand: null,
              client_lob: null,
              allocation: item.allocation || "",
              rate_card: item.rateCard || item.rate_card || "",
              id: item.id,
              allocation_id: count
            };
            if (item.client_brand) {
              allocationRow.client_brand = {
                value: item.client_brand,
                label: item.client_brand
              };
            }
            if (item.client_lob) {
              allocationRow.client_lob = {
                value: item.client_lob || "",
                label: item.client_lob
              };
            }
            if (item.status) {
              allocationRow.status = {
                value: item.status,
                label: item.status
              };
            }
            allocationRow.reconcilable = item.reconcilable || "";
            allocationRow.rate_card = item.rate_card || "";
            allocationRow.disableAllocation = item.disableAllocation || false;
            formInitialValues[field].push(allocationRow);
          });
        }
      } else if (
        field === "fill_date" ||
        field === "date_of_last_compensation_change" ||
        field === "date_of_lastpromotion"
      ) {
        formInitialValues[field] = "";
        if (inputData[field]) {
          // Convert to date which is required for the datePicker field
          const convertedDate = new Date(inputData[field]);
          formInitialValues[field] = isValid(convertedDate)
            ? convertedDate
            : "";
        }
      } else if (inputData[field]) {
        formInitialValues[field] = inputData[field];
      } else if (DEFAULT_VALUES[field] !== undefined) {
        formInitialValues[field] = DEFAULT_VALUES[field];
      } else if (field === "current_queue_id") {
        formInitialValues[field] = null;
      } else {
        formInitialValues[field] = "";
      }
    });
    formInitialValues.current_queue_name = inputData.current_queue_name;
    formInitialValues.salary_budget =
      inputData.salary_budget || getSalaryBudget(inputData);
    return formInitialValues;
  };

  const getPARAvailableMSLPINs = async () => {
    try {
      const res = await api.getAvailablePins({ countryId: 1 });
      setAvailablePINs(res);
      return res;
    } catch (ex) {
      console.error(ex);
      return [];
    }
  };

  const getPARPayRanges = async () => {
    try {
      const res = await api.getPARPayRanges();
      setPayRanges(res || []);
    } catch (ex) {
      console.error(ex);
    }
  };

  const getGlobalCostCardData = async () => {
    try {
      const data = await api.getGlobalCostData();
      setGlobalCostData(data || []);
    } catch (ex) {
      console.error(ex);
    }
  };

  useEffect(() => {
    if (!parAvailablePins || parAvailablePins.length === 0) {
      getPARAvailableMSLPINs();
    }
    if (!parPayRanges || parPayRanges.length === 0) {
      getPARPayRanges();
    }
    if (isRMIntake) {
      getGlobalCostCardData();
    }
  }, []);

  const getFormData = (data) => {
    let newData = {};
    for (let prop in data) {
      if (ParConstants.LIST_FIELDS.indexOf(prop) > -1) {
        if (prop === "cc_emails" || prop === "related_pars") {
          newData[prop] = data[prop]?.map((x) => x.value).join();
        } else {
          newData[prop] = data[prop]?.value;
        }
      } else if (prop === "allocations") {
        const allocations = JSON.parse(JSON.stringify(data[prop]));
        allocations.forEach((allocation) => {
          allocation.client_brand = allocation.client_brand.value;
          allocation.client_lob = allocation.client_lob?.value;
          allocation.status = allocation.status?.value;
          allocation.rate_card = allocation.rate_card || 0;
          allocation.reconcilable = allocation.reconcilable || "";
        });
        newData[prop] = allocations;
      } else if (
        prop === "date_of_last_compensation_change" ||
        prop === "requested_offer" ||
        prop === "current_base_salary" ||
        prop === "date_of_lastpromotion"
      ) {
        newData[prop] = data[prop] !== "" ? data[prop] : null;
      } else {
        newData[prop] = data[prop];
      }
    }
    return newData;
  };

  const handleSubmit = async () => {
    const formData = getFormData(abuseFormikProps.values);
    setSaved(false);
    let response = {};
    try {
      // By default workflow_id is always for "Open Requistion"
      let workflowId = 1;
      if (parType && parType.workflow_id) {
        workflowId = parType.workflow_id;
      }
      // Set the incoming MSL PIN as request_id if present
      if (
        !formData.par_request_id &&
        initialData &&
        initialData.existing_msl_pin
      ) {
        formData.par_request_id = initialData.existing_msl_pin;
      }
      response = await api.savePARRequest({
        par_request_data: formData,
        par_workflow_id: workflowId
      });
      if (response.success) {
        setParRequestId(response.par_request_id);
        abuseFormikProps.setFieldValue("par_request_id", {
          label: response.par_request_id,
          value: response.par_request_id
        });
        if (isSubmitterRole) {
          setRefreshForm(true);
        }
        setIsModalOpen(true);
        setModalMessage(
          `Your position action request (${response.par_request_id}) has been saved.`
        );
      } else {
        // Show error
        console.log("Error in saving PAR");
        console.log(response.error);
        toast.error(
          "There was a problem in saving the PAR",
          Constants.TOAST_OPTIONS
        );
      }
    } catch (ex) {
      console.log(ex);
      toast.error(
        `There was an unexpected error in saving the PAR - ${ex.message}`,
        Constants.TOAST_OPTIONS
      );
    }
    setSaved(true);
  };

  const onSubmitPAR = async ({ actionName = null, successMessage = null }) => {
    setSubmitting(true);
    if (abuseFormikProps.isValid) {
      const finalAction =
        actionName || ParConstants.PAR_ACTIONS.NEW_PAR_SUBMITTED;
      const finalSuccessMsg =
        successMessage || "PAR Request Submitted Successfully";
      let comment = "";
      if (abuseFormikProps.values.comment) {
        comment = abuseFormikProps.values.comment;
      }
      await api
        .actionOnPARRequest({
          par_request_id: parRequestId,
          action: finalAction,
          comment
        })
        .then(
          (response) => {
            if (response.success) {
              setModalMessage(finalSuccessMsg);
              setIsModalOpen(true);
              setDoNavigate(true);
            } else {
              setModalMessage(response.error);
              setIsModalOpen(true);
            }
          },
          (error) => {
            setModalMessage(error);
            setIsModalOpen(true);
          }
        );
    }
    setSubmitting(false);
  };

  const onWithdraw = async () => {
    // Call actionOnPARRequest API Method with withdrawn action
    await onSubmitPAR({
      actionName: ParConstants.PAR_ACTIONS.WITHDRAWN,
      successMessage: "PAR Request has been withdrawn"
    });
  };

  const onDeletePAR = async () => {
    setSubmitting(true);
    if (abuseFormikProps.isValid) {
      await api.deletePARRequest(parRequestId).then(
        (response) => {
          if (response.success) {
            setModalMessage(
              `PAR ${parRequestId} has been deleted successfully`
            );
            setIsModalOpen(true);
            setDoNavigate(true);
          } else {
            setModalMessage(response.error);
            setIsModalOpen(true);
          }
        },
        (error) => {
          setModalMessage(error);
          setIsModalOpen(true);
        }
      );
    }
    setSubmitting(false);
  };

  // Captures all business rules/actions based on the workflow
  const getActionTypeForWorkflow = () => {
    let actionName = ParConstants.PAR_ACTIONS.APPROVED;
    let successMessage = "PAR Request has been advanced to next level";
    const completeMessage = "PAR Request is now complete";
    const isEmpTypeGlobal =
      abuseFormikProps.values.employee_type &&
      abuseFormikProps.values.employee_type?.value ===
        RapConstants.EMPLOYEE_TYPE_VALUES[1];
    const workflowName = parType && parType.name;
    const reqReason = abuseFormikProps.values.open_requisition_reason.value;
    if (
      workflowName === ParConstants.PAR_WORKFLOW.OPEN_REQUISITION ||
      workflowName === ParConstants.PAR_WORKFLOW.OPEN_REQUISITION_TBH
    ) {
      if (isRMIntake) {
        actionName = ParConstants.PAR_ACTIONS.PIN_CONFIRMED_EXISTING_CLIENT;
        if (reqReason === ParConstants.REQUISITION_NEW_CLIENT) {
          actionName = ParConstants.PAR_ACTIONS.PIN_CONFIRMED_NEW_CLIENT;
        } else if (reqReason === ParConstants.REQUISITION_PRACTICE_INVST) {
          actionName = ParConstants.PAR_ACTIONS.PIN_CONFIRMED_PRACTICE_INVT;
        }
      } else if (isScaledService) {
        // If employeeType Global, skip HR and go to Finance
        if (isEmpTypeGlobal) {
          actionName = ParConstants.PAR_ACTIONS.APPROVED_GLOBAL;
        }
        if (reqReason === ParConstants.REQUISITION_PRACTICE_INVST) {
          actionName = !isEmpTypeGlobal
            ? ParConstants.PAR_ACTIONS.APPROVED_PRACTICEINVST
            : ParConstants.PAR_ACTIONS.APPROVED_PRACTICEINVST_GLOBAL;
        }
      } else if (isHRIntake || isFinance) {
        if (reqReason === ParConstants.REQUISITION_PRACTICE_INVST) {
          actionName = ParConstants.PAR_ACTIONS.APPROVED_PRACTICEINVST;
        }
      } else if (isBrandCEO) {
        // Check if Exception Request
        if (reqReason === ParConstants.REQUISITION_PRACTICE_INVST) {
          actionName = ParConstants.PAR_ACTIONS.APPROVED_PRACTICEINVST;
        } else {
          const level = abuseFormikProps.values.par_level.value;
          // Remove all alphabets and convert to number
          const levelNum = parseInt(level.replace(/\D/g, ""), 10);
          if (levelNum >= 50) {
            actionName = ParConstants.PAR_ACTIONS.APPROVED_OVER_50;
          } else {
            actionName = ParConstants.PAR_ACTIONS.APPROVED_BELOW_45;
          }
        }
      } else if (isRMAction) {
        // If Employee Type has External Fill as Fill type or Emp Type as Global we
        // skip going to HR Action and RM Action directly marks the ticket as complete
        const isExternalFill =
          abuseFormikProps.values.fill_type &&
          abuseFormikProps.values.fill_type?.value ===
            ParConstants.FILL_TYPES[0];
        if (isEmpTypeGlobal || isExternalFill) {
          actionName = ParConstants.PAR_ACTIONS.PAR_COMPLETE;
          successMessage = completeMessage;
        } else {
          actionName = ParConstants.PAR_ACTIONS.PAR_COMPLETE_RMACTION;
        }
      } else if (isHRAction) {
        actionName = ParConstants.PAR_ACTIONS.PAR_COMPLETE;
        successMessage = completeMessage;
      } else if (isExecutive) {
        actionName = ParConstants.PAR_ACTIONS.APPROVED_EXCEPTION;
      }
    } // TODO: add conditions for other workflow types
    return {
      actionName,
      successMessage
    };
  };

  const onAdvancePAR = async () => {
    const actionObj = getActionTypeForWorkflow();
    // Call actionOnPARRequest API Method with relevant action
    await onSubmitPAR({
      actionName: actionObj.actionName,
      successMessage: actionObj.successMessage
    });

    setSaved(false);
  };

  const onReturnPAR = async () => {
    // Call actionOnPARRequest API Method with relevant action
    let actionName = ParConstants.PAR_ACTIONS.RETURNED;
    let successMessage = "PAR Request has been returned to the Submitter";
    if (isExecutive) {
      actionName = ParConstants.PAR_ACTIONS.RETURNED_TO_CEO;
      successMessage = "PAR Request has been returned to Brand CEO Queue";
    }
    await onSubmitPAR({
      actionName,
      successMessage
    });
    setSaved(false);
  };

  const onViewHistory = async () => {
    setIsSidebarOpen(true);
  };

  const getInternalEmployeeWarning = () => {
    let message = null;
    if (
      abuseFormikProps.values.fill_type &&
      (abuseFormikProps.values.fill_type.value === ParConstants.FILL_TYPES[1] ||
        abuseFormikProps.values.fill_type.value === ParConstants.FILL_TYPES[2])
    ) {
      const employee = availablePINs.filter(
        (x) => x.employeeName === abuseFormikProps.values.employee_name.value
      );

      if (employee && employee[0]) {
        const isDifferentLevel =
          employee[0].level !== abuseFormikProps.values.par_level.value;
        const isDifferentDepartment =
          employee[0].deptServiceLine !==
          abuseFormikProps.values.serviceline_department.value;
        if (isDifferentLevel && isDifferentDepartment) {
          message = (
            <>
              The selected Employee’s <strong>Level</strong> and{" "}
              <strong>Service Line Department</strong> does not match the value
              in PAR. Are you sure you want to proceed?
            </>
          );
        } else if (isDifferentLevel) {
          message = (
            <>
              The selected Employee’s <strong>Level</strong> does not match the
              value in PAR. Are you sure you want to proceed?
            </>
          );
        } else if (isDifferentDepartment) {
          message = (
            <>
              The selected Employee’s <strong>Service Line Department</strong>{" "}
              does not match the value in PAR. Are you sure you want to proceed?
            </>
          );
        } else {
          message = null;
        }
      }
    }
    return message;
  };

  const onConfirm = () => {
    setIsConfirmModalOpen(false);
    handleSubmit();
  };

  const OnSaveAsDraft = () => {
    if (abuseFormikProps.isValid) {
      if (isSubmitterRole) {
        // Show the warning only for submitter role
        const message = getInternalEmployeeWarning();
        if (message) {
          setWarningMessage(message);
          setIsConfirmModalOpen(true);
        } else {
          handleSubmit();
        }
      } else {
        handleSubmit();
      }
    }
  };

  const OnCancel = () => {
    navigate("/par", {
      state: { currentTab: "tab2" }
    });
  };

  const getAdvanceButtonText = () => {
    let buttonText = "Advance";
    if (isHRAction || isRMAction) {
      buttonText = "Complete";
    } else if (isBrandCEO || isExecutive) {
      buttonText = "Approve";
    }
    return buttonText;
  };
  return (
    <>
      <div className="row sticky-header" id="sticky-header">
        <div className="col-md-12">
          {initialData &&
          initialData.par_request_id &&
          !initialData.existing_msl_pin
            ? `View PAR Details - ${parType?.name}`
            : parType
            ? `Create PAR - ${parType.name}`
            : "Create PAR - Open Requisition"}
        </div>
      </div>
      <div className="row col-md-12 page-title" id="page-title">
        <div className="col-md-6">
          {initialData &&
          initialData.par_request_id &&
          !initialData.existing_msl_pin
            ? "PAR - " + initialData.par_request_id
            : initialData && initialData.existing_msl_pin
            ? `New PAR - ${initialData.existing_msl_pin}`
            : `Open Requisition Form`}
        </div>
        {showDescription && (
          <div className="col-md-6  d-flex align-self-center justify-end pr-0">
            <button
              type="button"
              id="btnExpandAll"
              name="btnExpandAll"
              className="btn btn-sm btn-secondary"
              onClick={onViewHistory}
            >
              View Ticket History
            </button>
          </div>
        )}
      </div>
      <div>
        <Formik
          enableReinitialize
          initialValues={getInitialValues(initialData)}
          validationSchema={getValidationSchema()}
          onSubmit={handleSubmit}
        >
          {(formikProps) => (
            <Form id="CreateForm">
              {(abuseFormikProps = formikProps) && <></>}
              <PositionDetails
                formikProps={formikProps}
                canModifyPARRecords={canModifyPARRecords}
                validations={validations || {}}
                showDescription={showDescription}
                workflowName={parType && parType.name}
                showFieldsReadOnly={showFieldsReadOnly}
                hideFields={hideFields}
                getSalaryBudget={getSalaryBudget}
              />
              <RequisitionInstructions
                formikProps={formikProps}
                canModifyPARRecords={canModifyPARRecords}
                availablePINs={availablePINs || []}
                validations={validations || {}}
                showDescription={showDescription}
                workflowName={parType && parType.name}
                showFieldsReadOnly={showFieldsReadOnly}
                hideFields={hideFields}
                parPayRanges={payRanges || []}
                globalCostData={globalCostData}
              />

              <PositionJustification
                formikProps={formikProps}
                canModifyPARRecords={canModifyPARRecords}
                availablePARRequests={availablePINs}
                showDescription={showDescription}
                showFieldsReadOnly={showFieldsReadOnly}
                isSubmitterRole={isSubmitterRole}
              />

              <ParStatus
                fillType={abuseFormikProps.values.fill_type}
                parType={parType && parType.name}
                formikProps={formikProps}
                showFieldsReadOnly={showFieldsReadOnly}
                hideFields={hideFields}
              />

              <TicketAction
                formikProps={formikProps}
                isDraft={!showDescription}
              />
              <div className="row  d-flex align-self-center justify-end">
                <div className="mr-3">
                  <button
                    type="button"
                    id="btnCancel"
                    name="btnCancel"
                    className="btn btn-sm btn-link"
                    onClick={OnCancel}
                  >
                    Cancel
                  </button>
                </div>
                {/* Show Buttons only if PAR is not complete */}
                {!isCancelOnly && (
                  <>
                    {showDescription && isSubmitterRole ? (
                      <div className="mr-3">
                        <button
                          type="button"
                          id="btnWithdraw"
                          name="btnWithdraw"
                          className="btn btn-sm btn-primary"
                          onClick={onWithdraw}
                          disabled={
                            !abuseFormikProps.values.current_queue_id ||
                            !abuseFormikProps.isValid ||
                            isSubmitting
                          }
                        >
                          Withdraw
                        </button>
                        &nbsp;
                        {isSubmitting && (
                          <i className="fas fa-spinner fa-pulse fa-lg" />
                        )}
                      </div>
                    ) : (
                      <>
                        <div className="mr-3">
                          <button
                            type="button"
                            id="btnSaveAsDraft"
                            name="btnSaveAsDraft"
                            className="btn btn-sm btn-secondary"
                            onClick={OnSaveAsDraft}
                            disabled={!abuseFormikProps.isValid}
                          >
                            Save As Draft
                          </button>
                        </div>
                        {isSubmitterRole &&
                          parRequestId &&
                          !abuseFormikProps.values.ticket_status && (
                            <div className="mr-3">
                              <button
                                type="button"
                                id="btnSubmitPAR"
                                name="btnSubmitPAR"
                                className="btn btn-sm btn-secondary"
                                onClick={onDeletePAR}
                                disabled={
                                  !abuseFormikProps.isValid || isSubmitting
                                }
                              >
                                Delete PAR
                              </button>
                            </div>
                          )}
                        <div className="mr-3">
                          {isSubmitterRole && (
                            <button
                              type="button"
                              id="btnSubmitPAR"
                              name="btnSubmitPAR"
                              className="btn btn-sm btn-primary"
                              onClick={onSubmitPAR}
                              disabled={
                                !parRequestId ||
                                !abuseFormikProps.isValid ||
                                isSubmitting
                              }
                            >
                              Submit PAR
                            </button>
                          )}
                          {!isSubmitterRole && (
                            <button
                              type="button"
                              id="btnAdvancePAR"
                              name="btnAdvancePAR"
                              className="btn btn-sm btn-primary"
                              onClick={onAdvancePAR}
                              disabled={
                                !parRequestId ||
                                !abuseFormikProps.isValid ||
                                !isSaved ||
                                isSubmitting
                              }
                            >
                              {getAdvanceButtonText()}
                            </button>
                          )}
                        </div>
                        <div className="mr-3">
                          {!isSubmitterRole && !isHRAction && !isRMAction && (
                            <button
                              type="button"
                              id="btnReturnPAR"
                              name="btnReturnPAR"
                              className="btn btn-sm btn-primary"
                              onClick={onReturnPAR}
                              disabled={
                                !parRequestId ||
                                !abuseFormikProps.isValid ||
                                !isSaved ||
                                isSubmitting
                              }
                            >
                              Return
                            </button>
                          )}
                          &nbsp;
                          {isSubmitting && (
                            <i className="fas fa-spinner fa-pulse fa-lg" />
                          )}
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </Form>
          )}
        </Formik>
        <Modal isOpen={isModalOpen}>
          <div>{modalMessage}</div>
        </Modal>
      </div>
      <ConfirmationModal
        isOpen={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        onConfirm={() => onConfirm()}
      >
        <div>{warningMessage}</div>
      </ConfirmationModal>

      {showDescription && (
        <Sidebar
          isOpen={isSidebarOpen}
          header="Ticket History"
          onClose={() => setIsSidebarOpen(false)}
        >
          <TicketHistory parRequestId={initialData?.par_request_id} />
        </Sidebar>
      )}
    </>
  );
};

ParForm.propTypes = {
  getValidationSchema: PropTypes.func,
  validations: PropTypes.shape({
    clientList: PropTypes.instanceOf(Array),
    clientLobList: PropTypes.instanceOf(Array),
    payrollCompanyList: PropTypes.instanceOf(Array),
    payrollDepartmentList: PropTypes.instanceOf(Array),
    payrollProfitCenterList: PropTypes.instanceOf(Array),
    serviceLineDepartmentList: PropTypes.instanceOf(Array),
    summaryDepartmentList: PropTypes.instanceOf(Array),
    tbhCategoryList: PropTypes.instanceOf(Array),
    roleTitleList: PropTypes.instanceOf(Array),
    locationList: PropTypes.instanceOf(Array),
    levelList: PropTypes.instanceOf(Array)
  }).isRequired,
  initialData: PropTypes.shape({
    par_request_id: PropTypes.string,
    current_queue_id: PropTypes.number,
    existing_msl_pin: PropTypes.string,
    current_queue_name: PropTypes.string,
    ticket_status: PropTypes.string,
    created_by: PropTypes.number
  }),
  parType: PropTypes.shape({
    workflow_id: PropTypes.number,
    name: PropTypes.string
  }),
  parAvailablePins: PropTypes.instanceOf(Array),
  parPayRanges: PropTypes.instanceOf(Array),
  refreshForm: PropTypes.func
};

ParForm.defaultProps = {
  parType: null,
  getValidationSchema: {},
  initialData: {
    par_request_id: null,
    current_queue_id: null,
    current_queue_name: null
  },
  parAvailablePins: [],
  parPayRanges: [],
  refreshForm: {}
};

export default ParForm;
