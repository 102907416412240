import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import api from "../../services/RapApi";
import Constants from "../../common/Constants";

const Validations = () => {
  const [summaryDepartmentList, setSummaryDepartment] = useState([]);
  const [serviceLineDepartmentList, setServiceLineDepartment] = useState([]);
  const [payrollProfitCenterList, SetpayrollProfitCenter] = useState([]);
  const [clientBrandList, SetclientBrand] = useState([]);
  const [clientList, setClientList] = useState([]);

  const getValidations = async () => {
    try {
      const result = await api.getValidations();
      if (result) {
        const summaryDepartment = result.summaryDepartmentList;
        const serviceLineDepartment = result.serviceLineDepartmentList;
        const payrollProfitCenter = result.payrollProfitCenterList;
        const clientBrand = result.clientBrandList;
        const clients = result.clientList;

        if (summaryDepartment) {
          const summaryDepartmentList = summaryDepartment.map((val) => ({
            label: val,
            value: val
          }));
          setSummaryDepartment(summaryDepartmentList);
        }

        if (serviceLineDepartment) {
          const serviceLineDepartmentList = serviceLineDepartment.map(
            (val) => ({
              label: val.value,
              value: val.value,
              parent: val.parentValue
            })
          );
          setServiceLineDepartment(serviceLineDepartmentList);
        }

        if (payrollProfitCenter) {
          const payrollProfitCenterList = payrollProfitCenter.map((val) => ({
            label: val,
            value: val
          }));
          SetpayrollProfitCenter(payrollProfitCenterList);
        }

        if (clientBrand) {
          const clientBrandList = clientBrand.map((val) => ({
            label: val,
            value: val
          }));
          SetclientBrand(clientBrandList);
        }

        if (clients) {
          const clientsList = clients.map((val) => ({
            label: val,
            value: val
          }));
          setClientList(clientsList);
        }
      } else {
        toast.error(
          "There was an error in fetching validations",
          Constants.TOAST_OPTIONS
        );
      }
    } catch (ex) {
      toast.error(
        `There was an error in fetching validations - ${ex.message}`,
        Constants.TOAST_OPTIONS
      );
    }
  };

  useEffect(() => {
    getValidations();
  }, []);

  return {
    summaryDepartmentList,
    serviceLineDepartmentList,
    payrollProfitCenterList,
    clientBrandList,
    clientList
  };
};

export default Validations;
