import React, { useState } from "react";
import PropTypes from "prop-types";
import { Outlet } from "react-router-dom";
import OktaLoginPage from "./OktaLoginPage";
import ParConstants from "./common/ParConstants";

const NoPermissionPage = ({ routeName }) => (
  <div className="row red-text ml-4">
    <h5>
      You do not have permissions to access the {routeName} page. Please contact
      the technical team to get access.
    </h5>
  </div>
);
NoPermissionPage.propTypes = {
  routeName: PropTypes.string.isRequired
};

const ProtectedRoute = ({
  isLoggedIn,
  isSessionSet,
  hasPermissions,
  routeName,
  oktaAuthConfig,
  children,
  setSession
}) => {
  const [isSession, setIsSession] = useState(false);

  // We are not logged in, so show okta page to login
  if (!isLoggedIn) {
    // get pageSize from localStorage before clearing
    const staffPageSize = localStorage.getItem(
      ParConstants.STAFF_PAGE_LOCAL_STORAGE
    );
    const parPageSize = localStorage.getItem(
      ParConstants.PAR_SUBMISSION_LOCAL_STORAGE
    );
    // Clear localStorage in case there is any
    localStorage.clear();
    // set pageSize back to the localStorage
    localStorage.setItem(ParConstants.STAFF_PAGE_LOCAL_STORAGE, staffPageSize);
    localStorage.setItem(
      ParConstants.PAR_SUBMISSION_LOCAL_STORAGE,
      parPageSize
    );

    return <OktaLoginPage config={oktaAuthConfig} />;
  }
  // No session set, lets set it!
  if (!isSessionSet) {
    if (!isSession) {
      setIsSession(true);
      setSession();
    }
    return <Outlet />;
  }

  if (!hasPermissions) {
    return <NoPermissionPage routeName={routeName} />;
  }

  if (children) {
    return children;
  }
  return <Outlet />;
};

ProtectedRoute.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  hasPermissions: PropTypes.bool.isRequired,
  routeName: PropTypes.string.isRequired,
  isSessionSet: PropTypes.bool,
  oktaAuthConfig: PropTypes.shape({
    issuer: PropTypes.string,
    clientId: PropTypes.string,
    redirectUri: PropTypes.string,
    scopes: PropTypes.arrayOf(PropTypes.string)
  }),
  children: PropTypes.node,
  setSession: PropTypes.func
};
ProtectedRoute.defaultProps = {
  isSessionSet: false,
  oktaAuthConfig: {},
  children: null,
  setSession: () => {}
};

export default ProtectedRoute;
