import Cookies from "../helpers/Cookies";

/**
 * Generic HTTP function that performs both GET and POST
 */
let isProd = process.env.NODE_ENV + "" === "production";
let isUat = !isProd && !process.env.LAMBDA_ROUTING;
let isStaging = (document.location + "").toLowerCase().includes("staging");
let lambdaRouting = process.env.LAMBDA_ROUTING;

window.isStaging = isStaging;
window.isProd = isProd;

async function baseRequest(verb, apiEndpoint, data = null) {
  let authKey = "";
  if (window.userSession) {
    authKey = window.userSession.authKey;
  }
  // construct the request
  const headers = {
    Authorization: authKey,
    Accept: "application/json",
    "Content-Type": "application/json",
    Pragma: "no-cache",
    "Cache-Control": "no-store, no-cache"
  };
  if (process.env.DISABLE_HEADERS) {
    delete headers.Pragma;
    delete headers["Cache-Control"];
  }
  let request = {
    method: verb,
    headers
  };

  // Set the body if data is present
  if (data) {
    request.body = JSON.stringify(data);
  }

  let sessionObject = null;
  let obj = { ...window.userSession };
  // no need to send authKey in X-ats-application header
  delete obj.authKey;
  if (isProd || isUat) {
    if (window.userSession) {
      delete obj.lambdaRouting; // ensure no routing for UAT and PROD
      sessionObject = obj;
      request.headers["X-ats-application"] = JSON.stringify(sessionObject);
    }
  } else if (isStaging || lambdaRouting) {
    if (window.userSession) {
      obj.lambdaRouting = {
        costToServeDataLoading: isStaging ? "DEV2" : lambdaRouting
      };
      sessionObject = obj;
      request.headers["X-ats-application"] = JSON.stringify(sessionObject);
    } else {
      request.headers["X-ats-application"] = JSON.stringify({
        lambdaRouting: {
          costToServeDataLoading: isStaging ? "DEV2" : lambdaRouting,
          resourceToolServices: isStaging ? "DEV2" : lambdaRouting
        }
      });
    }
  }

  // store it...
  if (sessionObject && sessionObject.userId) {
    // Set the cookie value
    Cookies.setCookie(
      "X-ats-application",
      request.headers["X-ats-application"],
      2
    );
  }

  // Call the API to get the response
  const response = await fetch(apiEndpoint, request);
  const payload = await response.json();

  if (!response.ok) {
    throw new Error(payload.error || "Unexpected Error");
  }

  return payload;
}

export default {
  baseRequest
};
