import PropTypes from "prop-types";
import React from "react";

const Dropdown = ({ submenus, dropdown, toggleDropDown }) => {
  const handleToggle = () => {
    toggleDropDown(false);
  };

  return (
    <div className={`dropdown-content ${dropdown ? "show-dropdown" : ""}`}>
      {submenus.map((submenu) => (
        <a href={submenu.url} key={submenu.title} onClick={handleToggle}>
          {submenu.title}
        </a>
      ))}
    </div>
  );
};

Dropdown.propTypes = {
  submenus: PropTypes.instanceOf(Array),
  dropdown: PropTypes.bool,
  toggleDropDown: PropTypes.func
};

Dropdown.defaultProps = {
  submenus: [],
  dropdown: false,
  toggleDropDown: () => {}
};

export default Dropdown;
