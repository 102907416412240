import React from "react";
import PropTypes from "prop-types";

const ProgressOverlay = ({ message }) => (
  <div className="overlayProgress">
    <div style={{ position: "relative", width: "100%", height: "100%" }}>
      <div
        className="overlayProgressContent"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="row">
          <div className="col-md-1" />
          <div className="col-md-10">
            <div className="row d-flex justify-content-center">
              <i className="fa fa-spinner fa-spin fa-2x" />
            </div>
            <div className="row d-flex justify-content-center">{message}</div>
          </div>
          <div className="col-md-1" />
        </div>
      </div>
    </div>
  </div>
);

ProgressOverlay.propTypes = {
  message: PropTypes.element
};
ProgressOverlay.defaultProps = {
  message: <div />
};

export default ProgressOverlay;
