// Scenario stages
const SCENARIO_STATUS_LOCKED = "Locked";
const SCENARIO_STATUS_ARCHIVED = "Archived";
const SCENARIO_STATUS_ACTIVE = "Active";

const SCENARIO_CREATED_YESTERDAY = "yesterday";

// Environment Variables
// eslint-disable-next-line prefer-destructuring
const CTS_API_ROOT_URL = process.env.CTS_API_ROOT_URL;

// Data Sources
const SOURCE_WORKDAY = "Workday";
const SOURCE_TIMESHEET = "Timesheet";
const SOURCE_ADP = "ADP";
const SOURCE_MASTER_SERVICE_SET = "MasterServiceSet";
const SOURCE_CLIENT_MATRIX = "Client Matrix";
const SOURCE_RAP_CORRECTIONS = "RAP Corrections";
const SOURCE_MSS_CORRECTIONS = "MSS Corrections";
const SOURCE_D365 = "Actual Contractor & IC";
const SOURCE_CTS_BFS_CLIENT_MATRIX = "CTS BFS Client Matrix";
const SOURCE_CLIENT_MATRIX_FD_MAPPING =
  "Client Matrix Overhead FD & AOR Mapping";

const CTS_SOURCES = [
  SOURCE_WORKDAY,
  SOURCE_TIMESHEET,
  SOURCE_ADP,
  SOURCE_MASTER_SERVICE_SET,
  SOURCE_CLIENT_MATRIX,
  SOURCE_D365
];

const EXCEL_OUTPUT_TEMPLATE = "Excel Output Template";

const CTS_SYSTEM_SOURCES = [
  SOURCE_RAP_CORRECTIONS,
  SOURCE_MSS_CORRECTIONS,
  EXCEL_OUTPUT_TEMPLATE,
  SOURCE_CTS_BFS_CLIENT_MATRIX,
  SOURCE_CLIENT_MATRIX_FD_MAPPING
];

const CTS_ISSUES_STATUS = ["Active", "Resolved", "Ignore"];

const EDIT_FORM_FIELDS = {
  payrollId: "Payroll ID",
  workdayId: "Employee Workday ID",
  company: "Company",
  profitCenter: "Profit Center",
  name: "NAME",
  employeeHub: "Employee Hub",
  employeeDiscipline: "Employee Discipline",
  employeeFunction: "Employee Function",
  businessTitle: "Business Title",
  location: "Location",
  mgmtLevel: "MGMT Lvl",
  statusCY: "Status CY",
  bpcSegment: "BPC Segment",
  roleId: "Role ID/PIN",

  // Salary
  annualSalary: "Annual (USD)",
  salaryType: "Salary Type",
  proposedAnnualSalary: "Proposed Promo Annual Salary %",
  proposedCYSalary: "CY Proposed Promo Salary $",
  proposedCYDate: "CY Proposed Date",
  effectiveCYRaise: "CY Effective Raise",
  salaryAdjCY: "Promo/Merit/Salary Adj CY"
};

const MONTHS = [
  "January",
  "Febuary",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

const ALLOCATION_FIELDS = [
  // Allocations
  "clientTitle",
  "clientScope",
  "client",
  "clientAOR",
  "clientDiscipline",
  "ftePlanned",
  "salaryPlanned",
  "statusEffDateCY",
  "statusEffEndDateCY",
  // CY and Next CY Fields
  "cyJanPercent",
  "cyFebPercent",
  "cyMarPercent",
  "cyAprPercent",
  "cyMayPercent",
  "cyJunPercent",
  "cyJulPercent",
  "cyAugPercent",
  "cySepPercent",
  "cyOctPercent",
  "cyNovPercent",
  "cyDecPercent",
  "cyJanSalary",
  "cyFebSalary",
  "cyMarSalary",
  "cyAprSalary",
  "cyMaySalary",
  "cyJunSalary",
  "cyJulSalary",
  "cyAugSalary",
  "cySepSalary",
  "cyOctSalary",
  "cyNovSalary",
  "cyDecSalary",
  "cyNextJanPercent",
  "cyNextFebPercent",
  "cyNextMarPercent",
  "cyNextAprPercent",
  "cyNextMayPercent",
  "cyNextJunPercent",
  "cyNextJulPercent",
  "cyNextAugPercent",
  "cyNextSepPercent",
  "cyNextOctPercent",
  "cyNextNovPercent",
  "cyNextDecPercent",
  "cyNextJanSalary",
  "cyNextFebSalary",
  "cyNextMarSalary",
  "cyNextAprSalary",
  "cyNextMaySalary",
  "cyNextJunSalary",
  "cyNextJulSalary",
  "cyNextAugSalary",
  "cyNextSepSalary",
  "cyNextOctSalary",
  "cyNextNovSalary",
  "cyNextDecSalary",
  "cyNextProposedPromoAnnualSalary",
  "cyNextYPlusOneProposedPromoAnnualSalary",
  "cyNextYPlusOneProposedDate",
  "cyNextCyEffectiveRaise",
  "cyNextYPlusOneSalary",
  "cyNextYPlusOneStatus",
  "cyNextYPlusStatusEffDate"
];

const LIST_FIELDS = ["bpcSegment", "salaryType", "salaryAdjCY", "statusCY"];

const DATE_FIELDS = [
  "statusEffDateCY",
  "statusEffEndDateCY",
  "proposedCYDate",
  "cyNextYPlusOneProposedDate",
  "cyNextYPlusStatusEffDate"
];

const BPC_SEGMENT_VALUES = [
  "REV_ACT_DLVRY",
  "SUPPINVEST",
  "SUPPTRAD",
  "REV_ACT_MGMT",
  "DIRTECH",
  "REV_ACT_SUPP",
  "NEWBUS",
  "EXECMGT",
  "COMMNS",
  "COMMNS_SL",
  "FACILITY",
  "HUMRES",
  "LAW",
  "TECH"
];

const SALARY_ADJCY_VALUES = ["Promo", "Merit", "Salary"];

const SALARY_TYPE_VALUES = [
  "Full-Time",
  "Part-Time & Contractor",
  "Severance",
  "Bonus",
  "Other Personnel",
  "Recovery",
  "Recharge",
  "Attrition",
  "Commission",
  "LAFH",
  "Car Allowance",
  "Finance Plug",
  "External Contractor"
];

// Column Headers
const COLUMN_SCENARIO_NAME = "Scenario Name";
const COLUMN_LAST_EDITED = "Last Edited On";
const COLUMN_EDITED_BY = "Last Edited By";
const COLUMN_DATA_SOURCES = "Data Sources";
const COLUMN_MISMATCHES = "Mismatches";
const COLUMN_STATUS = "Status";
const COLUMN_ACTIONS = "Actions";

// Data keys to access values from 'getScenarios' API response
const SCENARIO_DATAKEY_ID = "scenarioId";
const SCENARIO_DATAKEY_NAME = "name";
const SCENARIO_DATAKEY_NEWROW = "newRow";
const SCENARIO_DATAKEY_LAST_EDITED = "lastEditedOn";
const SCENARIO_DATAKEY_UPDATED = "updated";
const SCENARIO_DATAKEY_LAST_EDITED_BY = "updatedBy";
const SCENARIO_DATAKEY_SOURCES = "sourceType";
const SCENARIO_DATAKEY_SOURCES_TEMPLATE = "sourceListTemplate";
const SCENARIO_DATAKEY_MISMATCHES = "processingIssues";
const SCENARIO_DATAKEY_STATUS = "status";
const SCENARIO_DATAKEY_STATUSICO = "statusIco";
const SCENARIO_DATAKEY_ACTIONS = "actions";

const PROCESS_DONE = "done";
const PROCESS_FINAL = "ready";
const PROCESS_ERROR = "error";
const PROCESS_INPROGRESS = "processing";

const SUCCESS = "success";

export default {
  SCENARIO_STATUS_LOCKED,
  SCENARIO_STATUS_ARCHIVED,
  SCENARIO_STATUS_ACTIVE,
  SCENARIO_CREATED_YESTERDAY,
  CTS_API_ROOT_URL,
  SOURCE_WORKDAY,
  SOURCE_TIMESHEET,
  SOURCE_ADP,
  SOURCE_MASTER_SERVICE_SET,
  SOURCE_CLIENT_MATRIX,
  SOURCE_RAP_CORRECTIONS,
  SOURCE_MSS_CORRECTIONS,
  EDIT_FORM_FIELDS,
  ALLOCATION_FIELDS,
  LIST_FIELDS,
  DATE_FIELDS,
  CTS_SOURCES,
  CTS_SYSTEM_SOURCES,
  EXCEL_OUTPUT_TEMPLATE,
  CTS_ISSUES_STATUS,
  BPC_SEGMENT_VALUES,
  SALARY_ADJCY_VALUES,
  SALARY_TYPE_VALUES,
  COLUMN_SCENARIO_NAME,
  COLUMN_LAST_EDITED,
  COLUMN_EDITED_BY,
  COLUMN_DATA_SOURCES,
  COLUMN_MISMATCHES,
  COLUMN_STATUS,
  COLUMN_ACTIONS,
  SCENARIO_DATAKEY_ID,
  SCENARIO_DATAKEY_NAME,
  SCENARIO_DATAKEY_LAST_EDITED,
  SCENARIO_DATAKEY_NEWROW,
  SCENARIO_DATAKEY_UPDATED,
  SCENARIO_DATAKEY_LAST_EDITED_BY,
  SCENARIO_DATAKEY_SOURCES,
  SCENARIO_DATAKEY_SOURCES_TEMPLATE,
  SCENARIO_DATAKEY_MISMATCHES,
  SCENARIO_DATAKEY_STATUS,
  SCENARIO_DATAKEY_STATUSICO,
  SCENARIO_DATAKEY_ACTIONS,
  MONTHS,
  PROCESS_DONE,
  PROCESS_FINAL,
  PROCESS_ERROR,
  PROCESS_INPROGRESS,
  SUCCESS
};
