import React from "react";
import PropTypes from "prop-types";

const NotificationBell = ({ hasNotification, onBellIconClick }) => (
  <div className="notification-bell" onClick={onBellIconClick}>
    <div className={`bell-icon ${hasNotification ? "with-notifications" : ""}`}>
      <span className="bell">
        {" "}
        <i className="fas fa-bell" />
      </span>
      {hasNotification && <div className="notification-dot" />}
    </div>
  </div>
);

NotificationBell.propTypes = {
  hasNotification: PropTypes.bool,
  onBellIconClick: PropTypes.func
};

NotificationBell.defaultProps = {
  hasNotification: false,
  onBellIconClick: () => {}
};
export default NotificationBell;
