import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import api from "../../services/UserAdminApi";
import Constants from "../../common/Constants";

const UserCountries = () => {
  const [isLoading, setIsloading] = useState(true);
  const [countries, setCountries] = useState([]);

  const getCountries = async () => {
    try {
      const userId = window?.userSession?.userId;
      const userCountryResult = await api.getCountriesByUser(userId);
      if (userCountryResult.length > 0) {
        setCountries(userCountryResult);
      } else {
        toast.error(
          "There was an error in fetching countries",
          Constants.TOAST_OPTIONS
        );
      }
      setIsloading(false);
    } catch (ex) {
      setIsloading(false);
      toast.error(
        `There was an error in fetching countries - ${ex.message}`,
        Constants.TOAST_OPTIONS
      );
    }
  };

  useEffect(() => {
    getCountries();
  }, []);

  return { countries, isLoading };
};

export default UserCountries;
