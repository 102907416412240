import React, { useRef, useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";

import { useNavigate } from "react-router-dom";
import {
  useTable,
  useSortBy,
  useAsyncDebounce,
  usePagination
} from "react-table";
import { Field, Form, Formik } from "formik";
import { toast } from "react-toastify";

import "../../styles/par-queue-table.scss";
import Constants from "../../common/Constants";
import ParConstants from "../../common/ParConstants";
import RapConstants from "../../common/RapConstants";
import ParRequestList from "../hooks/par/ParRequestList";
import TypeAheadBoxField from "../shared/TypeAheadBoxField";
import DateTime from "../../helpers/DateTime";
import ParApi from "../../services/ParApi";
import Pagination from "../shared/Pagination";
import { useParContext } from "../../store/ParContext";
import LoadingSpinner from "../shared/LoadingSpinner";
import spreadhseet from "../../assets/spreadhseet.png";
import spreadhseetdisabled from "../../assets/Spreadhseet_icon_disabled.png";

const ParQueue = ({ parAvailablePins, parPayRanges, queueType }) => {
  const navigate = useNavigate();
  const [userRole] = useState(
    window.userSession?.parPermissions?.permission_name
  );
  const { parRequestTableData, uiState, refresParRequestList } = ParRequestList(
    null,
    queueType
  );

  const { parWorkflows, validations } = useParContext();

  const [textSearch, setTextSearch] = useState("");
  const [parType, setParType] = useState("");
  const [employeeType, setEmployeeType] = useState("");
  const [clientBrand, setClientBrand] = useState("");
  const [isException, setIsException] = useState(false);
  const hasMounted = useRef(false);

  const handleSearchText = useAsyncDebounce((value) => {
    setTextSearch(value);
  }, 300);

  const handleToggle = (e) => {
    setIsException(e.target.checked);
  };

  const disableExportBtn = () => {
    if (
      uiState === Constants.UI_STATE_LOADING ||
      uiState === Constants.UI_STATE_EMPTY ||
      uiState === Constants.UI_STATE_ERROR
    ) {
      return true;
    }

    if (
      textSearch === "" &&
      parType === "" &&
      clientBrand === "" &&
      employeeType === "" &&
      !isException
    ) {
      return true;
    }

    return false;
  };

  const getStatusClass = (status) => {
    const parStatus = ParConstants.PAR_STATUS;

    switch (status) {
      case parStatus.withdraw:
        return "par-status-withdraw";
      case parStatus.complete:
        return "par-status-complete";
      case parStatus.draft:
        return "par-status-draft";
      default:
        return "";
    }
  };

  function getHeaderClassName(header) {
    switch (header) {
      case "PAR ID":
        return "col-par-id";
      case "PAR Type":
        return "col-par-type";
      case "Employee Name":
        return "col-employee-name";
      case "Position Title":
        return "col-position-title";
      case "Date Submitted":
        return "col-par-date";
      case "Last Updated":
        return "col-par-date";
      case "Profit Center":
        return "col-profile-center";
      case "Status":
        return "col-par-status";
      // Only shown for non-client brand submitter
      case "Employee Type":
        return "col-employee-type";
      case "Client - Brand":
        return "col-profile-center";
      case "Submitter Name":
        return "col-par-date";
      case "Submitter Email":
        return "col-par-date";
      default:
        return "";
    }
  }

  const renderParCell = ({ row }) => {
    const parRequestId = row.values.par_request_id;
    return (
      <a
        onClick={() =>
          navigate("/paredit", {
            state: { parRequestId, validations, parAvailablePins, parPayRanges }
          })
        }
      >
        {parRequestId}
      </a>
    );
  };

  const submitterColsDef = useMemo(
    () => [
      {
        Header: "PAR ID",
        accessor: "par_request_id",
        Cell: ({ row }) => renderParCell({ row })
      },
      {
        Header: "PAR Type",
        accessor: "par_type"
      },
      {
        Header: "Employee Name",
        accessor: "employee_name"
      },
      {
        Header: "Position Title",
        accessor: "position_title"
      },
      {
        Header: "Date Submitted",
        accessor: "par_submitted_date",
        Cell: ({ value }) => DateTime.formatDate(value, "MM/dd/yyyy")
      },
      {
        Header: "Last Updated",
        accessor: "last_update_date",
        /* eslint-disable react/no-unstable-nested-components */
        /* eslint-disable react/prop-types */
        Cell: ({ value }) => DateTime.formatDate(value, "MM/dd/yyyy")
      },
      {
        Header: "Profit Center",
        accessor: "profit_center"
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ row }) => {
          const { status } = row.values;
          const parStatus = ParConstants.PAR_STATUS;
          const parRequestId = row.values.par_request_id;

          return (
            <span className={getStatusClass(status)}>
              {!status && (
                <a
                  onClick={() =>
                    navigate("/paredit", {
                      state: {
                        parRequestId,
                        validations,
                        parAvailablePins,
                        parPayRanges
                      }
                    })
                  }
                >
                  <strong>{parStatus.draft}</strong>
                  <i className="fas fa-pen" />
                </a>
              )}
              {status && <>{status}</>}
            </span>
          );
        }
      },
      {
        Header: "",
        accessor: "is_exception_request",
        Cell: ({ row }) => {
          const isExceptionRequest = row.values?.is_exception_request;

          if (!isExceptionRequest) {
            return ``;
          }
          return <span className="par-exception">ex</span>;
        }
      }
    ],
    [parRequestTableData]
  );

  const nonSubmitterColsDef = useMemo(
    () => [
      {
        Header: "PAR ID",
        accessor: "par_request_id",
        Cell: ({ row }) => renderParCell({ row })
      },
      {
        Header: "PAR Type",
        accessor: "par_type"
      },
      {
        Header: "Employee Type",
        accessor: "employee_type"
      },
      {
        Header: "Position Title",
        accessor: "position_title"
      },
      {
        Header: "Client - Brand",
        accessor: "client_brand"
      },
      {
        Header: "Date Submitted",
        accessor: "par_submitted_date",
        Cell: ({ value }) => DateTime.formatDate(value, "MM/dd/yyyy")
      },
      {
        Header: "Submitter Name",
        accessor: "submitter_name"
      },
      {
        Header: "Submitter Email",
        accessor: "submitter_email"
      }
    ],
    [parRequestTableData]
  );

  const exportPARRequestList = async () => {
    let filePath = "";
    try {
      const parSearchFilters = {
        workflow_id: parType,
        clientBrand,
        isException,
        textSearch
      };
      const response = await ParApi.exportPARQueue(parSearchFilters);

      // Get full url
      if (response && response.success && response.downloadURL) {
        filePath = response.downloadURL;
      } else {
        // Show error
        toast.error(
          "There was a problem in downloading the file.",
          Constants.TOAST_OPTIONS
        );
      }
    } catch (ex) {
      toast.error(
        `There was an unexpected error in downloading the file - ${ex.message}`,
        Constants.TOAST_OPTIONS
      );
    }

    await window.open(`${filePath}`, "_blank");
  };

  const table = useTable(
    {
      columns: queueType ? nonSubmitterColsDef : submitterColsDef,
      data: parRequestTableData,
      initialState: {
        sortBy: [
          {
            id: "par_submitted_date",
            desc: true
          }
        ],
        pageSize:
          parseInt(
            localStorage.getItem(ParConstants.PAR_SUBMISSION_LOCAL_STORAGE),
            10
          ) || 100,
        pageIndex: 0
      },
      disableSortRemove: true
    },
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = table;

  const getTableBodyTemplate = () =>
    page.map((row) => {
      prepareRow(row);
      return (
        <tr {...row.getRowProps()}>
          {row.cells.map((cell) => (
            <td
              {...cell.getCellProps()}
              style={{
                textAlign: "left"
              }}
            >
              <span>{cell.render("Cell")}</span>
            </td>
          ))}
        </tr>
      );
    });

  useEffect(() => {
    if (!hasMounted.current) {
      hasMounted.current = true;
      return;
    }

    const parSearchFilters = {
      workflow_id: parType,
      clientBrand,
      isException,
      textSearch,
      employee_type: employeeType
    };
    refresParRequestList(parSearchFilters, queueType);
  }, [textSearch, parType, clientBrand, isException, employeeType]);

  return (
    <>
      <div className="par-queue-filter">
        <Formik initialValues={{}}>
          {(formikProps) => (
            <Form>
              {!queueType && (
                <div className="filter-item">
                  <span>Show Exceptions</span>
                  <Field
                    type="checkbox"
                    id="exception"
                    name="exception"
                    className="toggle-btn"
                    checked={isException}
                    onChange={(event) => handleToggle(event)}
                  />
                  <label htmlFor="exception">Toggle</label>
                </div>
              )}
              {queueType && <span className="filter-item" />}

              <div className="filter-item text-search  mr-3">
                <i className="fas fa-search search-icon" />
                <Field
                  type="text"
                  name="searchText"
                  className="form-control"
                  aria-label="searchText"
                  onChange={(event) =>
                    handleSearchText(event.target.value.trim())
                  }
                  placeholder="Search"
                />
              </div>
              {userRole === ParConstants.PAR_PERMISSIONS_MAPPING.PAR_RM && (
                <div className="filter-item select-box  mr-3">
                  <TypeAheadBoxField
                    formikProps={formikProps}
                    suggestions={RapConstants.EMPLOYEE_TYPE_VALUES.map((v) => ({
                      value: v,
                      label: v
                    }))}
                    fieldName="employeeType"
                    label=""
                    placeholder="Employee Type"
                    hideHeader
                    onValueChange={(value) => setEmployeeType(value)}
                    isKeyValueProvided
                  />
                </div>
              )}
              <div className="filter-item select-box  mr-3">
                <TypeAheadBoxField
                  formikProps={formikProps}
                  suggestions={parWorkflows.map((w) => ({
                    value: w.workflow_id,
                    label: w.name
                  }))}
                  fieldName="parType"
                  label=""
                  placeholder="PAR Type"
                  hideHeader
                  onValueChange={(value) => setParType(value)}
                  isKeyValueProvided
                />
              </div>
              <div className="filter-item select-box  mr-3">
                <TypeAheadBoxField
                  formikProps={formikProps}
                  suggestions={validations?.clientList || []}
                  fieldName="clientBrand"
                  label=""
                  placeholder="Client - Brand"
                  isMultiSelect={false}
                  hideHeader
                  onValueChange={(value) => setClientBrand(value)}
                />
              </div>
              <span className="filter-item export-btn">
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={disableExportBtn()}
                  onClick={exportPARRequestList}
                >
                  Export File{" "}
                  <img
                    alt=""
                    src={disableExportBtn() ? spreadhseetdisabled : spreadhseet}
                  />
                </button>
              </span>
            </Form>
          )}
        </Formik>
      </div>
      {uiState === Constants.UI_STATE_LOADING && (
        <LoadingSpinner
          status="Loading"
          textMessage="Please wait, PAR Requests are being loaded..."
        />
      )}
      {uiState === Constants.UI_STATE_EMPTY && (
        <LoadingSpinner
          status="NoData"
          textMessage="No PAR requests are available"
        />
      )}
      {uiState === Constants.UI_STATE_ERROR && (
        <LoadingSpinner
          status="NoData"
          textMessage="Error fetching PAR Requests"
        />
      )}
      {uiState === Constants.UI_STATE_SUCCESS &&
        parRequestTableData.length === 0 && (
          <LoadingSpinner
            status="NoData"
            textMessage="No PAR requests are available"
          />
        )}

      <table {...getTableProps()} className="par-queue-table">
        {uiState === Constants.UI_STATE_SUCCESS &&
          parRequestTableData.length > 0 && (
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={getHeaderClassName(column.Header)}
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <i className="fas sort-button fa-chevron-down" />
                          ) : (
                            <i className="fas sort-button fa-chevron-up" />
                          )
                        ) : (
                          ""
                        )}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
          )}
        <tbody {...getTableBodyProps()}>{getTableBodyTemplate()}</tbody>
      </table>
      {uiState === Constants.UI_STATE_SUCCESS &&
        parRequestTableData.length > 0 && (
          <Pagination
            currentPage={pageIndex}
            totalPages={pageOptions.length}
            onPageChange={gotoPage}
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
            totalRows={parRequestTableData.length}
            showPerPage={ParConstants.SHOW_PER_PAGE}
            localStorageName={ParConstants.PAR_SUBMISSION_LOCAL_STORAGE}
          />
        )}
    </>
  );
};

ParQueue.propTypes = {
  parAvailablePins: PropTypes.instanceOf(Array),
  parPayRanges: PropTypes.instanceOf(Array),
  queueType: PropTypes.string
};

ParQueue.defaultProps = {
  parAvailablePins: [],
  parPayRanges: [],
  queueType: null
};

export default ParQueue;
