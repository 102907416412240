/* eslint-disable no-use-before-define */
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useTable, useRowSelect, useSortBy } from "react-table";

import Constants from "../../common/Constants";
import BackdropLoader from "../shared/BackdropLoader";

const sortByAdmin = (rowA, rowB) => {
  const isAdminA =
    rowA.values.groups && rowA.values.groups.includes(Constants.ADMIN_GROUP);
  const isAdminB =
    rowB.values.groups && rowB.values.groups.includes(Constants.ADMIN_GROUP);
  // checks for the admin if its admin it will come first
  if (isAdminA === isAdminB) return 0;
  if (isAdminA && !isAdminB) return -1;
  if (!isAdminA && isAdminB) return 1;

  // Default return value
  return 0;
};

const UserTable = ({ data, uiState, setSelectedRow }) => {
  const tableColumns = useMemo(
    () => [
      {
        accessor: "displayName",
        Header: "Name"
      },
      {
        accessor: "email",
        Header: "Email"
      },
      {
        accessor: "status",
        Header: "Status"
      },
      {
        accessor: "groups",
        Header: "Admin",
        sortType: sortByAdmin,
        /* eslint-disable react/no-unstable-nested-components */
        /* eslint-disable react/prop-types */
        Cell: ({ row }) => {
          const isAdmin =
            row.values.groups &&
            row.values.groups.includes(Constants.ADMIN_GROUP);
          if (isAdmin) {
            return <i className="fas fa-check" />;
          }
          return <></>;
        }
      }
    ],
    [data]
  );

  const getHeaderClassName = (header) => {
    switch (header) {
      case "Select":
        return "col-select align-center";
      case Constants.COL_NAME:
        return "col-name";
      case Constants.COL_EMAIL:
        return "col-email";
      case Constants.COL_STATUS:
        return "col-status";
      case Constants.COL_ADMIN:
        return "col-admin align-center";
      default:
        return "";
    }
  };

  const centerAlignedColumns = ["Select", Constants.COL_ADMIN];

  const getTableBodyTemplate = () => {
    switch (uiState) {
      case Constants.UI_STATE_LOADING:
        return (
          <tr>
            <td colSpan={12} className="pos-relative loader-wrapper">
              <BackdropLoader />
            </td>
          </tr>
        );
      case Constants.UI_STATE_EMPTY:
        return (
          <tr>
            <td colSpan={12}>
              <h5 className="placeholder text-center">No Users available</h5>
            </td>
          </tr>
        );
      case Constants.UI_STATE_ERROR:
        return (
          <tr>
            <td colSpan={12}>
              <h5 className="placeholder text-center">Error fetching User</h5>
            </td>
          </tr>
        );
      default:
        if (rows.length === 0) {
          return (
            <tr>
              <td colSpan={12}>
                <h5 className="placeholder text-center">No Users available</h5>
              </td>
            </tr>
          );
        }
        return rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <td
                  {...cell.getCellProps()}
                  style={{
                    textAlign: centerAlignedColumns.includes(cell.column.Header)
                      ? "center"
                      : "left"
                  }}
                >
                  <span
                    className={
                      centerAlignedColumns.includes(cell.column.Header)
                        ? "center-align-cell"
                        : "audience-name__cell"
                    }
                  >
                    {cell.render("Cell")}
                  </span>
                </td>
              ))}
            </tr>
          );
        });
    }
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns: tableColumns,
        data,
        initialState: {
          sortBy: [
            {
              id: "displayName",
              desc: false
            }
          ]
        },
        disableSortRemove: true
      },
      useSortBy,
      useRowSelect,
      (hooks) => {
        hooks.visibleColumns.push((columns) => [
          {
            Header: "Select",
            Cell: ({ row }) => (
              <input
                type="radio"
                name="user-row"
                onClick={() => setSelectedRow(row.original)}
              />
            )
          },
          ...columns
        ]);
      }
    );

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                className={`${getHeaderClassName(column.Header)}`}
              >
                {column.render("Header")}
                {column.canSort ? (
                  column.isSorted ? (
                    column.isSortedDesc ? (
                      <i className="fas sort-button fa-arrow-down" />
                    ) : (
                      <i className="fas sort-button fa-arrow-up" />
                    )
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>{getTableBodyTemplate()}</tbody>
    </table>
  );
};

export default UserTable;

UserTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string,
      email: PropTypes.string,
      status: PropTypes.string,
      groups: PropTypes.arrayOf(PropTypes.string)
    })
  ).isRequired,
  uiState: PropTypes.string.isRequired,
  setSelectedRow: PropTypes.func.isRequired
};
