import React from "react";
import PropTypes from "prop-types";

const Tooltip = ({ children, text, containerClassName, ...rest }) => {
  const [show, setShow] = React.useState(false);

  return (
    <div className={`tooltip-container ${containerClassName}`}>
      <div className={show ? "tooltip-box visible" : "tooltip-box"}>
        {text}
        <span className="tooltip-arrow" />
      </div>

      <div
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
        {...rest}
      >
        {children}
      </div>
    </div>
  );
};

Tooltip.propTypes = {
  children: PropTypes.element.isRequired,
  text: PropTypes.string.isRequired,
  containerClassName: PropTypes.string
};

Tooltip.defaultProps = {
  containerClassName: ""
};
export default Tooltip;
