import ReactDOM from "react-dom";
import React, { useState } from "react";
import { HashRouter, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";

import "./styles/main.scss";
import BaseRouter from "./BaseRouter";
import Constants from "./common/Constants";
import Cookies from "./helpers/Cookies";

import api from "./services/RapApi";
import dentsuLogo from "./assets/logo.png";

/**
 * The main page. If we're here, there is the expectation that the session
 * key is in local storage. If it doesn't validate, throw to login.
 */
const App = () => {
  const [isSessionSet, setIsSessionSet] = useState(false);
  const [tokenStore, setTokenStore] = useState({});

  const history = useNavigate();

  const getParPermissionObject = async (parPermissions) => {
    const parPermissionObject = parPermissions.reduce((acc, current) => {
      if (Object.keys(acc).length === 0) {
        acc = {
          permission_name: current.permission_name,
          field_type_parent: current.field_type_parent,
          field_parent_value: current.field_parent_value,
          permission_for_field_type: current.permission_for_field_type,
          field_value: [],
          user_id: current.user_id
        };
      }
      acc.field_value.push(current.field_value);
      return acc;
    }, {});

    return parPermissionObject;
  };

  const setSessionForPermissions = async () => {
    // Get the oktaKey from custom access token cookie stored by us
    const oktaKeyOne = Cookies.getCookie(Constants.FINOPS_LOGIN_KEY);
    const oktaKeyTwo = Cookies.getCookie(Constants.FINOPS_LOGIN_KEY_APPEND);
    let oktaKey = oktaKeyOne + oktaKeyTwo;
    // Get the Dentsu custom sessionkey from the stored cookie
    const localKey = Cookies.getCookie(Constants.FINOPS_SESSION_KEY);

    let emailAddress = null;
    if (oktaKey) {
      // Get email address to send to validateSession method
      emailAddress = Cookies.getCookie(Constants.FINOPS_LOGIN_USER);
      window.userSession = { authKey: oktaKey };
    }
    try {
      const { user } = await api.validateSession(localKey, emailAddress);
      // There is an issue with the session, so throw an error to display login screen
      if (!user || !user.userId) {
        throw new Error("Authentication failed");
      }
      // create a PAR permissions object
      user.parPermissions = await getParPermissionObject(user.parPermissions);
      // Save the session Key as well as Auth (okta key) in user Session
      Cookies.setCookie(Constants.FINOPS_SESSION_KEY, user.sessionKey, 1);
      user.authKey = oktaKey;
      window.userSession = user;
      const { countries } = user;
      if (countries.length === 1) {
        localStorage.removeItem(Constants.FINOPS_MULTIPLE_COUNTRY);
        // Set User's country setting in localStorage by default if only one country
        localStorage.setItem(
          Constants.FINOPS_COUNTRY,
          JSON.stringify({ countryid: countries[0] })
        );
      } else {
        localStorage.setItem(Constants.FINOPS_MULTIPLE_COUNTRY, "true");
      }
      // all good, continue
      setIsSessionSet(true);
    } catch (ex) {
      // Set the cookie value to a value in past so that it gets deleted
      Cookies.setCookie(Constants.FINOPS_SESSION_KEY, "", null, true);
      setIsSessionSet(false);
      if (ex.message.includes(Constants.ERR_SESSION)) {
        // Navigate back to login, since session has expired
        history("/login");
      } else if (ex.message !== "Failed to fetch") {
        history("/error", { state: { errorMessage: ex.message } });
      }
    }
  };

  // Session Key found, now lets render the main page
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history(toRelativeUrl(originalUri || "", window.location.origin), {
      replace: true
    });
  };

  const storageProvider = {
    getItem(key) {
      return tokenStore[key];
    },
    setItem(key, val) {
      const tokenStoreTemp = tokenStore;
      tokenStoreTemp[key] = val;
      setTokenStore(tokenStoreTemp);
    }
  };

  const oktaAuthConfig = {
    issuer: process.env.RAP_OKTA_DOMAIN,
    redirectUri: `${window.location.origin}/callback`,
    clientId: process.env.RAP_OKTA_CLIENT_ID,
    scopes: ["openid", "profile", "email"],
    logo: dentsuLogo,
    features: {
      rememberMe: true
    },
    brandName: "Dentsu",
    tokenManager: {
      storage: storageProvider
    },
    storageManager: {
      token: {
        storageTypes: [storageProvider]
      }
    }
  };
  const oktaAuth = new OktaAuth(oktaAuthConfig);

  const customAuthHandler = () => {
    history("/login");
  };

  return (
    <div id="divApp">
      <Security
        oktaAuth={oktaAuth}
        onAuthRequired={customAuthHandler}
        restoreOriginalUri={restoreOriginalUri}
      >
        <BaseRouter
          isSessionSet={isSessionSet}
          oktaAuthConfig={oktaAuthConfig}
          setSession={setSessionForPermissions}
        />
      </Security>

      <ToastContainer
        position="top-center"
        autoClose={false}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
      />
    </div>
  );
};

ReactDOM.render(
  <HashRouter>
    <App />
  </HashRouter>,
  document.getElementById("app")
);
