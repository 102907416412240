import React from "react";
import PropTypes from "prop-types";

const ScenarioStatusIcon = ({ status }) => {
  const scenarioStatusIndicator = {
    locked: <i className="fa fa-lock scenario-status locked" />,
    active: <i className="fas fa-check-circle scenario-status active" />,
    archived: <i className="fa fa-save scenario-status archived" />
  };

  return scenarioStatusIndicator[status.toLowerCase()];
};

ScenarioStatusIcon.propTypes = {
  status: PropTypes.string.isRequired
};

export default ScenarioStatusIcon;
